(function() {
    'use strict';

    angular
        .module('pessoas')
        .controller('pessoasController', pessoasController)

    pessoasController.$inject = ['$route', '$routeParams', 'mensagem', '$location', 'PessoasService', 'negociosService', '$http', 'API', '$filter', '$timeout', '$window', '$document', '$q', 'loadSaas', 'validarSaas', 'verificaLeads', 'empresasService', 'tiposEmailService', 'FactoryRoles', '$log', 'FactoryWebhook', 'FactoryNotificacao', '$scope', 'origemContatoService', 'RC', 'APINode', '$mdBottomSheet', 'tokenNodejs'];

    function pessoasController($route, $routeParams, mensagem, $location, PessoasService, negociosService, $http, API, $filter, $timeout, $window, $document, $q, loadSaas, validarSaas, verificaLeads, empresasService, tiposEmailService, FactoryRoles, $log, FactoryWebhook, FactoryNotificacao, $scope, origemContatoService, RC, APINode, $mdBottomSheet, tokenNodejs) {

        if ($location.url().substring(0, 21) == '/pessoas/atendimento/') {
            $timeout(function() {
                $('.ativar_textarea2').fadeOut(0);
                $('.ativar_textarea').fadeIn(0);
            });
        }

        var vm = this;

        let userAPI = JSON.parse(RC.funilRC.decrypt(window.localStorage.getItem('5c479de2')));
        let BaseApi = APINode.url + 'token/' + userAPI[0].saas_id;

        vm.resultExcluiRole = FactoryRoles.checkRolesExclui('isModPessoas');
        vm.resultAlteraRole = FactoryRoles.checkRolesAltera('isModPessoas');
        vm.resultCriaRole = FactoryRoles.checkRolesCria('isModPessoas');

        var varRolesStorage = FactoryRoles.checkRolesStorage('isModPessoas');
        vm.roleVisualiza = varRolesStorage.visualiza;
        vm.roleCria = varRolesStorage.cria;
        vm.roleExclui = varRolesStorage.exclui;
        vm.roleAltera = varRolesStorage.altera;

        var truVisuGroupCargoSelec = FactoryRoles.visuGroupCargoSelec('isModConfig');
        vm.veriVisuSelectCargCSS = function(dados) {
            return '';
            // LINHAS ABAIXO COMENTADAS PARA QUE USUÁRIOS COM PERFIL REPRESENTANTE POSSAM VISUALIZAR AS ORIGENS DE CONTATO E CARGOS
            /*if (dados == false) {
                var desabilitarSelect = 'disable_a2';
                return desabilitarSelect;
            }*/
        };
        vm.desabilitarSelect = vm.veriVisuSelectCargCSS(truVisuGroupCargoSelec);
        // verificar se for falso, retornar um true para colocar na class


        // console.log('$("#teste_telefone_input")', $("#teste_telefone_input"))

        // vm.initPlaceholderTel = function() {
        //     console.log('mask')
        //     angular.element("#telInput").inputmask('phone')
        // }


        vm.IsEmail = function(email) {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
            /*var exclude = /[^@\-\.\w]|^[_@\.\-]|[\._\-]{2}|[@\.]{2}|(@)[^@]*\1/;
            var check = /@[\w\-]+\./;
            var checkend = /\.[a-zA-Z]{2,3}$/;
            if (((email.search(exclude) != -1) || (email.search(check)) == -1) || (email.search(checkend) == -1)) {
                return false;
            } else {
                return true;
            }*/
        };

        // LIMITAÇÃO DE LEADS --- INÍCIO
        vm.verificarLimitacaoLeads = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) {
                vm.saasid = items;
                vm.leads = verificaLeads(vm.saasid); // carregar saas id
                vm.leads.then(function(dados) {
                    if (dados.limite <= dados.quantia) {
                        //$location.url("/alertas");
                        $window.location = API.url + 'minhaconta/';
                    }
                });
            });
        };
        if ($location.url().match('/cadastro/')) {
            vm.verificarLimitacaoLeads();
        }
        // LIMITAÇÃO DE LEADS --- FIM

        vm.disable_endereco = false;
        vm.cep_valid = false;
        vm.validar_nome = true;
        vm.validar_categoria = true;
        vm.mask_cpf = '999.999.999-99'; //99.999.999/9999-99
        vm.validar_cpf = true;
        vm.tags_lista_cliente = [];
        vm.novo_atendimento = false;
        vm.atendimentos = [];
        vm.limiteAtendimento = 5;
        vm.adm = false;
        vm.negocios = [];
        vm.mostrarGrafico = true;
        var dados = {
            'slug': $routeParams.slugCliente
        };
        vm.slugPessoa = $routeParams.slugCliente;
        vm.carregarPg = true;
        vm.cliPg = true;
        vm.textarea = true;
        vm.atdPg = true;
        vm.slugCliente = $routeParams.slugCliente;
        vm.mostrarWarn = true;
        vm.negocios = [];

        if ($location.url().substring(0, 14) == '/pessoas/info/') {

            var rolIdDecry = API.funilRC.decrypt($window.localStorage.getItem('isModNegociacao'));
            var rolStorageObj = JSON.parse(rolIdDecry);
            var rolID = rolStorageObj.visualiza;
            //dadosUser
            var daUserStorage = API.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
            var daUser = JSON.parse(daUserStorage);
            var gruId = daUser[0].fk_gru_id;
            var userId = daUser[0].user_id;

            dados.rolid = rolID;
            dados.userID = userId;
            dados.gruID = gruId;
            PessoasService.carregarValorNegocios(dados).then(function(response) {
                if (response.data == '0') {
                    vm.negocios = [{
                        'tipo': 'Abertos',
                        'valor': '0.00'
                    }, {
                        'tipo': 'Ganhos',
                        'valor': '0.00'
                    }, {
                        'tipo': 'Perdidos',
                        'valor': '0.00'
                    }, {
                        'tipo': 'Atrasados',
                        'atrasado': 'false'
                    }];
                } else {
                    vm.negocios = response.data;
                }
                var a = true;
                var g = true;
                var p = true;
                var nome = '';
                for (var x = 0; x < vm.negocios.length; x++) {
                    if (x == 0) {
                        nome = 'neg_abertos';
                    } else if (x == 1) {
                        nome = 'neg_ganhos';
                    } else {
                        nome = 'neg_perdidos';
                    }
                    var soma = 0;
                    if (x == 0 && vm.negocios[x].valor == '0.00') {
                        a = false;
                    } else if (x == 1 && vm.negocios[x].valor == '0.00') {
                        g = false;
                    } else if (x == 2 && vm.negocios[x].valor == '0.00') {
                        p = false;
                    }
                    for (var y = 0; y < vm.negocios.length; y++) {
                        if (vm.negocios[y].tipo != 'Atrasados') {
                            soma = soma + (parseFloat(vm.negocios[y].valor));
                        }
                    }
                    if (vm.negocios[x].tipo != 'Atrasados') {
                        var porcent = Math.round(parseInt(vm.negocios[x].valor) / (soma / 100));
                        var valor = ((parseFloat(vm.negocios[x].valor) / parseFloat(soma)) * 350);
                        if (valor == 0) {
                            $('.' + nome).css('display', 'none');
                        }
                        if (valor == 0) {
                            valor = valor + 3;
                        }
                        $('.' + nome).css('width', '' + valor + 'px');
                    }
                }
                //1 = ganhos      2 = perdidos
                if (vm.negocios[1].valor.length > 9 || vm.negocios[2].valor.length > 9) {
                    $('.valor_ganhos').addClass('size_text_menor');
                    $('.valor_perdidos').addClass('size_text_menor');
                } else {
                    $('.valor_ganhos').removeClass('size_text_menor');
                    $('.valor_perdidos').removeClass('size_text_menor');
                }

                if (a == true && p == true && g == true) {
                    //abertos
                    $('.neg_abertos').removeClass('border_radius_right');
                    $('.neg_abertos').addClass('border_radius_left');
                    $('.neg_abertos').removeClass('border_radius');
                    //display
                    $('.neg_abertos').addClass('display_block');
                    $('.neg_abertos').removeClass('display_none');
                    //perdidos
                    $('.neg_perdidos').removeClass('border_radius_right');
                    $('.neg_perdidos').removeClass('border_radius_left');
                    $('.neg_perdidos').removeClass('border_radius');
                    //display
                    $('.neg_perdidos').addClass('display_block');
                    $('.neg_perdidos').removeClass('display_none');
                    //ganhos
                    $('.neg_ganhos').addClass('border_radius_right');
                    $('.neg_ganhos').removeClass('border_radius_left');
                    $('.neg_ganhos').removeClass('border_radius');
                    //display
                    $('.neg_ganhos').addClass('display_block');
                    $('.neg_ganhos').removeClass('display_none');
                } else if (a == false && p == true && g == true) {
                    //abertos
                    $('.neg_abertos').removeClass('border_radius_right');
                    $('.neg_abertos').removeClass('border_radius_left');
                    $('.neg_abertos').removeClass('border_radius');
                    //display
                    $('.neg_abertos').addClass('display_none');
                    $('.neg_abertos').removeClass('display_block');
                    //perdidos
                    $('.neg_perdidos').removeClass('border_radius_right');
                    $('.neg_perdidos').addClass('border_radius_left');
                    $('.neg_perdidos').removeClass('border_radius');
                    //display
                    $('.neg_perdidos').addClass('display_block');
                    $('.neg_perdidos').removeClass('display_none');
                    //ganhos
                    $('.neg_ganhos').addClass('border_radius_right');
                    $('.neg_ganhos').removeClass('border_radius_left');
                    $('.neg_ganhos').removeClass('border_radius');
                    //display
                    $('.neg_ganhos').addClass('display_block');
                    $('.neg_ganhos').removeClass('display_none');
                } else if (a == true && p == false && g == true) {
                    //abertos
                    $('.neg_abertos').removeClass('border_radius_right');
                    $('.neg_abertos').addClass('border_radius_left');
                    $('.neg_abertos').removeClass('border_radius');
                    //display
                    $('.neg_abertos').addClass('display_block');
                    $('.neg_abertos').removeClass('display_none');
                    //perdidos
                    $('.neg_perdidos').removeClass('border_radius_right');
                    $('.neg_perdidos').removeClass('border_radius_left');
                    $('.neg_perdidos').removeClass('border_radius');
                    //display
                    $('.neg_perdidos').addClass('display_none');
                    $('.neg_perdidos').removeClass('display_block');
                    //ganhos
                    $('.neg_ganhos').addClass('border_radius_right');
                    $('.neg_ganhos').removeClass('border_radius_left');
                    $('.neg_ganhos').removeClass('border_radius');
                    //display
                    $('.neg_ganhos').addClass('display_block');
                    $('.neg_ganhos').removeClass('display_none');
                } else if (a == true && p == true && g == false) {
                    //abertos
                    $('.neg_abertos').removeClass('border_radius_right');
                    $('.neg_abertos').addClass('border_radius_left');
                    $('.neg_abertos').removeClass('border_radius');
                    //display
                    $('.neg_abertos').addClass('display_block');
                    $('.neg_abertos').removeClass('display_none');
                    //perdidos
                    $('.neg_perdidos').addClass('border_radius_right');
                    $('.neg_perdidos').removeClass('border_radius_left');
                    $('.neg_perdidos').removeClass('border_radius');
                    //display
                    $('.neg_perdidos').addClass('display_block');
                    $('.neg_perdidos').removeClass('display_none');
                    //ganhos
                    $('.neg_ganhos').removeClass('border_radius_right');
                    $('.neg_ganhos').removeClass('border_radius_left');
                    $('.neg_ganhos').removeClass('border_radius');
                    //display
                    $('.neg_ganhos').addClass('display_none');
                    $('.neg_ganhos').removeClass('display_block');
                } else if (a == false && p == false && g == true) {
                    //abertos
                    $('.neg_abertos').removeClass('border_radius_right');
                    $('.neg_abertos').removeClass('border_radius_left');
                    $('.neg_abertos').removeClass('border_radius');
                    //display
                    $('.neg_abertos').addClass('display_none');
                    $('.neg_abertos').removeClass('display_block');
                    //perdidos
                    $('.neg_perdidos').removeClass('border_radius_right');
                    $('.neg_perdidos').removeClass('border_radius_left');
                    $('.neg_perdidos').removeClass('border_radius');
                    //display
                    $('.neg_perdidos').addClass('display_none');
                    $('.neg_perdidos').removeClass('display_block');
                    //ganhos
                    $('.neg_ganhos').removeClass('border_radius_right');
                    $('.neg_ganhos').removeClass('border_radius_left');
                    $('.neg_ganhos').addClass('border_radius');
                    //display
                    $('.neg_ganhos').addClass('display_block');
                    $('.neg_ganhos').removeClass('display_none');
                } else if (a == true && p == false && g == false) {
                    //abertos
                    $('.neg_abertos').removeClass('border_radius_right');
                    $('.neg_abertos').removeClass('border_radius_left');
                    $('.neg_abertos').addClass('border_radius');
                    //display
                    $('.neg_abertos').addClass('display_block');
                    $('.neg_abertos').removeClass('display_none');
                    //perdidos
                    $('.neg_perdidos').removeClass('border_radius_right');
                    $('.neg_perdidos').removeClass('border_radius_left');
                    $('.neg_perdidos').removeClass('border_radius');
                    //display
                    $('.neg_perdidos').addClass('display_none');
                    $('.neg_perdidos').removeClass('display_block');
                    //ganhos
                    $('.neg_ganhos').removeClass('border_radius_right');
                    $('.neg_ganhos').removeClass('border_radius_left');
                    $('.neg_ganhos').removeClass('border_radius');
                    //display
                    $('.neg_ganhos').addClass('display_none');
                    $('.neg_ganhos').removeClass('display_block');
                } else if (a == false && p == true && g == false) {
                    //abertos
                    $('.neg_abertos').removeClass('border_radius_right');
                    $('.neg_abertos').removeClass('border_radius_left');
                    $('.neg_abertos').removeClass('border_radius');
                    //display
                    $('.neg_abertos').addClass('display_none');
                    $('.neg_abertos').removeClass('display_block');
                    //perdidos
                    $('.neg_perdidos').removeClass('border_radius_right');
                    $('.neg_perdidos').removeClass('border_radius_left');
                    $('.neg_perdidos').addClass('border_radius');
                    //display
                    $('.neg_perdidos').addClass('display_block');
                    $('.neg_perdidos').removeClass('display_none');
                    //ganhos
                    $('.neg_ganhos').removeClass('border_radius_right');
                    $('.neg_ganhos').removeClass('border_radius_left');
                    $('.neg_ganhos').removeClass('border_radius');
                    //display
                    $('.neg_ganhos').addClass('display_none');
                    $('.neg_ganhos').removeClass('display_block');
                } else if (a == false && p == false && g == false) {
                    //abertos
                    $('.neg_abertos').removeClass('border_radius_right');
                    $('.neg_abertos').removeClass('border_radius_left');
                    $('.neg_abertos').removeClass('border_radius');
                    //display
                    $('.neg_abertos').addClass('display_none');
                    $('.neg_abertos').removeClass('display_block');
                    //perdidos
                    $('.neg_perdidos').removeClass('border_radius_right');
                    $('.neg_perdidos').removeClass('border_radius_left');
                    $('.neg_perdidos').removeClass('border_radius');
                    //display
                    $('.neg_perdidos').addClass('display_none');
                    $('.neg_perdidos').removeClass('display_block');
                    //ganhos
                    $('.neg_ganhos').removeClass('border_radius_right');
                    $('.neg_ganhos').removeClass('border_radius_left');
                    $('.neg_ganhos').removeClass('border_radius');
                    //display
                    $('.neg_ganhos').addClass('display_none');
                    $('.neg_ganhos').removeClass('display_block');
                    vm.mostrarWarn = false;
                }
                $timeout(function() {
                    vm.porcent1 = Math.round(parseInt(vm.negocios[1].valor) / (parseInt(vm.negocios[0].valor) + parseInt(vm.negocios[1].valor) + parseInt(vm.negocios[2].valor)) * 100);
                    vm.porcent2 = Math.round(parseInt(vm.negocios[2].valor) / (parseInt(vm.negocios[0].valor) + parseInt(vm.negocios[1].valor) + parseInt(vm.negocios[2].valor)) * 100);
                    vm.total1 = (parseInt(vm.negocios[0].valor) + parseInt(vm.negocios[2].valor));
                    vm.total2 = (parseInt(vm.negocios[0].valor) + parseInt(vm.negocios[1].valor));
                    vm.vl1 = parseFloat(vm.negocios[1].valor);
                    vm.vl2 = parseFloat(vm.negocios[2].valor);
                    if (isNaN(parseInt(vm.porcent1))) {
                        vm.porcent1 = 0;
                        if (vm.total1 == 0) {
                            vm.total1 = 1;
                        }
                    }
                    if (isNaN(parseInt(vm.porcent2))) {
                        vm.porcent2 = 0;
                        if (vm.total2 == 0) {
                            vm.total2 = 1;
                        }
                    }

                    $timeout(function() {
                        var ctx = document.getElementById('myChart').getContext('2d');
                        var ctx2 = document.getElementById('myChart2').getContext('2d');
                        Chart.defaults.global.tooltips.enabled = false;

                        var myChart = new Chart(ctx, {
                            type: 'doughnut',
                            data: {
                                labels: ['Total', 'Ganhos'],
                                datasets: [{
                                    label: false,
                                    data: [vm.vl1, vm.total1],
                                    backgroundColor: ['white', '#94DF97'],
                                    borderColor: 'transparent',
                                    hoverBackgroundColor: ['white', '#94DF97']
                                }]
                            },
                            options: {
                                responsive: true,
                                cutoutPercentage: 80,
                                legend: {
                                    display: false
                                },
                                plugins: {
                                    datalabels: {
                                        display: false
                                    }
                                }
                            }
                        });

                        var myChart2 = new Chart(ctx2, {
                            type: 'doughnut',
                            data: {
                                labels: ['', ''],
                                datasets: [{
                                    label: '',
                                    data: [vm.vl2, vm.total2],
                                    backgroundColor: ['white', '#E7AFAF'],
                                    borderColor: 'transparent',
                                    hoverBackgroundColor: ['white', '#E7AFAF']
                                }]
                            },
                            options: {
                                responsive: true,
                                cutoutPercentage: 80,
                                legend: {
                                    display: false
                                },
                                plugins: {
                                    datalabels: {
                                        display: false
                                    }
                                }
                            },
                        });
                    });
                });
            }, function(error) {
                $log.error('Erro: ', error);
            });
        }

        vm.verificarAdm = function() {
            PessoasService.verificarAdm(Cookies.get('crm_funil_hash')).then(function(response) {
                if (response.data == 'Administrador') {
                    vm.adm = true;
                } else {
                    vm.adm = false;
                }
            }, function(error) {
                $log.error('Erro: ', error);
            });
        };
        vm.verificarAdm();

        vm.carregarFechado = function() {
            $timeout(function() {
                $('.formatacao_text').css('display', 'none');
                $('.tamanho_font_icon').animate({
                    borderSpacing: 0
                }, {
                    step: function(now, fx) {
                        $(this).css('-webkit-transform', 'rotate(' + now + 'deg)');
                        $(this).css('-moz-transform', 'rotate(' + now + 'deg)');
                        $(this).css('transform', 'rotate(' + now + 'deg)');
                    },
                    duration: 1
                }, 'linear');
                $timeout(function() {
                    for (var x = 0; x < vm.atendimentos.length; x++) {
                        //var id = vm.atendimentos[x].fk_user_id;
                        var id = vm.atendimentos[x].atdcli_id;
                        var t = x;
                        $('#' + id + '' + t).css('display', 'block');
                        $('#gigar_' + id + '' + t).animate({
                            borderSpacing: 90
                        }, {
                            step: function(now, fx) {
                                $(this).css('-webkit-transform', 'rotate(' + now + 'deg)');
                                $(this).css('-moz-transform', 'rotate(' + now + 'deg)');
                                $(this).css('transform', 'rotate(' + now + 'deg)');
                            },
                            duration: 1
                        }, 'linear');
                    }
                }, 1);
                for (var x = 0; x < vm.atendimentos.length; x++) {
                    //var id = vm.atendimentos[x].fk_user_id;
                    var id = vm.atendimentos[x].atdcli_id;
                    var descricao = vm.atendimentos[x].atdcli_descricao;
                    $('#' + id + '' + x).html(descricao);
                }
            }, 1);
        };


        if ($routeParams.sememail) {
            vm.sememail = 'null';
        }



        if ($routeParams.tag) {
            vm.comtag = parseInt($routeParams.tag);
            vm.comtagativo = parseInt(1);
        }


        if (!$location.search().categoria) {
            vm.categoria_filtro = {
                'categ_id': '',
                'categ_nome': ''
            };
            if ($routeParams.categoria) {
                //vm.categoriaFiltro = true;
                PessoasService.findCategFilter($routeParams.categoria).then(function(response) {
                    vm.categoria_filtro.categ_nome = response.data[0].categ_nome;
                    vm.categoria_filtro.categ_id = response.data[0].categ_id;
                    //vm.categoria_filtro.categ_id = parseInt($routeParams.categoria);
                }, function(error) {
                    $log.error('Erro: ', error);
                });

            }
        }
        /*
                vm.compare = function(a, b) {
                    // compara os números retornando true para o filter. Utilizado para funcionar filtro de número na catogoria
                    return parseInt(a, 10) === parseInt(b, 10);
                };*/

        vm.tags_busca = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                PessoasService.findTags(vm.saasid).then(function(response) {
                    vm.tags_lista = response.data;
                }, function(error) {
                    $log.error('Erro: ', error);
                });
            });
        };

        vm.tagsClientes = function(cliente) {
            PessoasService.findCategoriasCliente(cliente).then(function(response) {
                vm.tags_lista_cliente = response.data;
                $('.box_tags').niceScroll();
            }, function(error) {
                $log.error('Erro: ', error);
            });
        };

        vm.LimpaBusca = function() {
            delete vm.busca;
            vm.buscando = false;
            vm.searchUrl('busca', null);
            vm.buscaClientes();
        };

        vm.tagsClientesHistorico = function(cliente) {
            PessoasService.findTagsHistorico(cliente).then(function(response) {
                vm.tags_lista_cliente_historico = response.data;
                $('.box_table').niceScroll();
            }, function(error) {
                $log.error('Erro: ', error);
            });
        };

        vm.remover_tag = function(tag) {
            var cliente = $routeParams.slugCliente;
            PessoasService.removeTag(tag, cliente).then(function(response) {
                vm.tagsClientes($routeParams.slugCliente);
                vm.tagsClientesHistorico($routeParams.slugCliente);
            }, function(error) {
                $log.error('Erro: ', error);
            });
        };

        $('#modalTag').on('shown.bs.modal', function() {
            vm.tags_busca(); // quando a modal abrir carrega as tag
        });

        if ($routeParams.slugCliente) {
            vm.edicao = true;
            vm.tagsClientes($routeParams.slugCliente);
            vm.tagsClientesHistorico($routeParams.slugCliente);
        } else {
            vm.edicao = false;
        }
        vm.salvando_tags = false;
        vm.SalvaTags = function() {
            vm.salvando_tags = true;
            angular.element('.salvar_tags').html('<div class="spinner"></div>');
            var tags = angular.element('.active_tag');
            var NewTagsCliente = [];
            for (var i = tags.length - 1; i >= 0; i--) {
                NewTagsCliente.push(angular.element(tags[i]).attr('data-tag-id'));
            }
            NewTagsCliente.push(vm.tags_lista_cliente);
            var NewTagsCliente = JSON.stringify(NewTagsCliente);

            PessoasService.salvaTagsCliente(NewTagsCliente, $routeParams.slugCliente).then(function(response) {
                vm.tagsClientes($routeParams.slugCliente);
                vm.tagsClientesHistorico($routeParams.slugCliente);
                $('#modalTag').modal('hide');
                vm.salvando_tags = false;
                angular.element('.salvar_tags').html('Salvar');
            }, function(error) {
                console.log("errrroooooooooooo")
                $log.error('Erro: ', error);
            });

        };

        // marcar tags
        vm.add_remove_tag = function(a) {
            // elemento ativo
            if (angular.element('#tag_list_' + a).hasClass('active_tag')) {
                angular.element('#tag_list_' + a).removeClass('active_tag');
            } else {
                angular.element('#tag_list_' + a).addClass('active_tag');
            }
        };
        vm.a_z = true;
        vm.order_az = function() {
            if (vm.a_z == true) { // coloca em order decrescente
                vm.a_z = false;
                vm.z_a = true;
                vm.direcao = !vm.direcao;
            } else { // coloca em order crescente
                vm.direcao = !vm.direcao;
                vm.a_z = true;
                vm.z_a = false;
            }
        };

        vm.size = function(itens) {
            //$log.info(itens.length);
        };

        vm.mudaPessoa = function() {
            // if (vm.cliente.tipo_pessoa == 'F') {
            vm.mask_cpf = '999.999.999-99';
            vm.mask_name = 'CPF';
            vm.validar_cpf = true;
            // } else {
            //     vm.mask_cpf = '99.999.999/9999-99';
            //     vm.mask_name = 'CNPJ';
            //     vm.validar_cpf = true;
            // }
        };

        vm.cliente = {
            'nome': '',
            'email': '',
            'telefone': 55,
            'telefone2': 55,
            'categoria': '0',
            'empresa': '',
            'cepCampo': '',
            'logradouro': '',
            'complemento': '',
            'bairro': '',
            'city': 'Cidade',
            'cpf': '',
            'descricao': '',
            'id_endereco': '0',
            'uf': 'UF',
            'numero': '',
            'tipo_pessoa': 'F',
            'ativo': '',
            'saasid': '',
            'cli_data_criacao': '',
            'cli_data_alteracao': '',
            'id_unidade_odontotop': '0',
            'status_atd_odontotop': '0',
            'data_entrada': moment().format('DD/MM/YYYY HH:mm'),
            'data_agendamento_odontotop': '',
        };

        vm.carregarcliente = function() {
            /*VALIDAR SAAS --- INÍCIO*/
            vm.items = loadSaas(Cookies.get('crm_funil_hash'));
            vm.items.then(function(items) {
                vm.saasid = items;
                if ($routeParams.slugCliente) {
                    vm.items = validarSaas('pessoas', $routeParams.slugCliente, vm.saasid);
                    vm.items.then(function(existe) {
                        if (existe) { // saas id confere
                            vm.buscarDados();
                        } else {
                            $window.history.back();
                        }
                    });
                }
            });
            /*VALIDAR SAAS --- FIM*/
        };

        vm.buscarEmailsAdicionaisCliente = function(id_cliente) {
            var dados = {
                'id_cliente': id_cliente
            };
            PessoasService.buscarEmailsAdicionaisCliente(dados).then(function(response) {
                if (response.data != '') {
                    vm.emailsAdd = response.data;
                } else {
                    vm.emailsAdd = [];
                }
            }, function(error) {
                $log.error('Erro: ', error);
            });
        };

        vm.editandoCadastro = false;

        vm.buscaUsuariosSelect = function(dadosc, acao) {
            vm.selectUsuarios = [];
            vm.listaResponsaveis = [];
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'user_hash': Cookies.get('crm_funil_hash'),
                    'acao': acao,
                    'mod_id': 1
                };
                negociosService.findUsuarios(dados).then(function(response) {
                    var local = $window.localStorage.getItem('5c479de2');
                    vm.listaResponsaveis = response.data;
                    vm.selectUsuarios.push({
                        'icon': '<i class=\'fa fa-times\'></i>',
                        'id': '',
                        'name': 'Nenhum responsável',
                        'ticked': '',
                        'slug': ''
                    });
                    angular.forEach(response.data, function(value, key) {
                        if (value.user_avatar == null) { // COLOCA LETRA NO LUGAR DO AVATAR
                            var icon = '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                                '<div class="cell table">' +
                                '<div class="row">' +
                                '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182;font-size:11px;">' + value.sigla +
                                '</span>' +
                                '</div>' +
                                '</div>' +
                                '</div>';
                        } else { // COLOCA IMAGEM DO AVATAR
                            var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                        }
                        if (value.user_ativo == 1) { // SE O USUÁRIO ESTÁ ATIVO MOSTRA O NOME NORMAL
                            var nome = value.user_nome;
                        } else { // SENÃO ACRESCENTA (INATIVO)
                            var nome = value.user_nome + ' (Inativo)';
                        }
                        var adicionar = false;
                        if (value.user_ativo == 0) { // QUANDO ESTÁ EDITANDO, SOMENTE ADICIONAR O USUÁRIO INATIVO SE ESTIVER SELECIONADO PARA ESTA PESSOA
                            if (dadosc) {
                                if (dadosc[0].user_hash == value.user_hash) {
                                    adicionar = true;
                                } else {
                                    adicionar = false;
                                }
                            }
                        } else {
                            adicionar = true;
                        }
                        if (!$routeParams.slugCliente) { // CADASTRO
                            if (Cookies.get('crm_funil_hash') == value.user_hash) { // SELECIONA O USUÁRIO LOGADO NO SELECT
                                if (adicionar) {
                                    vm.selectUsuarios.push({
                                        'icon': icon,
                                        'id': value.user_id,
                                        'name': nome,
                                        'ticked': true,
                                        'slug': value.user_slug
                                    });
                                }
                            } else {
                                if (adicionar) {
                                    vm.selectUsuarios.push({
                                        'icon': icon,
                                        'id': value.user_id,
                                        'name': nome,
                                        'ticked': false,
                                        'slug': value.user_slug
                                    });
                                }
                            }
                        } else { // EDIÇÃO
                            if (adicionar) {
                                vm.selectUsuarios.push({
                                    'icon': icon,
                                    'id': value.user_id,
                                    'name': nome,
                                    'ticked': false,
                                    'slug': value.user_slug
                                });
                            }
                        }
                        if (dadosc) {
                            angular.forEach(vm.selectUsuarios, function(value, key) {
                                if (dadosc[0].user_id) { // COM RESPONSÁVEL
                                    if (value.id == dadosc[0].user_id) {
                                        vm.selectUsuarios[key].ticked = true;
                                    }
                                } else { // SEM RESPONSÁVEL, MARCAR 'NENHUM RESPONSÁVEL' NA LISTAGEM
                                    if (value.id == '') {
                                        vm.selectUsuarios[key].ticked = true;
                                    }
                                }
                            });
                        }
                    });
                }, function(error) {
                    $log.error(error);
                });
            });
        };
        if (!$routeParams.slugCliente) {
            //vm.buscaUsuariosSelect();
        }

        vm.rand = (new Date()).toString();
        vm.buscarDados = function() {
            PessoasService.findUnique($routeParams.slugCliente).then(function(response) {
                /*console.log('aaaaaaaaaaaaaaa', response.data)*/
                $timeout(function() {
                    var empresa = {
                        'emp_id': '',
                        'emp_nome': '',
                        'emp_slug': ''
                    };
                    var cargo = {
                        'car_id': '',
                        'car_nome': ''
                    };
                    var origem = {
                        'orc_id': '',
                        'orc_nome': ''
                    };
                    var responsavel = [];
                    if (response.data[0].emp_id && response.data[0].emp_nome || response.data[0].emp_slug) {
                        empresa = {
                            'emp_id': response.data[0].emp_id,
                            'emp_nome': response.data[0].emp_nome,
                            'emp_slug': response.data[0].emp_slug
                        };
                    }
                    if (response.data[0].car_id && response.data[0].car_nome) {
                        cargo = {
                            'car_id': response.data[0].car_id,
                            'car_nome': response.data[0].car_nome
                        };
                    }
                    if (response.data[0].orc_id && response.data[0].orc_nome) {
                        origem = {
                            'orc_id': response.data[0].orc_id,
                            'orc_nome': response.data[0].orc_nome
                        };
                    }
                    /*RESPONSÁVEL -- INÍCIO*/
                    if (response.data[0].user_nome != null) {
                        var sigla = response.data[0].user_nome.substr(0, 1);
                    } else {
                        var sigla = '';
                    }
                    if (response.data[0].user_avatar) {
                        responsavel.push({
                            'icon': API.url + '/usuarios/avatar/' + response.data[0].user_avatar,
                            'id': response.data[0].user_id,
                            'name': response.data[0].user_nome,
                            'ticked': true,
                            'slug': response.data[0].user_slug,
                            'sigla': sigla,
                            'ativo': response.data[0].user_ativo
                        });
                        // $log.info('dentro do if ', responsavel);
                    } else {
                        responsavel.push({
                            'icon': null,
                            'id': response.data[0].user_id,
                            'name': response.data[0].user_nome,
                            'ticked': true,
                            'slug': response.data[0].user_slug,
                            'sigla': sigla,
                            'ativo': response.data[0].user_ativo
                        });
                        // $log.info('dentro do if ', responsavel);
                    }
                    vm.buscaUsuariosSelect(response.data, 'ALTERA');
                    /*console.log('response.data[0]', response.data[0])*/
                    vm.cliente = {
                        'nome': response.data[0].cli_nome,
                        'email': response.data[0].cli_email,
                        'telefone': response.data[0].cli_telefone ? response.data[0].cli_telefone : 55,
                        'telefone2': response.data[0].cli_telefone2 ? response.data[0].cli_telefone2 : 55,
                        'categoria': response.data[0].categ_id,
                        'empresa': empresa,
                        'cargo': cargo,
                        'cargo_nome': cargo['car_nome'],
                        'origem': origem,
                        'origem_nome': origem['orc_nome'],
                        'categoria_nome': response.data[0].categ_nome,
                        'cepCampo': response.data[0].endcli_cep,
                        'logradouro': response.data[0].endcli_logradouro,
                        'complemento': response.data[0].endcli_complemento,
                        'bairro': response.data[0].endcli_bairro,
                        'city': response.data[0].endcli_cidade,
                        'pais': response.data[0].endcli_pais,
                        'cpf': response.data[0].cli_cpf,
                        'descricao': response.data[0].cli_observacao,
                        'uf': response.data[0].endcli_estado,
                        'numero': response.data[0].endcli_numero,
                        'latitude': response.data[0].endcli_latitude,
                        'longitude': response.data[0].endcli_longitude,
                        'id_endereco': response.data[0].endcli_id,
                        'ativo': response.data[0].cli_ativo,
                        'slug': $routeParams.slugCliente,
                        'tipo': 'p',
                        'cli_data_criacao': response.data[0].cli_data_criacao,
                        'cli_data_alteracao': response.data[0].cli_data_alteracao,
                        'id_unidade_odontotop': response.data[0].id_unidade_odontotop ? response.data[0].id_unidade_odontotop : '0',
                        'status_atd_odontotop': response.data[0].status_atd_odontotop ? response.data[0].status_atd_odontotop : '0',
                        'data_entrada': response.data[0].cli_data_entrada ? response.data[0].cli_data_entrada : moment().format('DD/MM/YYYY HH:mm'),
                        'data_agendamento_odontotop': response.data[0].data_agendamento_odontotop ? response.data[0].data_agendamento_odontotop : '',
                    };

                    if (vm.saasid == 1 || vm.saasid == 177 || vm.saasid == 212 || vm.saasid == 225){
                        vm.buscaUnidadesOdontoTop(true);
                        vm.buscaStatusAtdOdontoTop(true);
                        vm.initDateRange(true);                        
                    }

                    vm.id_cliente = response.data[0].cli_id;
                    vm.buscarEmailsAdicionaisCliente(response.data[0].cli_id);

                    if (response.data[0].cli_email != '') {
                        vm.getEmailsRecebidos(response.data[0].cli_email);
                    } else {
                        vm.listEmailsRecebidosLoaded = true;
                        vm.listEmailsRecebidos = [];
                    }

                    $timeout(function() {
                        if (vm.cliente.cpf == null) {
                            vm.cliente.cpf = '';
                        }
                        if (response.data[0].endcli_cidade == 'undefined') {
                            var cidade = 'Cidade';
                        } else {
                            cidade = response.data[0].endcli_cidade;
                        }
                        vm.buscaUf(response.data[0].endcli_estado, cidade);

                        vm.mudaPessoa();
                        vm.titulo_pagina = response.data[0].cli_nome;
                        if (!vm.cliente.categoria) {
                            vm.cliente.categoria = '0';
                        }
                        if (!vm.cliente.categoria_nome) {
                            vm.cliente.categoria_nome = '';
                        }
                    });
                    if (vm.cliente.cpf) {
                        if (vm.cliente.cpf.length == 11) {
                            var novo = vm.cliente.cpf;
                            novo = vm.cliente.cpf.substring(0, 3) + '.';
                            novo = novo + '' + vm.cliente.cpf.substring(3, 6) + '.';
                            novo = novo + '' + vm.cliente.cpf.substring(6, 9) + '-';
                            vm.cpfformatado = novo + '' + vm.cliente.cpf.substring(9, 11);
                        }
                    }

                    if ($location.url().substring(0, 21) == '/pessoas/atendimento/') {
                        $timeout(function() {
                            vm.cliPg = false;
                            if (vm.textarea == false && vm.atdPg == false) {
                                vm.carregarPg = false;
                            }
                        });
                    } else {
                        vm.carregarPg = false;
                    }
                    vm.endereco_rota = '';
                    if (vm.cliente.latitude && vm.cliente.longitude) {
                        if (vm.saasid == 1 || vm.saasid == 149) {
                            vm.endereco_rota = 'https://www.google.com.br/maps/dir/-27.278894,-52.335003/' + vm.cliente.latitude + ',' + vm.cliente.longitude + '/';
                        } else {
                            vm.endereco_rota = 'https://www.google.com.br/maps/dir//' + vm.cliente.latitude + ',' + vm.cliente.longitude + '/';
                        }
                    }

                    vm.responsavel = responsavel;

                    vm.naoMostrarBtn = true;
                    vm.naoMostrarBtnRespdifer = function() {
                        var local = $window.localStorage.getItem('5c479de2');
                        var valLocal = JSON.parse(API.funilRC.decrypt(local));
                        var localPessoas = $window.localStorage.getItem('isModPessoas');
                        var valLocalPessoas = JSON.parse(API.funilRC.decrypt(localPessoas));

                        switch (valLocalPessoas.exclui) {
                            case '3':
                                if (valLocal[0].user_id == vm.responsavel[0].id) {
                                    return vm.naoMostrarBtn = true;
                                } else {
                                    return vm.naoMostrarBtn = false;
                                };
                            case '2':
                                if (valLocal[0].fk_gru_id == response.data[0].fk_gru_id) {
                                    return vm.naoMostrarBtn = true;
                                } else {
                                    return vm.naoMostrarBtn = false;
                                };
                                break;
                            default:
                                return vm.naoMostrarBtn = true;
                        }
                    };
                    vm.naoMostrarBtnRespdifer();
                    // $log.info(vm.responsavel);
                    /*RESPONSÁVEL -- FIM*/

                    vm.naoMostrarBtnSalvarAltera = true; // Mostrar ou não botão de eidtar ou de salvar dados editados
                    vm.naoMostrarBtnSalvarResp = function() {
                        var local = $window.localStorage.getItem('5c479de2');
                        var valLocal = JSON.parse(API.funilRC.decrypt(local));
                        var localPessoas = $window.localStorage.getItem('isModPessoas');
                        var valLocalPessoas = JSON.parse(API.funilRC.decrypt(localPessoas));

                        switch (valLocalPessoas.altera) {
                            case '3':
                                if (valLocal[0].user_id == vm.responsavel[0].id) {
                                    return vm.naoMostrarBtnSalvarAltera = true;
                                } else {
                                    return vm.naoMostrarBtnSalvarAltera = false;
                                };
                                break;
                            case '2':
                                if (valLocal[0].fk_gru_id == response.data[0].fk_gru_id) {
                                    return vm.naoMostrarBtnSalvarAltera = true;
                                } else {
                                    return vm.naoMostrarBtnSalvarAltera = false;
                                };
                                break;
                            default:
                                return vm.naoMostrarBtnSalvarAltera = true;
                        }


                    };
                    vm.naoMostrarBtnSalvarResp();

                    if (response.data[0].endcli_pais != 'Brasil' && response.data[0].endcli_pais != null && response.data[0].endcli_pais != '') {
                        vm.mostrarCombo = false;
                        vm.mostrarText = true;
                    }

                    vm.editandoCadastro = true;
                });
            }, function(error) {
                $log.error('Erro: ', error);
            });
        };

        vm.getEmailsRecebidos = (email) => {
            tokenNodejs.get('mailmkt/contatos/lista-emails-recebidos/' + email)
                .then((result) => {
                    for (let data of result.data.data.response) {
                        data.date_sent = $filter('formatDataUTC')(data.date_sent)
                        for (let historic of data.historic_opening) {
                            historic.datetime = $filter('formatDataUTC')(historic.datetime)
                        }
                    }

                    $timeout(() => {
                        vm.listEmailsRecebidosLoaded = true;
                        vm.listEmailsRecebidos = result.data.data.response
                    })
                })
                .catch((err) => {
                    console.log(err)
                    vm.listEmailsRecebidosLoaded = true;
                    vm.listEmailsRecebidos = []
                })
        }

        vm.reativarCliente = function() {
            swal({
                    title: 'Deseja realmente reativar esta pessoa?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    closeOnConfirm: false
                },
                function() {
                    $window.onkeydown = null;
                    $window.onfocus = null;

                    PessoasService.findUnique($routeParams.slugCliente).then(function(response) {
                        if (response.data[0].cli_email != '' && response.data[0].cli_email != null) { // E-MAIL ESTÁ PREENCHIDO
                            var dados = {
                                'slug': $routeParams.slugCliente,
                                'saasid': vm.saasid,
                                'email': response.data[0].cli_email
                            };
                            PessoasService.validaExisteEmailEdit(dados).then(function(response) {
                                if (response.data != '') {
                                    if (response.data[0].tipo == 'p') {
                                        swal({
                                            title: 'Operação não realizada!',
                                            text: 'Já existe uma pessoa ativa com o e-mail informado.<br><span style=\'font-size:12px;font-weight: 300;\'>Clique no link abaixo para acessá-la.</span><br><br><a href=\'#/pessoas/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                                response.data[0].nome + '</a>',
                                            type: 'error',
                                            showConfirmButton: true,
                                            html: true
                                        }, function() {
                                            $timeout(function() {
                                                $('#email').focus();
                                            });
                                        });
                                    } else if (response.data[0].tipo == 'e') {
                                        swal({
                                            title: 'Operação não realizada!',
                                            text: 'Já existe uma empresa ativa com o e-mail informado.<br><span style=\'font-size:12px;font-weight: 300;\'>Clique no link abaixo para acessá-la.</span><br><br><a href=\'#/empresas/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                                response.data[0].nome + '</a>',
                                            type: 'error',
                                            showConfirmButton: true,
                                            html: true
                                        }, function() {
                                            $timeout(function() {
                                                $('#email').focus();
                                            });
                                        });
                                    }
                                } else {
                                    vm.reativar();
                                }
                            }, function(error) {
                                $log.error(error);
                            });
                        } else { // NÃO TEM E-MAIL PREENCHIDO
                            vm.reativar();
                        }
                    }, function(error) {
                        $log.error(error);
                    });
                });
        };

        vm.reativar = function() {
            // LIMITAÇÃO DE LEADS --- INÍCIO
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) {
                vm.saasid = items;
                vm.leads = verificaLeads(vm.saasid); // carregar saas id
                vm.leads.then(function(info) {
                    if (info.limite <= info.quantia) {
                        swal.close();
                        $('#modalAlertaLeads').modal({
                            backdrop: 'static',
                            keyboard: false
                        });
                        vm.salvando = false;
                    } else {
                        var dados = {
                            'slug': $routeParams.slugCliente,
                        };
                        PessoasService.reativaCliente(dados).then(function(response) {
                            swal({
                                title: 'Reativado!',
                                text: 'Pessoa reativada com sucesso!',
                                type: 'success',
                                showConfirmButton: false,
                                timer: 2000
                            });
                            $timeout(function() {
                                vm.carregarcliente();
                            }, 2000);
                        }, function(error) {
                            $log.error(error);
                        });
                    }
                });
            });
            // LIMITAÇÃO DE LEADS --- FIM
        };


        if ($routeParams.slugCliente) {
            vm.carregarPg = true;
            vm.carregarcliente();
        } else {
            vm.titulo_pagina = 'Cadastrar Pessoa';
            vm.mudaPessoa();
        }


        vm.buscaCep = function(cep) {
            if (cep) {
                angular.element('#cepbusca').addClass('input_loading');
                $http({
                    method: 'GET',
                    //url: 'https://api.postmon.com.br/v1/cep/' + cep
                    url: 'https://viacep.com.br/ws/'+ cep + '/json'
                }).then(function successCallback(response) {
                    var dados = response.data;
                    console.log("buscaCEP");
                    console.log(response.data);

                    vm.cliente.pais = 'Brasil';
                    vm.mudouPais('Brasil');
                    vm.cliente.logradouro = '';
                    $timeout(function() {
                        if (dados.endereco) {
                            vm.cliente.logradouro = dados.endereco;
                        }
                        if (dados.logradouro) {
                            vm.cliente.logradouro = dados.logradouro;
                        }
                        if (dados.bairro) {
                            vm.cliente.bairro = dados.bairro;
                        } else {
                            vm.cliente.bairro = '';
                        }
                        /*if (dados.estado) {
                            vm.cliente.uf = dados.estado;
                        }
                        if (dados.cidade) {
                            vm.cidade_resultado = dados.cidade;
                            vm.buscaCidade(vm.cidade_resultado);
                        }*/
                        if (dados.uf) {
                            vm.cliente.uf = dados.uf;
                        }
                        if (dados.localidade) {
                            vm.cidade_resultado = dados.localidade;
                            vm.buscaCidade(vm.cidade_resultado);
                        }
                        vm.disable_endereco = true;
                        vm.cep_valid = false;
                        angular.element('#cepbusca').removeClass('input_loading');
                    }, 500);
                }, function errorCallback(response) {
                    vm.disable_endereco = false;
                    vm.cep_valid = true;
                    angular.element('#cepbusca').removeClass('input_loading');
                });
            } else {
                vm.disable_endereco = false;
            }
        };

        vm.limparEndereco = function() {
            vm.cliente.logradouro = '';
            vm.cliente.numero = '';
            vm.cliente.complemento = '';
            vm.cliente.bairro = '';
            vm.cliente.cepCampo = '';
            vm.cliente.uf = 'UF';
            vm.cliente.city = 'Cidade';
            vm.cliente.pais = 'Brasil';
            vm.disable_endereco = false;
            vm.cidades = [];
        }

        vm.buscaUf = function(uf, cidade) {
            $http({
                method: 'GET',
                url: API.url + 'app/template/json/cidades.json'
            }).then(function successCallback(response) {
                vm.estados = response.data.estados;
                vm.cliente.uf = uf;
                vm.buscaCidade(cidade);
            }, function errorCallback(response) {
                $log.info('Error buscaCep: ', response);
            });
        };
        vm.paises = [];
        vm.acabouPais = function() {
            $timeout(function() {
                if (!$routeParams.slugCliente) {
                    vm.cliente.pais = 'Brasil';
                }
            });
        };
        vm.buscaPaises = function() {
            $http({
                method: 'GET',
                url: API.url + 'app/template/json/paises.json'
            }).then(function successCallback(response) {
                vm.paises = response.data;
                vm.acabouPais();
            }, function errorCallback(response) {
                $log.info('Error buscaCep: ', response);
            });
        };

        vm.mostrarCombo = true;
        vm.mostrarText = false;
        vm.mudouPais = function(pais) {
            if (pais == 'Brasil') { // Brasil, mostra combos
                $('.text_cidade_estado').fadeOut(400);
                vm.cliente.uf = 'UF';
                vm.cliente.city = 'Cidade';
                $timeout(function() {
                    $('.combo_cidade_estado').fadeIn();
                    $timeout(function() {
                        vm.mostrarCombo = true;
                        vm.mostrarText = false;
                    }, 100);
                }, 400);
            } else { // Outro país, mostra campos text
                $('.combo_cidade_estado').fadeOut(400);
                vm.cliente.uf = '';
                vm.cliente.city = '';
                $timeout(function() {
                    $('.text_cidade_estado').fadeIn();
                    $timeout(function() {
                        vm.mostrarCombo = false;
                        vm.mostrarText = true;
                        $timeout(function() {
                            $('.campo_estado_text').focus();
                        }, 100);
                    }, 100);
                }, 400);
            }
        };
        vm.buscaCidade = function(cidade) {
            vm.cidades = vm.estados;
            vm.cidades = $filter('filter')(vm.cidades, {
                'sigla': vm.cliente.uf
            });
            if (vm.cidade_resultado) {
                vm.cliente.city = vm.cidade_resultado;
                vm.cliente.city = cidade;
                delete vm.cidade_resultado;
            } else {
                if (cidade == undefined) {
                    if ($location.url().substring(0, 18) == '/pessoas/cadastro/') {
                        vm.cliente.city = 'Cidade';
                    } else {
                        vm.cliente.city = '';
                    }
                } else {
                    vm.cliente.city = cidade;
                }
            }
        };

        var first = true;
        vm.data_filtro = {};
        vm.ordenacao = 'cad_rec';
        vm.cidade_filtro = '';
        vm.estado_filtro = '';
        vm.categ_filtro = '';
        vm.car_filtro = '';
        vm.resp_filtro = '';
        vm.orc_filtro = '';
        vm.buscaClientes = function(start) {
            vm.carregando = true;
            if (Cookies.get('crm_funil_startDate')) {} else {
                Cookies.set('crm_funil_startDate', moment().format('YYYY-MM-DD'));
            }
            if (Cookies.get('crm_funil_endDate')) {

            } else {
                Cookies.set('crm_funil_endDate', moment().format('YYYY-MM-DD'));
            }
            // glauber
            var rolIdDecry = API.funilRC.decrypt($window.localStorage.getItem('isModPessoas'));
            var rolStorageObj = JSON.parse(rolIdDecry);
            var rolID = rolStorageObj.visualiza;
            //dadosUser
            var daUserStorage = API.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
            var daUser = JSON.parse(daUserStorage);
            var gruId = daUser[0].fk_gru_id;
            var userId = daUser[0].user_id;

            if (!start){
                vm.data_filtro.start = moment().subtract(300, 'years');
                vm.data_filtro.end = moment().format('YYYY-MM-DD');
            }
            vm.data_filtro.saasid = vm.saasid;
            vm.data_filtro.rolid = rolID;
            vm.data_filtro.userID = userId;
            vm.data_filtro.gruID = gruId;

            if (!$routeParams.tag || !$routeParams.sememail) {
                vm.data_filtro.start = Cookies.get('crm_funil_startDate');
                vm.data_filtro.end = Cookies.get('crm_funil_endDate');
                vm.data_filtro.saasid = vm.saasid;
                vm.data_filtro.rolid = rolID;
                vm.data_filtro.userID = userId;
                vm.data_filtro.gruID = gruId;
            }

            vm.data_filtro.filtro = {
                'busca': vm.busca,
                'ordenacao': vm.ordenacao,
                'cidade': vm.cidade_filtro,
                'estado': vm.estado_filtro,
                'categoria': vm.categ_filtro,
                'cargo': vm.car_filtro,
                'responsavel': vm.resp_filtro,
                'origem': vm.orc_filtro,
            };
            if (first) { // PRIMEIRO CARREGAMENTO
                var search = $location.search();
                if (search.busca) {
                    vm.buscando = true;
                    vm.busca = search.busca;
                    vm.data_filtro.filtro.busca = vm.busca;
                }
                if (search.ordenar) {
                    vm.ordenacao = search.ordenar;
                    vm.data_filtro.filtro.ordenacao = vm.ordenacao;
                }
                if (search.cidade) {
                    vm.cidade_filtro = search.cidade;
                    vm.data_filtro.filtro.cidade = vm.cidade_filtro;
                }
                if (search.estado) {
                    vm.estado_filtro = search.estado;
                    vm.data_filtro.filtro.estado = vm.estado_filtro;
                    vm.buscaCidades(search.estado);
                }
                if (search.categoria) {
                    vm.categ_filtro = search.categoria;
                    vm.data_filtro.filtro.categoria = vm.categ_filtro;
                }
                if (search.cargo) {
                    vm.car_filtro = search.cargo;
                    vm.data_filtro.filtro.cargo = vm.car_filtro;
                }
                if (search.responsavel) {
                    vm.resp_filtro = search.responsavel;
                    vm.data_filtro.filtro.responsavel = vm.resp_filtro;
                }
                if (search.origem) {
                    vm.orc_filtro = search.origem;
                    vm.data_filtro.filtro.origem = vm.orc_filtro;
                }
            }

            //verifica se tem na url filtro data_criacao
            var filterINurlBuscarCli = $location.search().data_criacao;
            if (filterINurlBuscarCli) {
                var splitData = filterINurlBuscarCli.split('_')

                vm.data_filtro.start = splitData[0];
                vm.data_filtro.end = splitData[1];
            }
            /*console.log('dentro da função buscar cliente')
            console.log(vm.data_filtro)

            console.log('vm.zerouDataCriacao: ', vm.zerouDataCriacao)*/
            if (vm.zerouDataCriacao || !filterINurlBuscarCli) {
                vm.data_filtro.start = null;
                vm.data_filtro.end = null;
            }


            PessoasService.findAll(vm.data_filtro).then(function(response) {
                console.log('findAll');
                console.log(response.data);

                first = false;
                if (response.data.dados != 0) {
                    /*console.log('pessoas', response.data)*/
                    vm.clientesLista = response.data.dados;
                    vm.totalRegistros = response.data.total;
                } else {
                    vm.clientesLista = '';
                    vm.totalRegistros = 0;
                }
            }, function(error) {
                $log.error(error);
            });
        };


        vm.parseDataMoment = function(data) {
            moment.locale('pt-br');

            if (data) {
                var dataUtc = moment.utc(data);
                var parseData = moment(dataUtc).format('l');
                return parseData;
            } else {
                return null;
            }
        }

        vm.buscaFiltro = function(qual) {

            if (!qual.length) {
                qual = null;
                vm.buscando = false;
            } else {
                vm.buscando = true;
            }

            vm.searchUrl('busca', qual);
            vm.buscaClientes();
        }

        $('.input_search').tooltipster({
            position: 'left',
            trigger: 'click',
            timer: 4000,
            theme: 'tooltipster-light'
        });

        vm.carregarClientes = function() {
            if ($routeParams.pessoaFiltro) {
                vm.box_filtro_home = true;

                if (Cookies.get('crm_funil_startDate')) {

                } else {
                    Cookies.set('crm_funil_startDate', moment().format('YYYY-MM-DD'));
                }
                if (Cookies.get('crm_funil_endDate')) {

                } else {
                    Cookies.set('crm_funil_endDate', moment().format('YYYY-MM-DD'));
                }


                if ($.isEmptyObject($routeParams)) {
                    vm.data_filtro = {
                        'start': moment().subtract(300, 'years'),
                        'end': moment().format('YYYY-MM-DD'),
                        'saasid': vm.saasid
                    };
                } else {
                    if ($routeParams.tag || $routeParams.sememail) {
                        vm.data_filtro = {
                            'start': moment().subtract(300, 'years'),
                            'end': moment().format('YYYY-MM-DD'),
                            'saasid': vm.saasid
                        };
                    } else {
                        vm.data_filtro = {
                            'start': Cookies.get('crm_funil_startDate'),
                            'end': Cookies.get('crm_funil_endDate'),
                            'saasid': vm.saasid
                        };
                    }
                };



                angular.element('.chip_filtrando_home').html('Pessoas período');

                PessoasService.findPf(vm.data_filtro).then(function(response) {
                    if (response.data != 0 && response.data != 'null') {
                        $timeout(function() {
                            vm.clientesLista = response.data;
                        });
                    } else {
                        $timeout(function() {
                            vm.clientesLista = '';
                        });
                    }
                }, function(error) {
                    $log.error('Erro: ', error);
                });
            } else {
                vm.buscaClientes();
            }
        };

        /*SAAS --- INÍCIO*/
        vm.items = loadSaas(Cookies.get('crm_funil_hash'));
        vm.items.then(function(items) {
            vm.saasid = items;
            vm.carregarClientes();
        });
        /*SAAS --- FIM*/

        vm.carregando_mais_inf = false;

        vm.carregando = true;

        vm.acabou = function() {
            vm.carregando = false;
            vm.carregando_mais_inf = false;
            $('.linha_listagem').removeClass('disable_a');
        };

        vm.limite = 30;

        vm.carregar_tudo = true;
        vm.maisinf = function() {
            $('.linha_listagem').addClass('disable_a');
            vm.carregando_mais_inf = true;
            //vm.carregar_tudo = true;
            $timeout(function() {
                vm.limite = vm.limite + 30;
                vm.carregando_mais_inf = false;
            }, 500);
        };

        vm.buscaCategorias = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                PessoasService.findCategorias(vm.saasid).then(function(response) {
                    vm.categorias = response.data;
                    //vm.filtrarCategoria();
                }, function(error) {
                    $log.error(error);
                });
            });
        };

        vm.buscaCidades = function(estado) {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                PessoasService.findCidades(vm.saasid, estado).then(function(response) {
                    vm.cidades = response.data;
                }, function(error) {
                    $log.error(error);
                });
            });
        };

        /*vm.selecionarCidade = function(dados) {
            if (dados == 'nenhuma') {
                vm.cidade_filtro = '';
            } else if (dados == '') {
                delete vm.cidade_filtro;
            } else {
                vm.cidade_filtro = dados;
            }
        };*/
        vm.addTooltipCidade = function() {
            $('.box_campo_cidade_filtro > select').tooltip({
                title: 'Selecione um estado',
                placement: 'bottom'
            });
        }

        vm.buscaEstados = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                PessoasService.findEstados(vm.saasid).then(function(response) {
                    vm.estados = response.data;
                    var search = $location.search();
                    if (!search.estado) {
                        vm.addTooltipCidade();
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        };

        vm.testaCpf = function() {
            var cpf = vm.cliente.cpf;
            var i;
            var s = cpf;
            var c = s.substr(0, 9);
            var dv = s.substr(9, 2);
            var d1 = 0;
            var v = false;

            for (i = 0; i < 9; i++) {
                d1 += c.charAt(i) * (10 - i);
            }
            if (d1 == 0) {
                v = true;
                return false;
            }
            d1 = 11 - (d1 % 11);
            if (d1 > 9) d1 = 0;
            if (dv.charAt(0) != d1) {
                v = true;
                return false;
            }

            d1 *= 2;
            for (i = 0; i < 9; i++) {
                d1 += c.charAt(i) * (11 - i);
            }
            d1 = 11 - (d1 % 11);
            if (d1 > 9) d1 = 0;
            if (dv.charAt(1) != d1) {
                v = true;
                return false;
            }
            if (cpf == '00000000000' ||
                cpf == '11111111111' ||
                cpf == '22222222222' ||
                cpf == '33333333333' ||
                cpf == '44444444444' ||
                cpf == '55555555555' ||
                cpf == '66666666666' ||
                cpf == '77777777777' ||
                cpf == '88888888888' ||
                cpf == '99999999999') {
                return false;
            }


            if (!v) {
                return true;
            }
        };

        vm.testaCnpj = function() {
            var cnpj = vm.cliente.cpf;
            cnpj = cnpj.replace(/[^\d]+/g, '');

            if (cnpj == '') return false;

            if (cnpj.length != 14)
                return false;

            // Elimina CNPJs invalidos conhecidos
            if (cnpj == '00000000000000' ||
                cnpj == '11111111111111' ||
                cnpj == '22222222222222' ||
                cnpj == '33333333333333' ||
                cnpj == '44444444444444' ||
                cnpj == '55555555555555' ||
                cnpj == '66666666666666' ||
                cnpj == '77777777777777' ||
                cnpj == '88888888888888' ||
                cnpj == '99999999999999')
                return false;

            // Valida DVs
            var tamanho = cnpj.length - 2;
            var numeros = cnpj.substring(0, tamanho);
            var digitos = cnpj.substring(tamanho);
            var soma = 0;
            var pos = tamanho - 7;
            for (var i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(0))
                return false;

            tamanho = tamanho + 1;
            numeros = cnpj.substring(0, tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(1))
                return false;

            return true;

        };


        vm.excluirCliente = function(id) {
            var dados = {
                'slug': $routeParams.slugCliente
            };
            PessoasService.verifDadosAdicionais(dados).then(function(response) {
                /*console.log("verifDadosAdicionais");
                console.log(response.data);*/
                if (response.data == 1) { // EXISTEM NEGOCIAÇÕES E/OU COMPROMISSOS CADASTRADOS
                    swal({
                            title: 'Este registro possui vínculos.',
                            text: 'Percebemos que este registro possui negociações e/ou compromissos vinculados a ele.' +
                                '<br> Selecione uma das ações abaixo.<br>' +
                                '<div class="inputs">' +
                                '<div class="radio"><label><input type="radio" name="check_existe_registros" value="1"><span>REMOVER TODOS REGISTROS</span></label></div>' +
                                '<div class="txt_coment">Esta opção removerá o lead e todos registros vinculados.</div>' +
                                '</div>' +
                                '<div class="inputs">' +
                                '<div class="radio"><label><input type="radio" name="check_existe_registros" value="2"><span>REMOVER APENAS O LEAD</span></label></div>' +
                                '<div class="txt_coment">Esta opção mantém compromissos e negociações ativos, removendo apenas o lead.</div>' +
                                '</div>',
                            html: true,
                            type: 'warning',
                            customClass: 'modalAvisoExisteDados',
                            showCancelButton: true,
                            confirmButtonColor: '#DD6B55',
                            confirmButtonText: 'Continuar',
                            cancelButtonText: 'Cancelar',
                            closeOnConfirm: false
                        },
                        function() {
                            $window.onkeydown = null;
                            $window.onfocus = null;
                            var check = $("input[name=check_existe_registros]:checked").val();
                            if (!check) { // NENHUMA OPÇÃO SELECIONADA
                                swal.showInputError("Selecione uma das opções!");
                                return false;
                            } else { // PROSSEGUIR
                                vm.continuarExcluir(check);
                            }
                        });
                } else {
                    swal({
                            title: 'Deseja excluir este registro?',
                            text: 'Esta ação é irreversível',
                            html: true,
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#DD6B55',
                            confirmButtonText: 'Sim',
                            cancelButtonText: 'Não',
                            closeOnConfirm: false
                        },
                        function() {
                            $window.onkeydown = null;
                            $window.onfocus = null;

                            vm.continuarExcluir();
                        });
                }
            }, function(error) {
                $log.error(error);
            });
        };

        vm.continuarExcluir = function(check) {
            var previous = Cookies.get('previousUrl');
            var verifUrl = "/pessoas/";
            var userPerfilStorage = API.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
            var parseUser = JSON.parse(userPerfilStorage);
            var roleStorageMod = FactoryRoles.checkRolesStorage('isModPessoas'); // function FactoryRoles

            // atributs sql
            var userid = parseUser[0].user_id;
            var gruid = parseUser[0].fk_gru_id;
            var roleExcluiStorage = roleStorageMod.exclui;

            var dataExclui = {
                'slug': $routeParams.slugCliente,
                'userId': userid,
                'gruId': gruid,
                'roleExclui': roleExcluiStorage
            };

            if (check) {
                dataExclui.check = check;
            }

            PessoasService.deleteCliente(dataExclui).then(function(response) {
                if (response.data.length == '0') {
                    swal({
                        title: 'Sem Permissão!',
                        text: 'Cliente não pode ser removido, você não possui permissão!',
                        type: 'warning',
                        showConfirmButton: false,
                        // confirmButtonText: "OK"
                        timer: 3000
                    });
                    $timeout(function() {
                        //$location.url('/pessoas');
                        if ($window.history.length > 1) {
                            if (previous.match(verifUrl)) { // NÃO VOLTAR PARA PESSOA
                                $location.url('/pessoas');
                            } else {
                                $window.history.back();
                            }
                        } else {
                            $location.url('/pessoas');
                        }
                    }, 3000);
                } else {
                    swal({
                        title: 'Removido!',
                        text: 'Cliente removido com sucesso!',
                        type: 'success',
                        showConfirmButton: false,
                        timer: 2000
                    });
                    $timeout(function() {
                        //$location.url('/pessoas');
                        if ($window.history.length > 1) {
                            if (previous.match(verifUrl)) { // NÃO VOLTAR PARA PESSOA
                                $location.url('/pessoas');
                            } else {
                                $window.history.back();
                            }
                        } else {
                            $location.url('/pessoas');
                        }
                    }, 2000);
                }
            }, function(error) {
                $log.error(error);
            });
        }

        vm.validarEmail = function(save) {
            vm.salvando = true;
            vm.validandoEmail = true;
            if ($routeParams.slugCliente) { // EDITANDO PESSOA

                if (vm.cliente.email != '' && vm.cliente.email != null) {
                    vm.salvando = true;
                    vm.validandoEmail = true;
                    var enviar = {
                        'email': vm.cliente.email,
                        'slug': $routeParams.slugCliente,
                        'saasid': vm.saasid
                    };

                    PessoasService.validaExisteEmailEdit(enviar).then(function(response) {
                        if (response.data != 0) { // existe cliente com o email
                            if (response.data[0].tipo == 'p') {
                                swal({
                                    title: 'E-mail já existente!',
                                    text: 'Já existe um cliente cadastrado com o email informado:<br><br><a href=\'#/pessoas/emailsAdd/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                        response.data[0].nome + '</a>',
                                    type: 'warning',
                                    showConfirmButton: true,
                                    allowEscapeKey: false,
                                    html: true
                                }, function() {
                                    $window.onkeydown = null;
                                    $window.onfocus = null;
                                    $timeout(function() {
                                        $('#email').focus();
                                    });
                                });
                            } else if (response.data[0].tipo == 'e') {
                                swal({
                                    title: 'E-mail já existente!',
                                    text: 'Já existe uma empresa cadastrada com o email informado:<br><br><a href=\'#/empresas/emailsAdd/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                        response.data[0].nome + '</a>',
                                    type: 'warning',
                                    showConfirmButton: true,
                                    allowEscapeKey: false,
                                    html: true
                                }, function() {
                                    $window.onkeydown = null;
                                    $window.onfocus = null;

                                    $timeout(function() {
                                        $('#email').focus();
                                    });
                                });
                            }
                            $timeout(function() {
                                vm.salvando = false;
                                vm.validandoEmail = false;
                                vm.invalidMail = true;
                            });
                        } else { // não existe cliente com o email
                            $timeout(function() {
                                vm.salvando = false;
                                vm.validandoEmail = false;
                                vm.invalidMail = false;
                                angular.element(".icon_check_valida_email").fadeIn();
                                $timeout(function() {
                                    angular.element(".icon_check_valida_email").fadeOut();
                                }, 3000);
                            });
                        }
                    }, function(error) {
                        $log.error(error);
                    });
                } else { // não existe email
                    $timeout(function() {
                        vm.salvando = false;
                        vm.validandoEmail = false;
                        vm.invalidMail = false;
                        angular.element(".icon_check_valida_email").hide();
                    });
                }

            } else { // CADASTRANDO PESSOA

                if (vm.cliente.email != '' && vm.cliente.email != null) {
                    vm.salvando = true;
                    vm.validandoEmail = true;
                    var enviar = {
                        'email': vm.cliente.email,
                        'saasid': vm.saasid
                    };
                    PessoasService.validaExisteEmail(enviar).then(function(response) {
                        if (response.data != 0) { // existe cliente com o email
                            if (response.data[0].tipo == 'p') {
                                swal({
                                    title: 'E-mail já existente!',
                                    text: 'Já existe um cliente cadastrado com o email informado:<br><br><a href=\'#/pessoas/emailsAdd/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                        response.data[0].nome + '</a>',
                                    type: 'warning',
                                    showConfirmButton: true,
                                    allowEscapeKey: false,
                                    html: true
                                }, function() {
                                    $window.onkeydown = null;
                                    $window.onfocus = null;
                                    $timeout(function() {
                                        $('#email').focus();
                                    });
                                });
                            } else if (response.data[0].tipo == 'e') {
                                swal({
                                    title: 'E-mail já existente!',
                                    text: 'Já existe uma empresa cadastrada com o email informado:<br><br><a href=\'#/empresas/emailsAdd/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                        response.data[0].nome + '</a>',
                                    type: 'warning',
                                    showConfirmButton: true,
                                    allowEscapeKey: false,
                                    html: true
                                }, function() {
                                    $window.onkeydown = null;
                                    $window.onfocus = null;
                                    $timeout(function() {
                                        $('#email').focus();
                                    });
                                });
                            }
                            $timeout(function() {
                                vm.salvando = false;
                                vm.validandoEmail = false;
                                vm.invalidMail = true;
                            });
                        } else { // não existe cliente com o email
                            $timeout(function() {
                                vm.salvando = false;
                                vm.validandoEmail = false;
                                vm.invalidMail = false;
                                angular.element(".icon_check_valida_email").fadeIn();
                                $timeout(function() {
                                    angular.element(".icon_check_valida_email").fadeOut();
                                }, 3000);
                            });
                        }
                    }, function(error) {
                        $log.error(error);
                    });
                } else { // não existe email
                    $timeout(function() {
                        vm.salvando = false;
                        vm.validandoEmail = false;
                        vm.invalidMail = false;
                        angular.element(".icon_check_valida_email").hide();
                    });
                }

            }
        }

        vm.analisaTel = function(tel) {
            console.log('tel:', tel)
        }

        vm.cadastrarCliente = function(cliente, socialNet) {
            if (cliente.telefone == 55) {
                cliente.telefone = null;
            }

            if (cliente.telefone2 == 55) {
                cliente.telefone2 = null;
            }

            vm.salvando = true;
            angular.element('#submit').html('<div class="spinner"></div>');

            var error = false;
            if (vm.cliente.cpf != '' && vm.cliente.cpf != null) {
                var cpf = vm.testaCpf();
                if (!cpf == true) {
                    vm.validar_cpf = false;
                    vm.salvando = false;
                    error = true;
                    vm.sanfonadois = true;
                    vm.texto_valido = 'CPF';
                    $('html, body').animate({
                        scrollTop: $("#cpf").offset().top - 30
                    }, 500);
                } else {
                    vm.validar_cpf = true;
                }
            } else {
                vm.validar_cpf = true;
            }
            if (vm.cliente.nome == '') {
                vm.validar_nome = false;
                vm.salvando = false;
                error = true;
            }
            if (vm.saasid == 149) {
                if (vm.cliente.email == '') {
                    vm.validar_email = true;
                    vm.salvando = false;
                    error = true;
                }
                if (vm.cliente.categoria == 0) {
                    vm.validar_categoria = false;
                    vm.salvando = false;
                    error = true;
                }
            }

            if (error) {
                vm.tabSelecionada = 0;
                vm.salvando = false;
                angular.element('#submit').html('Salvar Pessoa');
                $('html, body').animate({
                    scrollTop: $("body").offset().top
                }, 500);
                return false;
            }

            if ($routeParams.slugCliente) { // editando pessoa
                var dados = [];
                if (cliente.uf == 'UF') {
                    cliente.uf = '';
                }
                if (cliente.city == 'Cidade') {
                    cliente.city = '';
                }
                dados.push(cliente);
                dados.push(socialNet);

                var localUs = API.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
                var localUdParse = JSON.parse(localUs);
                var localModPes = API.funilRC.decrypt($window.localStorage.getItem('isModPessoas'));
                var localModPesParse = JSON.parse(localModPes);
                var uid = localUdParse[0].user_id;
                var gid = localUdParse[0].fk_gru_id;

                vm.editPesDate = {
                    'saasid': vm.saasid,
                    'userid': uid,
                    'gruid': gid,
                    'roleAltera': localModPesParse.altera
                };

                // VALIDAÇÃO DE EMAIL JÁ EXISTENTE EDIÇÃO --- INÍCIO
                if (vm.cliente.email != '' && vm.cliente.email != null) {
                    if (vm.invalidMail) {
                        var enviar = {
                            'email': vm.cliente.email,
                            'slug': $routeParams.slugCliente,
                            'saasid': vm.saasid
                        };

                        PessoasService.validaExisteEmailEdit(enviar).then(function(response) {
                            if (response.data != 0) { // existe cliente com o email
                                if (response.data[0].tipo == 'p') {
                                    swal({
                                        title: 'E-mail já existente!',
                                        text: 'Já existe um cliente cadastrado com o email informado:<br><br><a href=\'#/pessoas/emailsAdd/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                            response.data[0].nome + '</a>',
                                        type: 'warning',
                                        showConfirmButton: true,
                                        html: true
                                    }, function() {
                                        $window.onkeydown = null;
                                        $window.onfocus = null;
                                        $timeout(function() {
                                            $('#email').focus();
                                        });
                                    });
                                } else if (response.data[0].tipo == 'e') {
                                    swal({
                                        title: 'E-mail já existente!',
                                        text: 'Já existe uma empresa cadastrada com o email informado:<br><br><a href=\'#/empresas/emailsAdd/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                            response.data[0].nome + '</a>',
                                        type: 'warning',
                                        showConfirmButton: true,
                                        html: true
                                    }, function() {
                                        $window.onkeydown = null;
                                        $window.onfocus = null;
                                        $timeout(function() {
                                            $('#email').focus();
                                        });
                                    });
                                }
                                vm.salvando = false;
                                angular.element('#submit').html('Salvar Pessoa');
                            } else { // não existe cliente com o email
                                vm.validarExisteCpfEdit(dados);
                            }
                        }, function(error) {
                            $log.error(error);
                        });
                    } else {
                        vm.validarExisteCpfEdit(dados);
                    }
                } else {
                    vm.validarExisteCpfEdit(dados);
                }
                // VALIDAÇÃO DE EMAIL JÁ EXISTENTE EDIÇÃO --- FIM

            } else { // cadastrando pessoa
                var dados = [];
                if (cliente.uf == 'UF') {
                    cliente.uf = '';
                }
                if (cliente.city == 'Cidade') {
                    cliente.city = '';
                }
                dados.push(cliente);
                dados.push(socialNet);
                // dados.push(vm.editPesDate);

                // VALIDAÇÃO DE EMAIL JÁ EXISTENTE CADASTRO --- INÍCIO
                if (vm.cliente.email != '' && vm.cliente.email != null) {
                    if (vm.invalidMail) {
                        var enviar = {
                            'email': vm.cliente.email,
                            'saasid': vm.saasid
                        };
                        PessoasService.validaExisteEmail(enviar).then(function(response) {
                            if (response.data != 0) { // existe cliente com o email
                                if (response.data[0].tipo == 'p') {
                                    swal({
                                        title: 'E-mail já existente!',
                                        text: 'Já existe um cliente cadastrado com o email informado:<br><br><a href=\'#/pessoas/emailsAdd/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                            response.data[0].nome + '</a>',
                                        type: 'warning',
                                        showConfirmButton: true,
                                        html: true
                                    }, function() {
                                        $window.onkeydown = null;
                                        $window.onfocus = null;
                                        $timeout(function() {
                                            $('#email').focus();
                                        });
                                    });
                                } else if (response.data[0].tipo == 'e') {
                                    swal({
                                        title: 'E-mail já existente!',
                                        text: 'Já existe uma empresa cadastrada com o email informado:<br><br><a href=\'#/empresas/emailsAdd/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                            response.data[0].nome + '</a>',
                                        type: 'warning',
                                        showConfirmButton: true,
                                        html: true
                                    }, function() {
                                        $window.onkeydown = null;
                                        $window.onfocus = null;
                                        $timeout(function() {
                                            $('#email').focus();
                                        });
                                    });
                                }
                                vm.salvando = false;
                                angular.element('#submit').html('Salvar Pessoa');
                            } else { // não existe cliente com o email
                                vm.validarExisteCpf(dados);
                            }
                        }, function(error) {
                            $log.error(error);
                        });
                    } else {
                        vm.validarExisteCpf(dados);
                    }
                } else {
                    vm.validarExisteCpf(dados);
                }
                // VALIDAÇÃO DE EMAIL JÁ EXISTENTE CADASTRO --- FIM
            }
        };

        vm.validarExisteCpf = function(dados) {
            if (vm.cliente.cpf != '') {
                var enviar = {
                    'cpf': vm.cliente.cpf,
                    'saasid': vm.saasid
                };
                PessoasService.validaExisteCpf(enviar).then(function(response) {
                    if (response.data != 0) { // existe cliente com o email
                        swal({
                            title: 'CPF já existente!',
                            text: 'Já existe um cliente cadastrado com o CPF informado:<br><br><a href=\'#/pessoas/info/' + response.data[0].cli_slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                response.data[0].cli_nome + '</a>',
                            type: 'warning',
                            showConfirmButton: true,
                            html: true
                        }, function() {
                            $window.onkeydown = null;
                            $window.onfocus = null;
                            $timeout(function() {
                                $('#cpf').focus();
                            });
                        });
                        vm.salvando = false;
                        angular.element('#submit').html('Salvar Pessoa');
                    } else { // não existe cliente com o email
                        vm.salvarCliente(dados);
                    }
                }, function(error) {
                    $log.error(error);
                });
            } else {
                vm.salvarCliente(dados);
            }
        };

        vm.validarExisteCpfEdit = function(dados) {
            if (vm.cliente.cpf != '' && vm.cliente.cpf != null) {
                var enviar = {
                    'cpf': vm.cliente.cpf,
                    'slug': $routeParams.slugCliente,
                    'saasid': vm.saasid
                };
                PessoasService.validaExisteCpfEdit(enviar).then(function(response) {
                    if (response.data != 0) { // existe cliente com o email
                        swal({
                            title: 'CPF já existente!',
                            text: 'Já existe um cliente cadastrado com o CPF informado:<br><br><a href=\'#/pessoas/info/' + response.data[0].cli_slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                response.data[0].cli_nome + '</a>',
                            type: 'warning',
                            showConfirmButton: true,
                            html: true
                        }, function() {
                            $window.onkeydown = null;
                            $window.onfocus = null;
                            $timeout(function() {
                                $('#cpf').focus();
                            });
                        });
                        vm.salvando = false;
                        angular.element('#submit').html('Salvar Pessoa');
                    } else { // não existe cliente com o email
                        vm.attCliente(dados);
                    }
                }, function(error) {
                    $log.error(error);
                });
            } else {
                vm.attCliente(dados);
            }
        };

        vm.salvarCliente = function(dados) {
            // LIMITAÇÃO DE LEADS --- INÍCIO
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) {
                vm.saasid = items;
                vm.leads = verificaLeads(vm.saasid); // carregar saas id
                vm.leads.then(function(info) {
                    if (info.limite <= info.quantia) {
                        $('#modalAlertaLeads').modal({
                            backdrop: 'static',
                            keyboard: false
                        });
                        vm.salvando = false;
                    } else {
                        /* CONVERTER ENDEREÇO EM COORDENADAS */
                        vm.logradouro = dados[0].logradouro;
                        vm.numero = dados[0].numero;
                        vm.city = dados[0].city;
                        vm.uf = dados[0].uf;
                        vm.pais = dados[0].pais;

                        if (vm.logradouro == '' && vm.city != '') {
                            vm.endereco = vm.city + ', ' + vm.uf + ', ' + vm.pais;
                        } else if (vm.numero == '' && vm.city != '' && vm.logradouro != '') {
                            vm.endereco = vm.logradouro + ', ' + vm.city + ', ' + vm.uf + ', ' + vm.pais;
                        } else if (vm.city == '') {
                            vm.endereco = '';
                        } else {
                            vm.endereco = vm.logradouro + ', ' + vm.numero + ', ' + vm.city + ', ' + vm.uf + ', ' + vm.pais;
                        };


                        if (vm.endereco != '') {
                            var geocoder = new google.maps.Geocoder();
                            geocoder.geocode({
                                'address': vm.endereco
                            }, function(results, status) {
                                if (status == google.maps.GeocoderStatus.OK && results.length > 0) {
                                    var localizacao = results[0].geometry.location,
                                        lat = localizacao.lat(),
                                        lng = localizacao.lng();
                                    vm.latitude = lat;
                                    vm.longitude = lng;
                                }
                                dados[0].latitude = vm.latitude;
                                dados[0].longitude = vm.longitude;

                                vm.continuarSalvar(dados);
                            });
                        } else {
                            vm.continuarSalvar(dados);
                        }
                    }
                });
            });
            // LIMITAÇÃO DE LEADS --- FIM
        };

        vm.continuarSalvar = function(dados) {
            dados[0].saasid = vm.saasid;
            dados[2] = vm.emailsAdd;
            vm.salvando = true;
            angular.element('#submit').html('<div class="spinner"></div>');
            PessoasService.create(dados).then(function(response) {
                console.log("continuarSalvar");
                console.log(response.data);
                //webhook
                var splitIdCli = response.data.split('-')[0]
                FactoryWebhook.triggerPessoa(splitIdCli);

                var respNotifId = dados['0'].responsavel['0'].id;
                var respNotNome = dados['0'].responsavel['0'].name;

                var localUs = API.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
                var localUdParse = JSON.parse(localUs);

                var userLogId = localUdParse['0'].user_id;
                var userLognome = localUdParse['0'].user_nome;

                if (respNotifId != userLogId) {
                    // dispara a factory criar notificação
                    FactoryNotificacao.createNotificacao({
                        "idUser": respNotifId,
                        "origem": userLognome,
                        "mensagem": 'cadastraPes',
                        "link": API.url + '/pessoas/info/' + response.data

                    })
                }

                vm.salvando = false;
                swal({
                    title: 'Cadastrado com sucesso!',
                    type: 'success',
                    text: 'Cliente cadastrado com sucesso!',
                    showConfirmButton: false,
                    timer: 1500
                });
                $timeout(function() {
                    vm.salvando = false;
                    angular.element('#submit').html('Salvar Pessoa');
                    $location.url('/pessoas/info/' + response.data);
                }, 1500);
            }, function(error) {
                $log.error(error);
            });
        };

        vm.attCliente = function(dados) {

            dados[0].saasid = vm.saasid;
            dados[2] = vm.emailsAdd;
            vm.salvando = true;
            angular.element('#submit').html('<div class="spinner"></div>');

            /* CONVERTER ENDEREÇO EM COORDENADAS */

            vm.logradouro = dados[0].logradouro;
            vm.numero = dados[0].numero;
            vm.city = dados[0].city;
            vm.uf = dados[0].uf;
            vm.pais = dados[0].pais;

            if (vm.logradouro == '' && vm.numero == '') {
                vm.endereco = vm.city + ', ' + vm.uf + ', ' + vm.pais;
            } else if (vm.numero == '' && vm.city != '' && vm.logradouro != '') {
                vm.endereco = vm.logradouro + ', ' + vm.city + ', ' + vm.uf + ', ' + vm.pais;
            } else if (vm.city == '') {
                vm.endereco = '';
            } else {
                vm.endereco = vm.logradouro + ', ' + vm.numero + ', ' + vm.city + ', ' + vm.uf + ', ' + vm.pais;
            };

            /* APÓS OS TESTES RETIRAR ESTE CONSOLE.LOG */

            //$log.info("Endereço: " + vm.endereco);
            // $log.info('ver dados, ', dados);

            /* APÓS OS TESTES RETIRAR ESTE CONSOLE.LOG ^ */

            if (vm.endereco != '') {
                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({
                    'address': vm.endereco
                }, function(results, status) {
                    if (status == google.maps.GeocoderStatus.OK && results.length > 0) {
                        var localizacao = results[0].geometry.location,
                            lat = localizacao.lat(),
                            lng = localizacao.lng();
                        vm.latitude = lat;
                        vm.longitude = lng;
                    }
                    dados[0].latitude = vm.latitude;
                    dados[0].longitude = vm.longitude;
                    dados[0].userLogado = vm.editPesDate;

                    PessoasService.update(dados, $routeParams.slugCliente).then(function(response) {
                        var respNotifId = dados['0'].responsavel['0'].id;
                        var respNotNome = dados['0'].responsavel['0'].name;

                        var localUs = API.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
                        var localUdParse = JSON.parse(localUs);

                        var userLogId = localUdParse['0'].user_id;
                        var userLognome = localUdParse['0'].user_nome;

                        if (respNotifId != userLogId) {
                            // dispara a factory criar notificação
                            FactoryNotificacao.createNotificacao({
                                "idUser": respNotifId,
                                "origem": userLognome,
                                "mensagem": 'alteraPes',
                                "link": API.url + '/pessoas/info/' + response.data

                            })
                        }

                        if (response.data == '2' || response.data == '3' || response.data == '4') {
                            $location.path('/#/pessoas');
                            alertify.set('notifier', 'delay', 5);
                            alertify.set('notifier', 'position', 'top-right');
                            alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! sem permissão para aterar o cliente');

                        } else {
                            // $location.path('/#/pessoas');
                            alertify.set('notifier', 'delay', 5);
                            alertify.set('notifier', 'position', 'top-right');
                            alertify.success('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Cliente alterado com sucesso!');
                        };

                        $timeout(function() {
                            vm.salvando = false;
                            angular.element('#submit').html('Salvar');
                            // vm.editarCadastroCliente(1);
                            // vm.carregarcliente();
                            // $location.url('/pessoas/info/' + response.data);
                            $route.updateParams({
                                'slugCliente': response.data
                            })
                            $route.reload()
                        });
                    }, function(error) {
                        $log.error(error);
                    });
                });
            } else {
                PessoasService.update(dados, $routeParams.slugCliente).then(function(response) {
                    var splitIdCli = response.data.split('-')[0]
                    FactoryWebhook.triggerPessoaEditar(splitIdCli);

                    var respNotifId = dados['0'].responsavel['0'].id;
                    var respNotNome = dados['0'].responsavel['0'].name;

                    var localUs = API.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
                    var localUdParse = JSON.parse(localUs);

                    var userLogId = localUdParse['0'].user_id;
                    var userLognome = localUdParse['0'].user_nome;

                    if (respNotifId != userLogId) {
                        // dispara a factory criar notificação
                        FactoryNotificacao.createNotificacao({
                            "idUser": respNotifId,
                            "origem": userLognome,
                            "mensagem": 'alteraPes',
                            "link": API.url + '/pessoas/info/' + response.data

                        })
                    }

                    if (response.data == '2' || response.data == '3' || response.data == '4') {
                        $location.path('/#/pessoas');
                        alertify.set('notifier', 'delay', 5);
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.warning('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Ops! sem permissão para aterar o cliente');

                    } else {
                        // $location.path('/#/pessoas');
                        alertify.set('notifier', 'delay', 5);
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.success('<span class="pull-right closedBtn"> <i class="fa fa-times" aria-hidden="true"></i> </span>Cliente alterado com sucesso!');
                    };

                    $timeout(function() {
                        vm.salvando = false;
                        angular.element('#submit').html('Salvar');
                        // vm.editarCadastroCliente(1);
                        // vm.carregarcliente();
                        // $location.url('/pessoas/info/' + response.data);
                        $route.updateParams({
                            'slugCliente': response.data
                        })
                        $route.reload()
                    });
                }, function(error) {
                    $log.error(error);
                });
            }
        };

        vm.carregarAtendimentos = function() {
            vm.atendimento = [];
            var dados = {
                'slug': $routeParams.slugCliente,
                'user_hash': Cookies.get('crm_funil_hash')
            }
            PessoasService.listarAtendimentos(dados).then(function(response) {
                /*console.log("listarAtendimentos");
                console.log(response.data);*/
                if (response.data != '') {
                    vm.atendimentos = response.data;
                    vm.carregarFechado();
                } else {
                    vm.atendimentos = [];
                    vm.atdPg = false;
                }
                if ($location.url().substring(0, 21) == '/pessoas/atendimento/') {
                    vm.ativarTextarea(0);
                } else {
                    vm.ativarTextarea(1);
                }

            }, function(error) {
                $log.error(error);
            });
        };
        vm.carregarAtendimentos();

        vm.toggleAtd = function(class_id, index) {
            if ($('#' + class_id + '' + index).is(':visible')) {
                $('#' + class_id + '' + index).css('display', 'none');
                $('#gigar_' + class_id + '' + index).animate({
                    borderSpacing: 0
                }, {
                    step: function(now, fx) {
                        $(this).css('-webkit-transform', 'rotate(' + now + 'deg)');
                        $(this).css('-moz-transform', 'rotate(' + now + 'deg)');
                        $(this).css('transform', 'rotate(' + now + 'deg)');
                    },
                    duration: 1
                }, 'linear');
            } else {
                $('#' + class_id + '' + index).fadeIn(300);
                $('#gigar_' + class_id + '' + index).animate({
                    borderSpacing: 90
                }, {
                    step: function(now, fx) {
                        $(this).css('-webkit-transform', 'rotate(' + now + 'deg)');
                        $(this).css('-moz-transform', 'rotate(' + now + 'deg)');
                        $(this).css('transform', 'rotate(' + now + 'deg)');
                    },
                    duration: 1
                }, 'linear');
            }
        };

        vm.ativarTextarea = function(x) {
            $timeout(function() {
                if (x == 0) {
                    //$('.ativar_textarea2').fadeOut(100);
                    /*$('.ativar_textarea2').hide();
                    tinyMCE.execCommand('mceRemoveEditor', true, 'novo_atendimento');
                    $timeout(function() {
                        //$('.ativar_btn_novo_atendimento').fadeOut(100);
                        $('.ativar_btn_novo_atendimento').hide();
                        $timeout(function() {
                            //$('.ativar_textarea').fadeIn(500);
                            $('.ativar_textarea').show();
                            $('#textarea').html('<textarea name="novo_atendimento" id="novo_atendimento"></textarea>');
                            tinymce.init({
                                selector: '#novo_atendimento',
                                statusbar: false,
                                menubar: false,
                                resize: true,
                                toolbar1: 'undo redo | fontselect fontsizeselect | bold italic underline | strikethrough inline | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent',
                                auto_focus: 'novo_atendimento',
                                entity_encoding: 'raw',
                            });
                            $timeout(function() {
                                if ($location.url().substring(0, 21) == '/pessoas/atendimento/') {
                                    vm.carregarPg = false;
                                }
                            });
                        });
                    });*/
                    $('.ativar_textarea2').hide();
                    $('#novo_atendimento').trumbowyg('destroy');
                    $('.ativar_btn_novo_atendimento').hide();
                    $('.ativar_textarea').show();
                    $('#textarea').html('<textarea name="novo_atendimento" id="novo_atendimento"></textarea>');
                    $('#novo_atendimento').trumbowyg({
                        svgPath: '/app/plugins/trumbowyg/dist/ui/icons.svg',
                        btns: [
                            ['undo', 'redo'],
                            ['formatting'],
                            ['strong', 'em', 'underline'],
                            ['foreColor', 'backColor'],
                            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                            ['unorderedList', 'orderedList'],
                            ['horizontalRule'],
                            ['removeformat'],
                        ],
                        lang: 'pt_br',
                    });
                    $timeout(function() {
                        if ($location.url().substring(0, 21) == '/pessoas/atendimento/') {
                            vm.carregarPg = false;
                        }
                    });
                } else {
                    $timeout(function() {
                        //$('.ativar_textarea').fadeOut(100);
                        $('.ativar_textarea').hide();
                        $timeout(function() {
                            //$('.ativar_btn_novo_atendimento').fadeIn(500);
                            $('.ativar_btn_novo_atendimento').show();
                            $('#textarea').html('');
                        }, 100);
                        //$('.mensagem_erro').fadeOut(100);
                        $('.mensagem_erro').hide();
                    });
                }
            });
            $timeout(function() {
                vm.textarea = false;
                if (vm.cliPg == false && vm.atdPg == false) {
                    vm.carregarPg = false;
                }
            });
        };

        vm.salvarNovoAtendimento = function() {
            vm.salvandoAtd = true;
            //var novoAtendimento = tinyMCE.get('novo_atendimento').getContent();
            var novoAtendimento = $('#novo_atendimento').trumbowyg('html');
            if (novoAtendimento != '') {
                $('.mensagem_erro').fadeOut(100);
                var dados = {
                    'atendimento': novoAtendimento
                };
                angular.element('.salvando_atendimento').html('<div class="spinner"></div>');
                PessoasService.cadastrarNovoAtendimento($routeParams.slugCliente, Cookies.get('crm_funil_hash'), dados).then(function(response) {
                    //tinyMCE.activeEditor.setContent('');
                    $('#novo_atendimento').trumbowyg('empty');
                    vm.carregarAtendimentos();
                    angular.element('.salvando_atendimento').html('Salvar');
                    vm.salvandoAtd = false;
                }, function(error) {
                    $log.error(error);
                });
            } else {
                vm.salvandoAtd = false;
                $('.mensagem_erro').fadeIn(100);
            }
        };

        vm.maisAtendimento = function() {
            $('.descricao_atendimento').addClass('disable_a');
            vm.carregando_mais_atendimento = true;
            //vm.carregar_tudo = true;
            $timeout(function() {
                vm.limiteAtendimento = vm.limiteAtendimento + 5;
            }, 500);
        };

        vm.acabouAtendimento = function() {
            vm.carregando_mais_atendimento = false;
            $('.descricao_atendimento').removeClass('disable_a');
            vm.carregarFechado();
            if (vm.limiteAtendimento >= vm.atendimentos.length) {
                $('.mais_atendimento').fadeOut(100);
            } else {
                $('.mais_atendimento').fadeIn(100);
            }
            vm.atdPg = false;
            if (vm.cliPg == false && vm.textarea == false) {
                vm.carregarPg = false;
            }
        };

        vm.salvandoAtd = false;
        vm.atdID = '';
        vm.atdDescricao = '';
        //tinyMCE.execCommand('mceRemoveEditor', true, 'alterar_atendimento');
        vm.alterarAtendimento = function(atd, descricao) {
            vm.atdID = atd;
            vm.atdDescricao = descricao;
            /*tinyMCE.execCommand('mceRemoveEditor', true, 'alterar_atendimento');
            $timeout(function() {
                $('#textareaModal').html('<textarea name="alterar_atendimento" id="alterar_atendimento"></textarea>');
                tinymce.init({
                    selector: '#alterar_atendimento',
                    statusbar: false,
                    menubar: false,
                    resize: true,
                    toolbar1: 'undo redo | fontselect fontsizeselect | bold italic underline | strikethrough inline | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent',
                    auto_focus: 'alterar_atendimento',
                    entity_encoding: 'raw',
                    height: '250'
                });
            });
            $timeout(function() {
                tinyMCE.activeEditor.setContent(descricao);
                $timeout(function() {
                    tinyMCE.get('alterar_atendimento').focus();
                }, 500);
            }, 500);*/
            $('#alterar_atendimento').trumbowyg('destroy');
            $('#textareaModal').html('<textarea name="alterar_atendimento" id="alterar_atendimento"></textarea>');
            $('#alterar_atendimento').trumbowyg({
                svgPath: '/app/plugins/trumbowyg/dist/ui/icons.svg',
                btns: [
                    ['undo', 'redo'],
                    ['formatting'],
                    ['strong', 'em', 'underline'],
                    ['foreColor', 'backColor'],
                    ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                    ['unorderedList', 'orderedList'],
                    ['horizontalRule'],
                    ['removeformat'],
                ],
                lang: 'pt_br',
            });
            $('#alterar_atendimento').trumbowyg('html', descricao);
            $timeout(function() {
                $('.trumbowyg-editor').focus();
            }, 500);
        };

        vm.updateAtendimento = function() {
            vm.salvandoAtd = true;
            //vm.atdDescricao = tinyMCE.get('alterar_atendimento').getContent();
            vm.atdDescricao = $('#alterar_atendimento').trumbowyg('html');
            var dados = {
                'atendimento': vm.atdDescricao
            };
            PessoasService.updateAtendimento(vm.atdID, Cookies.get('crm_funil_hash'), dados).then(function(response) {
                $timeout(function() {
                    //tinyMCE.activeEditor.setContent('');
                    $('#alterar_atendimento').trumbowyg('empty');
                    vm.salvandoAtd = false;
                    $('#modalAtendimento').modal('hide');
                    $timeout(function() {
                        $('.ativar_textarea2').fadeOut(100);
                    });
                    vm.carregarAtendimentos();
                });
            }, function(error) {
                $log.error(error);
            });
        };

        vm.cancelarAtleracaoAtendimento = function() {
            //tinyMCE.activeEditor.setContent('');
            $('#alterar_atendimento').trumbowyg('empty');
            $('#novo_atendimento').trumbowyg('empty');
            $('#modalAtendimento').modal('hide');
        };

        vm.excluirAtendimento = function(x) {

            swal({
                    title: 'Deseja excluir este registro?',
                    text: 'Esta ação é irreversível',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    closeOnConfirm: false
                },
                function() {
                    $window.onkeydown = null;
                    $window.onfocus = null;
                    PessoasService.excluirAtendimento(vm.atdID).then(function(response) {
                        if (x == 0) {
                            swal({
                                title: 'Removido!',
                                text: 'Atendimento removido com sucesso!',
                                type: 'success',
                                showConfirmButton: false,
                                timer: 1000
                            });
                            $timeout(function() {
                                //tinyMCE.activeEditor.setContent('');
                                $('#alterar_atendimento').trumbowyg('empty');
                                $('#novo_atendimento').trumbowyg('empty');
                                vm.salvandoAtd = false;
                                vm.cancelarAtleracaoAtendimentoTela();
                                vm.carregarAtendimentos();
                            }, 1000);
                        } else {
                            swal({
                                title: 'Removido!',
                                text: 'Atendimento removido com sucesso!',
                                type: 'success',
                                showConfirmButton: false,
                                timer: 1000
                            });
                            $timeout(function() {
                                //tinyMCE.activeEditor.setContent('');
                                $('#alterar_atendimento').trumbowyg('empty');
                                $('#novo_atendimento').trumbowyg('empty');
                                vm.salvandoAtd = false;
                                $('#modalAtendimento').modal('hide');
                                vm.carregarAtendimentos();
                            }, 1000);
                        }

                    }, function(error) {
                        $log.error(error);
                    });
                },
                function(error) {
                    $log.error(error);
                });

        };

        vm.compromissosCliente = [];
        vm.carregarCompromissos = function() {
            vm.compromissosCliente = [];
            PessoasService.listarCompromissos($routeParams.slugCliente).then(function(response) {
                if (response.data != '' && response.data != null) {
                    vm.compromissosCliente = response.data;
                    for (var x = 0; x < vm.compromissosCliente.length; x++) {
                        if (vm.compromissosCliente[x].compcli_nome.length > 40) {
                            var nome = vm.compromissosCliente[x].compcli_nome.substring(0, 37);
                            nome = nome + '...';
                            vm.compromissosCliente[x].nome_cortado = nome;
                        } else {
                            var nome = vm.compromissosCliente[x].compcli_nome.substring(0, 37);
                            vm.compromissosCliente[x].nome_cortado = nome;
                        }
                    }
                } else {
                    vm.compromissosCliente = [];
                }
            }, function(error) {
                $log.error(error);
            });
        };
        vm.carregarCompromissos();

        vm.tooltipBullet = 0;
        vm.posicionarTooltip = function(x) {
            if (x == 'neg_abertos') {
                vm.tooltipBullet = vm.negocios[0].valor;
            } else if (x == 'neg_ganhos') {
                vm.tooltipBullet = vm.negocios[1].valor;
            } else if (x == 'neg_perdidos') {
                vm.tooltipBullet = vm.negocios[2].valor;
            } else {
                vm.tooltipBullet = 0;
            }
            var height = $('.' + x).height();
            var width = $('.' + x).width();
            var left = $('.' + x).offset().left;
            var top = $('.' + x).offset().top;
            $('.tooltip_bullet').css('margin-left', '' + ((left + width) - 3) + 'px');
            $('.tooltip_bullet').css('margin-top', '' + (top + height + 4) + 'px');
            $('.tooltip_bullet').css('display', 'block');
            vm.tooltipBulletCurrency = $filter('currency')(vm.tooltipBullet, 'R$');
            $('#tooltip_grafic').html(vm.tooltipBulletCurrency);
        };

        vm.setarDisplayNone = function() {
            $('.tooltip_bullet').css('display', 'none');
        };

        //tooltip de compromissos encontrasse no final da tela de index.html - geral
        vm.mostrarTooltipComp = function(i) {
            var left = $('#comp_' + i).offset().left;
            var top = $('#comp_' + i).offset().top;
            var width = $('#comp_' + i).width();
            var height = $('#comp_' + i).height();
            $(".comp_nome").removeClass("fundo_comp").removeClass("fundo_neg");
            $('.comp_nome2').html(vm.compromissosCliente[i].compcli_nome);
            $('.comp_desc').html(vm.compromissosCliente[i].compcli_descricao);
            $('.comp_prazo').html('<i class="glyphicon glyphicon-calendar"></i> ' + vm.compromissosCliente[i].data_prazo + ' - ' + vm.compromissosCliente[i].hora_prazo);
            $('.tooltipCompromissos').css('margin-left', '' + ((width) + left + 20) + 'px');
            $('.tooltipCompromissos').css('margin-top', '' + (top + 20) + 'px');
            $('.tooltipCompromissos').css('display', 'block');
        };

        vm.setarDisplayComp = function() {
            $('.tooltipCompromissos').css('display', 'none');
        };

        vm.destacar = function(x, cl) {
            if (x == 0) {
                $('.' + cl).addClass('destacar_shadow');
            } else {
                $('.' + cl).removeClass('destacar_shadow');
            }
        };

        vm.mostrarEditar = false;
        vm.sanfonatres = false;

        if ($location.url() == '/pessoas/cadastro/') {
            $timeout(function() {
                $('#editarCliente').fadeIn(200);
            });
        } else if ($routeParams.vincularSlug) { // VINCULAR PESSOA À EMPRESA (CARREGA A EMPRESA SELECIONADA A PARTIR DO CADASTRO DE EMPRESAS)
            $timeout(function() {
                $('#editarCliente').fadeIn(200);
            });
            empresasService.findUnique($routeParams.vincularSlug).then(function(response) {
                //carrega o nome da empresa para aparecer marcado automaticamente quando o cadastro for feito através do vinculo de pessoas na tela de informações da empresa
                $('#empresas').attr('value', 'e');
                var cliente_empresa = {
                    'emp_id': response.data[0].emp_id,
                    'emp_nome': response.data[0].emp_nome,
                    'tipo': 'Empresas',
                };
                vm.cliente.empresa = cliente_empresa;
            });
        }


        vm.editarCadastroCliente = function(x) {
            // vm.carregarclientes();
            vm.carregarSocialNetwork();

            // if()
            if (x == 0) {
                $timeout(function() {
                    vm.copiaCliente = angular.copy(vm.cliente);
                });

                if (vm.cliente.uf == '' || vm.cliente.uf == null) {
                    vm.cliente.uf = 'UF';
                }
                if (vm.cliente.city == '' || vm.cliente.city == null) {
                    vm.cliente.city = 'Cidade';
                }
                $timeout(function() {
                    vm.sanfonaum = true;
                    vm.sanfonadois = true;
                    vm.sanfonatres = true;
                    $('#infoCliente').fadeOut(200);
                    $('.botao_editar_novo').fadeOut(200);
                    $('.botao_editar_novo_mob').fadeOut(200);
                    $timeout(function() {
                        $('#editarCliente').fadeIn(200);
                    }, 200);
                    vm.mostrarEditar = true;
                });
            } else {
                // $location.url('/pessoas/info/' + vm.slugCliente);
                // $route.reload();
                $timeout(function() {
                    vm.cliente = angular.copy(vm.copiaCliente);
                });

                if (vm.cliente.uf == 'UF') {
                    vm.cliente.uf = '';
                }
                if (vm.cliente.city == 'Cidade') {
                    vm.cliente.city = '';
                }
                $timeout(function() {
                    $('#editarCliente').fadeOut(200);
                    $('.botao_editar_novo').fadeIn(200);
                    $('.botao_editar_novo_mob').fadeIn(200);
                    $timeout(function() {
                        $('#infoCliente').fadeIn(200);
                        vm.sanfonaum = false;
                        vm.sanfonadois = false;
                        vm.sanfonatres = false;
                    }, 200);
                    vm.mostrarEditar = false;
                });
            }
        };

        vm.social = [];
        vm.carregarSocialNetwork = function() {
            vm.social = [];
            var dados = {
                'slug': $routeParams.slugCliente
            };
            PessoasService.listarSocialNetwork(dados).then(function(response) {
                if (response.data != '0') {
                    var existe = false;
                    for (var x = 0; x < response.data.length; x++) {
                        if (response.data[x].snc_url != '' && response.data[x].snc_url != null) {
                            existe = true;
                        }
                    }
                    if (existe == true) {
                        vm.social = angular.copy(response.data);
                    } else {
                        vm.social = [];
                    }
                    vm.socialEdit = angular.copy(response.data);
                } else {
                    vm.socialEdit = [];
                    vm.social = [];
                }
            }, function(error) {
                $log.error(error);
            });
        };
        vm.carregarSocialNetwork();

        vm.botaoSkype = function() {
            angular.forEach(vm.social, function(value, key) {
                if (value.sn_nome == 'Skype' && value.snc_url != '') {
                    Skype.ui({
                        'name': 'dropdown',
                        'element': 'SkypeButton_Call_1',
                        'participants': [value.snc_url],
                        'imageSize': 24
                    });
                    $timeout(function() {
                        $('#SkypeButton_Call_1').addClass('img_skype');
                        $('#SkypeButton_Call_1 > p > a').html('<img src=\'app/template/img/SVG/' + value.sn_icon + '\' width=\'34\' onmouseover="Skype.showDropdown(\'dropdown_SkypeButton_Call_1\'); return false;" onmouseout="Skype.hideDropdown(\'dropdown_SkypeButton_Call_1\'); return false;">');
                    }, 100);
                }
            });
        };

        vm.copiar = function() {
            var copyTextarea = document.querySelector('#nomeSkype');
            copyTextarea.select();

            var successful = document.execCommand('copy');
            if (successful) {
                //alert("copiado");
                $('.icon_check').fadeIn('fast');
                $timeout(function() {
                    $('.icon_check').fadeOut('fast');
                }, 2000);
            }
        };

        vm.abrirSocialNetwork = function(url) {
            $location.url(url);
        };

        vm.empresasRepeat = [];
        vm.listarEmpresas = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) {
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid
                };
                vm.empresas = [];
                PessoasService.listarEmpresas(dados).then(function(response) {
                    if (response.data != '0') {
                        vm.empresas = response.data;
                    } else {
                        vm.empresas = [];
                    }
                    for (var x = 0; x < vm.empresas.length; x++) {
                        vm.empresasRepeat.push({
                            'emp_id': vm.empresas[x].emp_id,
                            'emp_nome': vm.empresas[x].emp_nome
                        });
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        };
        vm.listarEmpresas();

        // vm.setSelectedIndex = -1;
        // vm.mostrarAutocomplete = function (e) {
        //     $('#item_' + vm.setSelectedIndex).removeClass('selectedIndex');
        //     if (e.keyCode == 40) {
        //         if (vm.setSelectedIndex < vm.empresas.length - 1) {
        //             vm.setSelectedIndex += 1;
        //         }
        //         $('#item_' + vm.setSelectedIndex).addClass('selectedIndex');
        //     } else if (e.keyCode == 38) {
        //         if (vm.setSelectedIndex > 0) {
        //             vm.setSelectedIndex -= 1;
        //         }
        //         $('#item_' + vm.setSelectedIndex).addClass('selectedIndex');
        //     }
        //     // vm.toPosition(vm.setSelectedIndex);
        // }

        // vm.toPosition = function (index) {
        //     console.log(index);
        //     var container = angular.element(document.getElementById('fundo_itens_autocomplete'));
        //     var posicao = angular.element(document.getElementById('item_' + index));
        //     var duration = 100;
        //     if(index == 5){
        //         var offset = 0;
        //         container.scrollToElement(posicao, offset, duration);
        //     }else if(index == 4){
        //         var offset = 160;
        //         container.scrollToElement(posicao, offset, duration);
        //     }

        // }

        vm.growableOptions = {
            displayText: 'Empresas',
            onAdd: function(texto) {
                var deferred = $q.defer();
                // LIMITAÇÃO DE LEADS --- INÍCIO
                vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
                vm.items.then(function(items) {
                    vm.saasid = items;
                    vm.leads = verificaLeads(vm.saasid); // carregar saas id
                    vm.leads.then(function(info) {
                        if (info.limite <= info.quantia) {
                            $('#modalAlertaLeads').modal({
                                backdrop: 'static',
                                keyboard: false
                            });
                            vm.salvando = false;
                        } else { // PROSEGUIR CADASTRO ----------------------------
                            var dados = {
                                'nome': texto,
                                'saasid': vm.saasid
                            };
                            $('.check_cadastro_emp').hide(); // remove o check
                            $('.spinner_cadastro_emp').toggleClass('hide'); // aparecer loading
                            /*PessoasService.cadastrarEmpresaRapido(dados).then(function(response) {
                                vm.listarEmpresas();
                                $(".spinner_cadastro").toggleClass("hide"); // remover loading
                                $(".check_cadastro").fadeIn("slow"); // mostra check sucesso
                                $(".limpar").val(""); // limpar input após cadastro
                                $timeout(function() {
                                    $(".check_cadastro").fadeOut("slow"); // após 2 segundos remove o check
                                }, 2000);
                                var empresa = { 'emp_id': response.data[0].emp_id, 'emp_nome': response.data[0].emp_nome, }
                                deferred.resolve(empresa);
                            }, function(error) {
                                console.error(error);
                            });*/
                            vm.abrirModalCadastroRapido(dados, 'e');
                            $('#modalCadastroRapido').on('hidden.bs.modal', function(e) {
                                $('#modalCadastroRapido').off();
                                if (vm.dadosCadRapido.salvar && vm.dadosCadRapido.qual == 'e') {
                                    negociosService.cadastrarEmpresaRapido(vm.dadosCadRapido).then(function(response) {
                                        FactoryWebhook.triggerEmpresa(response.data.id);

                                        vm.listarEmpresas();
                                        $('.spinner_cadastro_emp').toggleClass('hide'); // remover loading
                                        $('.spinner_cadastro_emp').addClass('hide'); // remover loading
                                        $('.check_cadastro_emp').fadeIn('slow'); // mostra check sucesso
                                        $timeout(function() {
                                            $('.check_cadastro_emp').fadeOut('slow'); // após 2 segundos remove o check
                                        }, 2000);
                                        var empresa = {
                                            'emp_id': response.data.id,
                                            'emp_nome': response.data.nome
                                        };
                                        deferred.resolve(empresa);
                                    }, function(error) {
                                        $log.error(error);
                                    });
                                } else {
                                    $timeout(function() {
                                        $('.spinner_cadastro').toggleClass('hide'); // remover loading
                                    });
                                }
                            });
                        }
                    });
                });
                // LIMITAÇÃO DE LEADS --- FIM
                return deferred.promise;
            }
        };

        /*CONFIGURAÇÕES MODAL CADASTRO RAPIDO --- INICIO*/


        vm.empresasRepeat = [];
        vm.listarEmpresas = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) {
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid
                };
                vm.empresas = [];
                PessoasService.listarEmpresas(dados).then(function(response) {
                    if (response.data != '0') {
                        vm.empresas = response.data;
                    } else {
                        vm.empresas = [];
                    }
                    vm.empresasRepeat.push({
                        'icon': '<i class=\'fa fa-times\'></i>',
                        'id': '',
                        'name': 'Nenhuma empresa',
                        'ticked': ''
                    });
                    for (var x = 0; x < vm.empresas.length; x++) {
                        vm.empresasRepeat.push({
                            'id': vm.empresas[x].emp_id,
                            'name': vm.empresas[x].emp_nome,
                            'ticked': false
                        });
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        };

        vm.listarCargos = function() {
            vm.cargosRepeat = [];
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) {
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid
                };
                PessoasService.listarCargos(dados).then(function(response) {
                    vm.cargosRepeat.push({
                        'icon': '<i class=\'fa fa-times\'></i>',
                        'name': 'Nenhum cargo',
                        'ticked': false,
                        'id': 'nenhum'
                    });
                    if (response.data != '0') {
                        angular.forEach(response.data, function(value, key) {
                            vm.cargosRepeat.push({
                                'name': value.car_nome,
                                'ticked': false,
                                'id': value.car_id
                            });
                        });
                    } else {
                        vm.cargosRepeat = [];
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        };

        vm.abrirModalCadastroRapido = function(dados, qual) {
            vm.dadosCadRapido = dados;
            vm.dadosCadRapido.salvar = false;
            vm.dadosCadRapido.qual = qual;
            vm.dadosCadRapido.categoria = '0';
            vm.dadosCadRapido.email = '';
            vm.dadosCadRapido.telefone = 55;
            vm.dadosCadRapido.telefone2 = 55;
            vm.dadosCadRapido.cargo = '';
            vm.dadosCadRapido.origem = '';
            vm.dadosCadRapido.user_hash = Cookies.get('crm_funil_hash');
            vm.validarCadRapido = {
                'nome': false
            };
            vm.listarEmpresas();
            vm.listarCargos();
            $('#modalCadastroRapido').modal({
                backdrop: 'static',
                keyboard: false,
                show: true
            }).on('shown.bs.modal', function(e) {
                $('#campo_nome_rapido').focus();
            });
        };

        /*var dados = {
            'nome': 'Marcelo'
        }
        vm.abrirModalCadastroRapido(dados, 'p');*/

        vm.aplicarScroll = function() {
            $('.checkBoxContainer').niceScroll();
        };

        /*vm.buscaCategorias = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                PessoasService.findCategorias(vm.saasid).then(function(response) {
                    vm.categorias = response.data;
                }, function(error) {
                    $log.error(error);
                });
            });
        };*/

        vm.ptbr = {
            selectAll: 'Todos',
            selectNone: 'Limpar',
            search: 'Pesquisar...',
            nothingSelected: 'Empresas'
        };
        vm.ptbr2 = {
            selectAll: 'Todos',
            selectNone: 'Limpar',
            search: 'Pesquisar...',
            nothingSelected: 'Cargos'
        };

        vm.efetuarCadastroRapido = function() {
            if (vm.dadosCadRapido.email.length) { // EMAIL PREENCHIDO, VALIDAR EMAIL
                var enviar = {
                    'email': vm.dadosCadRapido.email,
                    'saasid': vm.saasid
                };
                PessoasService.validaExisteEmail(enviar).then(function(response) {
                    if (response.data != 0) { // existe cliente com o email
                        if (response.data[0].tipo == 'p') {
                            swal({
                                title: 'E-mail já existente!',
                                text: 'Já existe um cliente cadastrado com o email informado:<br><br><a href=\'#/pessoas/emailsAdd/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                    response.data[0].nome + '</a>',
                                type: 'warning',
                                showConfirmButton: true,
                                html: true
                            }, function() {
                                $timeout(function() {
                                    $('#email_modal').focus();
                                });
                            });
                        } else if (response.data[0].tipo == 'e') {
                            swal({
                                title: 'E-mail já existente!',
                                text: 'Já existe uma empresa cadastrada com o email informado:<br><br><a href=\'#/empresas/emailsAdd/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                    response.data[0].nome + '</a>',
                                type: 'warning',
                                showConfirmButton: true,
                                html: true
                            }, function() {
                                $timeout(function() {
                                    $('#email_modal').focus();
                                });
                            });
                        }
                    } else { // não existe cliente com o email
                        vm.dadosCadRapido.salvar = true;
                        $('#modalCadastroRapido').modal('hide');
                    }
                }, function(error) {
                    $log.error(error);
                });
            } else { // EMAIL NÃO PREENCHIDO, CONTINUAR SEM VALIDAÇÃO
                vm.dadosCadRapido.salvar = true;
                $('#modalCadastroRapido').modal('hide');
            }
        };


        /*CONFIGURAÇÕES MODAL CADASTRO RAPIDO --- FIM*/

        vm.growableOptions2 = {
            displayText: 'Cargo',
            emptyListText: 'Nenhum cargo cadastrado',
            onAdd: function(texto) {
                var deferred = $q.defer();
                // LIMITAÇÃO DE LEADS --- INÍCIO
                vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
                vm.items.then(function(items) {
                    vm.saasid = items;
                    vm.leads = verificaLeads(vm.saasid); // carregar saas id
                    var dados = {
                        'nome': texto,
                        'saasid': vm.saasid
                    };
                    $('.check_cadastro_cargo').hide(); // remove o check
                    $('.spinner_cadastro_cargo').toggleClass('hide'); // aparecer loading
                    PessoasService.cadastrarCargoRapido(dados).then(function(response) {
                        vm.listarCargos();
                        $('.spinner_cadastro_cargo').toggleClass('hide'); // remover loading
                        $('.check_cadastro_cargo').fadeIn('slow'); // mostra check sucesso
                        $('.limpar').val(''); // limpar input após cadastro
                        $timeout(function() {
                            $('.check_cadastro_cargo').fadeOut('slow'); // após 2 segundos remove o check
                        }, 2000);
                        var cargo = {
                            'car_id': response.data[0].car_id,
                            'car_nome': response.data[0].car_nome
                        };
                        deferred.resolve(cargo);
                    }, function(error) {
                        $log.error(error);
                    });
                });
                return deferred.promise;
            }
        };

        vm.cargosRepeat = [];
        vm.listarCargos = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) {
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid
                };
                vm.empresas = [];
                PessoasService.listarCargos(dados).then(function(response) {
                    if (response.data != '0') {
                        vm.cargosRepeat = response.data;
                    } else {
                        vm.cargosRepeat = [];
                    }
                    //vm.filtrarCargo();
                }, function(error) {
                    $log.error(error);
                });
            });
        };
        vm.listarCargos();


        /*ORIGEM DO CONTATO --- INICIO*/

        vm.growableOptions5 = {
            displayText: 'Selecione a origem',
            emptyListText: 'Nenhuma origem cadastrada',
            onAdd: function(texto) {
                var deferred = $q.defer();
                // LIMITAÇÃO DE LEADS --- INÍCIO
                vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
                vm.items.then(function(items) {
                    vm.saasid = items;
                    var dados = {
                        'nome': texto,
                        'saasid': vm.saasid,
                        'user_hash': Cookies.get('crm_funil_hash')
                    };
                    $('.check_cadastro_origem').hide(); // remove o check
                    $('.spinner_cadastro_origem').toggleClass('hide'); // aparecer loading
                    origemContatoService.cadastrarOrigemContatoRapido(dados).then(function(response) {
                        if (response.data[0].orc_id) {
                            vm.listarOrigem();
                            $('.spinner_cadastro_origem').toggleClass('hide'); // remover loading
                            $('.check_cadastro_origem').fadeIn('slow'); // mostra check sucesso
                            $('.limpar').val(''); // limpar input após cadastro
                            $timeout(function() {
                                $('.check_cadastro_origem').fadeOut('slow'); // após 2 segundos remove o check
                            }, 2000);
                            var origem = {
                                'orc_id': response.data[0].orc_id,
                                'orc_nome': response.data[0].orc_nome
                            };
                        }
                        deferred.resolve(origem);
                    }, function(error) {
                        $log.error(error);
                    });
                });
                return deferred.promise;
            }
        };

        vm.origemRepeat = [];
        vm.listarOrigem = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) {
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'user_hash': Cookies.get('crm_funil_hash')
                };
                vm.empresas = [];
                origemContatoService.findAll(dados).then(function(response) {
                    if (response.data != '0') {
                        vm.origemRepeat = response.data;
                    } else {
                        vm.origemRepeat = [];
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        };
        vm.listarOrigem();

        vm.limparOrigem = function() {
            vm.cliente.origem = {
                'orc_id': '',
                'orc_nome': ''
            };
            if (vm.executando == false) {
                $('#msgOrigem').fadeIn(200);
                vm.executando = true;
                $timeout(function() {
                    $('#msgOrigem').fadeOut(200);
                    vm.executando = false;
                }, 2000);
            }
        };

        vm.limparOrigemCadRapido = function() {
            vm.dadosCadRapido.origem = {
                'orc_id': '',
                'orc_nome': ''
            };
            if (vm.executando == false) {
                $('#msgOrigemCadRapido').fadeIn(200);
                vm.executando = true;
                $timeout(function() {
                    $('#msgOrigemCadRapido').fadeOut(200);
                    vm.executando = false;
                }, 2000);
            }
        };

        /*ORIGEM DO CONTATO --- FIM*/

        //permite o filter funcionar sem ser necessário digitar os acentos para filtrar pela palavra com acentos
        vm.searchFn = function(actual, expected) {
            if (angular.isObject(actual)) return false;

            function removeAccents(value) {
                //substitui os acentos por letras normais
                return value.toString()
                    .replace(/á/g, 'a')
                    .replace(/à/g, 'a')
                    .replace(/ã/g, 'a')
                    .replace(/ä/g, 'a')
                    .replace(/â/g, 'a')
                    .replace(/é/g, 'e')
                    .replace(/è/g, 'e')
                    .replace(/ê/g, 'e')
                    .replace(/ë/g, 'e')
                    .replace(/í/g, 'i')
                    .replace(/ï/g, 'i')
                    .replace(/ì/g, 'i')
                    .replace(/î/g, 'i')
                    .replace(/ó/g, 'o')
                    .replace(/ô/g, 'o')
                    .replace(/õ/g, 'o')
                    .replace(/ö/g, 'o')
                    .replace(/ò/g, 'o')
                    .replace(/ú/g, 'u')
                    .replace(/ü/g, 'u')
                    .replace(/û/g, 'u')
                    .replace(/ù/g, 'u')
                    // .replace(/ç/g, 'c')
                    .replace(/ß/g, 's');
            }
            actual = removeAccents(angular.lowercase('' + actual));
            expected = removeAccents(angular.lowercase('' + expected));

            return actual.indexOf(expected) !== -1;
        };

        vm.executando = false;
        vm.limparEmpresa = function() {
            vm.cliente.empresa = {
                'emp_id': '',
                'emp_nome': '',
                'emp_slug': ''
            };
            if (vm.executando == false) {
                $('#msgEmpresa').fadeIn(200);
                vm.executando = true;
                $timeout(function() {
                    $('#msgEmpresa').fadeOut(200);
                    vm.executando = false;
                }, 2000);
            }
        };

        vm.limparCargo = function() {
            vm.cliente.cargo = {
                'car_id': '',
                'car_nome': ''
            };
            if (vm.executando == false) {
                $('#msgCargo').fadeIn(200);
                vm.executando = true;
                $timeout(function() {
                    $('#msgCargo').fadeOut(200);
                    vm.executando = false;
                }, 2000);
            }
        };

        vm.ativarTab = function(id) {
            $('' + id + '').addClass('item_submenu_click');
            $timeout(function() {
                Cookies.remove('pessoa_slug');
                Cookies.remove('empresa_slug');
                Cookies.remove('pessoa_slug_update');
                Cookies.remove('empresa_slug_update');
            });
        };

        vm.alterarAtendimentoTela = function(atd, descricao) {
            //$('.ativar_textarea').fadeOut(100);
            $('.ativar_textarea').hide();
            vm.atdID = atd;
            vm.atdDescricao = descricao;
            //$('.ativar_textarea2').fadeIn(500);
            $('.ativar_textarea2').show();
            /*tinyMCE.execCommand('mceRemoveEditor', true, 'alterar_atendimento');
            $timeout(function() {
                $('#textareaModal').html('<textarea name="alterar_atendimento" id="alterar_atendimento"></textarea>');
                tinymce.init({
                    selector: '#alterar_atendimento',
                    statusbar: false,
                    menubar: false,
                    resize: true,
                    toolbar1: 'undo redo | fontselect fontsizeselect | bold italic underline | strikethrough inline | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent',
                    auto_focus: 'alterar_atendimento',
                    entity_encoding: 'raw',
                });
            });
            $timeout(function() {
                tinyMCE.activeEditor.setContent(descricao);
                $timeout(function() {
                    tinyMCE.get('alterar_atendimento').focus();
                }, 500);
            }, 500);*/
            $('#alterar_atendimento').trumbowyg('destroy');
            $('#textareaModal').html('<textarea name="alterar_atendimento" id="alterar_atendimento"></textarea>');
            $('#alterar_atendimento').trumbowyg({
                svgPath: '/app/plugins/trumbowyg/dist/ui/icons.svg',
                btns: [
                    ['undo', 'redo'],
                    ['formatting'],
                    ['strong', 'em', 'underline'],
                    ['foreColor', 'backColor'],
                    ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                    ['unorderedList', 'orderedList'],
                    ['horizontalRule'],
                    ['removeformat'],
                ],
                lang: 'pt_br',
            });
            $('#alterar_atendimento').trumbowyg('html', descricao);
            $timeout(function() {
                $('.trumbowyg-editor').focus();
            }, 500);
        };

        vm.cancelarAtleracaoAtendimentoTela = function() {
            //tinyMCE.activeEditor.setContent('');
            $('#alterar_atendimento').trumbowyg('empty');
            $('#novo_atendimento').trumbowyg('empty');
            //$('.ativar_textarea2').fadeOut(100);
            $('.ativar_textarea2').hide();
        };

        vm.criarDados = function() {
            //criando cookies de validação para um cadastro
            if ($location.url().substring(0, 21) == '/pessoas/atendimento/') {
                $timeout(function() {
                    Cookies.set('pessoa_slug', '' + vm.slugCliente + '');
                    Cookies.remove('empresa_slug');
                    Cookies.remove('empresa_slug_update');
                    Cookies.remove('pessoa_slug_update');
                });
            }
        };

        vm.criarDadosVincular = function() {
            //criando cookies de validação para um cadastro
            if ($location.url().substring(0, 21) == '/pessoas/cadastro/') {
                $timeout(function() {
                    Cookies.set('empresa_slug', '' + vm.empresa.slug + '');
                    Cookies.remove('empresa_slug_update');
                });
            }
        };

        vm.mostrarFiltros = function() {
            $('.box_filtros_mobile').slideToggle('slow');
        };

        vm.tamanhoMenuMobile = function(atd) {
            $('.box_menu_interno_mobile').css('width', screen.width - 30 + 'px');
            if (atd) {
                $timeout(function() {
                    $('.box_menu_interno_mobile').scrollLeft(300);
                }, 200);
            }
        };

        vm.migrouEmpresa = false;
        vm.migrouEmailsAdicionais = false;
        vm.migrouEndereco = false;
        vm.migrouTag = false;
        vm.migrouAtendimento = false;
        vm.migrouCompromisso = false;
        vm.migrouSocial = false;
        vm.migrouProposta = false;

        vm.informarErro = function(erro) {
            if (vm.migrouEmpresa) {
                var iconEmpresa = '<i class=\'fa fa-check-circle icon_success\' aria-hidden=\'true\'></i>';
            } else {
                var iconEmpresa = '<i class=\'fa fa-times-circle icon_erro\' aria-hidden=\'true\'></i>';
            }
            if (vm.migrouEndereco) {
                var iconEndereco = '<i class=\'fa fa-check-circle icon_success\' aria-hidden=\'true\'></i>';
            } else {
                var iconEndereco = '<i class=\'fa fa-times-circle icon_erro\' aria-hidden=\'true\'></i>';
            }
            if (vm.migrouEmailsAdicionais) {
                var iconEmailsAdicionais = '<i class=\'fa fa-check-circle icon_success\' aria-hidden=\'true\'></i>';
            } else {
                var iconEmailsAdicionais = '<i class=\'fa fa-times-circle icon_erro\' aria-hidden=\'true\'></i>';
            }
            if (vm.migrouTag) {
                var iconTag = '<i class=\'fa fa-check-circle icon_success\' aria-hidden=\'true\'></i>';
            } else {
                var iconTag = '<i class=\'fa fa-times-circle icon_erro\' aria-hidden=\'true\'></i>';
            }
            if (vm.migrouAtendimento) {
                var iconAtendimento = '<i class=\'fa fa-check-circle icon_success\' aria-hidden=\'true\'></i>';
            } else {
                var iconAtendimento = '<i class=\'fa fa-times-circle icon_erro\' aria-hidden=\'true\'></i>';
            }
            if (vm.migrouCompromisso) {
                var iconCompromisso = '<i class=\'fa fa-check-circle icon_success\' aria-hidden=\'true\'></i>';
            } else {
                var iconCompromisso = '<i class=\'fa fa-times-circle icon_erro\' aria-hidden=\'true\'></i>';
            }
            if (vm.migrouSocial) {
                var iconSocial = '<i class=\'fa fa-check-circle icon_success\' aria-hidden=\'true\'></i>';
            } else {
                var iconSocial = '<i class=\'fa fa-times-circle icon_erro\' aria-hidden=\'true\'></i>';
            }
            if (vm.migrouProposta) {
                var iconProposta = '<i class=\'fa fa-check-circle icon_success\' aria-hidden=\'true\'></i>';
            } else {
                var iconProposta = '<i class=\'fa fa-times-circle icon_erro\' aria-hidden=\'true\'></i>';
            }
            $('.box_geral_migrando_dados_PE').hide();
            swal({
                title: 'Erro!',
                text: 'Ocorreu um erro na migração dos dados, possível falha na conexão com a internet, certifique-se que há conexão e refaça o processo:<br><br><div style=\'text-align:center;\'><table class=\'table_migracao\'>' +
                    '<tr><td class=\'coluna\'>Cadastrar nova empresa</td><td>' +
                    iconEmpresa +
                    '</td></tr>' +
                    '<tr><td class=\'coluna\'>Transferência de e-mails adicionais</td><td>' +
                    iconEmailsAdicionais +
                    '</td></tr>' +
                    '<tr><td class=\'coluna\'>Transferência de endereço</td><td>' +
                    iconEndereco +
                    '</td></tr>' +
                    '<tr><td class=\'coluna\'>Transferência de tags</td><td>' +
                    iconTag +
                    '</td></tr>' +
                    '<tr><td class=\'coluna\'>Transferência de atendimentos</td><td>' +
                    iconAtendimento +
                    '</td></tr>' +
                    '<tr><td class=\'coluna\'>Transferência de compromissos</td><td>' +
                    iconCompromisso +
                    '</td></tr>' +
                    '<tr><td class=\'coluna\'>Transferência de redes sociais</td><td>' +
                    iconSocial +
                    '</td></tr>' +
                    '<tr><td class=\'coluna\'>Transferência de negociações</td><td>' +
                    iconProposta +
                    '</td></tr>' +
                    '</table></div>' +
                    '<br><br>' +
                    'Os registros com <i class=\'fa fa-check-circle icon_success\' aria-hidden=\'true\'></i> foram criados, remova-os para evitar informações duplicadas.',
                type: 'error',
                showConfirmButton: true,
                html: true
            }, function() {
                $timeout(function() {

                });
            });
        };


        vm.migrarRegistro = function(slug) {
            swal({
                    title: 'Deseja transformar este lead em empresa?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    closeOnConfirm: false
                },
                function() {
                    $window.onkeydown = null;
                    $window.onfocus = null;
                    var erro = 0;
                    var cpf_cnpj = vm.cliente.cpf;
                    var id_empresa_pessoa = vm.cliente.empresa.emp_id;

                    if (cpf_cnpj != '') { // CPF PREENCHIDO
                        erro = 1;
                        swal({
                            title: 'Operação não realizada!',
                            text: 'Esta pessoa possui um CPF vinculado, por favor remova o CPF caso queira continuar.',
                            type: 'error',
                            showConfirmButton: true,
                            html: true
                        }, function() {
                            $timeout(function() {
                                $('[name=\'cpf_cnpj\']').focus();
                            });
                        });
                    }

                    if (id_empresa_pessoa != '') { // EMPRESA SELECIONADA
                        erro = 1;
                        swal({
                            title: 'Operação não realizada!',
                            text: 'Esta pessoa possui uma empresa vinculada a ela.',
                            type: 'error',
                            showConfirmButton: true,
                            html: true
                        }, function() {
                            $timeout(function() {
                                $('html, body').animate({
                                    scrollTop: 0
                                }, 500);
                            });
                        });
                    }

                    if (erro == 0) { // CPF E EMPRESA NÃO SELECIONADOS - CONTINUAR
                        swal.close();
                        $('.box_geral_migrando_dados_PE').fadeIn('slow');
                        $('.titulo_migrando').html('Cadastrando nova empresa...');
                        var dados = {
                            'slug': slug,
                            'saasid': vm.saasid
                        };
                        PessoasService.cadastrarEmpresaMigrar(dados).then(function(response) {
                            vm.migrouEmpresa = true;
                            var id_empresa = response.data;
                            $('.titulo_migrando').html('Transferindo e-mails adicionais...');
                            var dados = {
                                'slug': slug,
                                'id': id_empresa
                            };
                            PessoasService.cadastrarEmailsAdicionaisMigrar(dados).then(function(response) {
                                vm.migrouEmailsAdicionais = true;
                                $('.titulo_migrando').html('Transferindo endereço...');
                                var dados = {
                                    'slug': slug,
                                    'id': id_empresa
                                };
                                PessoasService.cadastrarEnderecoMigrar(dados).then(function(response) {
                                    vm.migrouEndereco = true;
                                    $('.titulo_migrando').html('Transferindo tags...');
                                    var dados = {
                                        'slug': slug,
                                        'id': id_empresa
                                    };
                                    PessoasService.cadastrarTagsMigrar(dados).then(function(response) {
                                        vm.migrouTag = true;
                                        $('.titulo_migrando').html('Transferindo atendimentos...');
                                        var dados = {
                                            'slug': slug,
                                            'id': id_empresa
                                        };
                                        PessoasService.cadastrarAtendimentosMigrar(dados).then(function(response) {
                                            vm.migrouAtendimento = true;
                                            $('.titulo_migrando').html('Transferindo compromissos...');
                                            var dados = {
                                                'slug': slug,
                                                'id': id_empresa
                                            };
                                            PessoasService.cadastrarCompromissosMigrar(dados).then(function(response) {
                                                vm.migrouCompromisso = true;
                                                $('.titulo_migrando').html('Transferindo redes sociais...');
                                                var dados = {
                                                    'slug': slug,
                                                    'id': id_empresa
                                                };
                                                PessoasService.cadastrarSocialMigrar(dados).then(function(response) {
                                                    vm.migrouSocial = true;
                                                    $('.titulo_migrando').html('Transferindo negociações...');
                                                    var dados = {
                                                        'slug': slug,
                                                        'id': id_empresa
                                                    };
                                                    PessoasService.cadastrarNegociacoesMigrar(dados).then(function(response) {
                                                        vm.migrouProposta = true;
                                                        $('.titulo_migrando').html('Agora falta pouco...');
                                                        var dados = {
                                                            'slug': slug,
                                                            'id': id_empresa
                                                        };
                                                        PessoasService.apagarDadosMigrar(dados).then(function(response) {
                                                            var slugEmpresa = response.data;
                                                            $('.box_geral_migrando_dados_PE').hide();
                                                            swal({
                                                                title: 'Transferência concluída!',
                                                                text: 'Os dados foram transferidos para empresa',
                                                                type: 'success',
                                                                showConfirmButton: true,
                                                                html: true
                                                            }, function() {
                                                                $timeout(function() {
                                                                    $location.url('/empresas/info/' + slugEmpresa);
                                                                });
                                                            });
                                                        }, function(error) {
                                                            $log.error(error);
                                                            $timeout(function() {
                                                                vm.informarErro(error.data);
                                                            }, 100);
                                                        });
                                                    }, function(error) {
                                                        $log.error(error);
                                                        $timeout(function() {
                                                            vm.informarErro(error.data);
                                                        }, 100);
                                                    });
                                                }, function(error) {
                                                    $log.error(error);
                                                    $timeout(function() {
                                                        vm.informarErro(error.data);
                                                    }, 100);
                                                });
                                            }, function(error) {
                                                $log.error(error);
                                                $timeout(function() {
                                                    vm.informarErro(error.data);
                                                }, 100);
                                            });
                                        }, function(error) {
                                            $log.error(error);
                                            $timeout(function() {
                                                vm.informarErro(error.data);
                                            }, 100);
                                        });
                                    }, function(error) {
                                        $log.error(error);
                                        $timeout(function() {
                                            vm.informarErro(error.data);
                                        }, 100);
                                    });
                                }, function(error) {
                                    $log.error(error);
                                    $timeout(function() {
                                        vm.informarErro(error.data);
                                    }, 100);
                                });
                            }, function(error) {
                                $log.error(error);
                                $timeout(function() {
                                    vm.informarErro(error.data);
                                }, 100);
                            });
                        }, function(error) {
                            $log.error(error);
                            $timeout(function() {
                                vm.informarErro(error.data);
                            }, 100);
                        });
                    }
                });
        };
        vm.cliente.ativo = 1;

        vm.aplicarScroll = function() {
            $('.checkBoxContainer').niceScroll();
        };



        //TRADUÇÃO DO INPUT SELECT DE USUÁRIOS
        vm.ptbr = {
            selectAll: 'Todos',
            selectNone: 'Limpar',
            search: 'Pesquisar...',
            nothingSelected: 'Selecionar responsável'
        };

        /*vm.campoOrder = 'cli_nome';
        var init6 = 0;*/
        vm.ordenarRegistros = function(qual) {

            if (qual == 'cad_rec') {
                qual = null;
            }

            vm.searchUrl('ordenar', qual);
            vm.buscaClientes();
        };




        /*EXPORTAR CSV --- INÍCIO*/
        vm.abrirModalExportar = function() {
            vm.exportar = {
                'endereco': true,
                'redes_soc': false,
                'email_soc': false,
                'tags': false,
                'email_adicionais': ''
            };
            $('#modalExportar').modal({
                //backdrop: 'static',
                //keyboard: false,
                show: true
            }).on('shown.bs.modal', function(e) {
                //$("#campo_nome_rapido").focus();
            });
        };

        vm.selected = [];
        vm.tiposEmailSelecionados = '';

        vm.toggle = function(item, list) {
            var idx = list.indexOf(item);
            if (idx > -1) {
                list.splice(idx, 1);
            } else {
                list.push(item);
            }
            vm.tiposEmailSelecionados = list.join();
        };


        vm.exists = function(item, list) {
            return list.indexOf(item) > -1;
        };

        vm.exportarCSV = function() {
            swal({
                    title: 'Deseja realmente exportar os dados selecionados?',
                    text: 'Esta ação pode demorar um pouco.',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    closeOnConfirm: false
                },
                function() {
                    $window.onkeydown = null;
                    $window.onfocus = null;
                    swal.close();
                    $('.box_geral_migrando_dados_PE').fadeIn('slow');
                    $('.titulo_migrando').html('Exportando dados...');
                    vm.exportar.email_adicionais = vm.tiposEmailSelecionados;

                    $timeout(function() {
                        var enviar = '';
                        if (vm.exportar.endereco) {
                            enviar += '&endereco=1';
                        }
                        if (vm.exportar.redes_soc) {
                            enviar += '&redes_soc=1';
                        }
                        if (vm.exportar.email_soc) {
                            enviar += '&email_soc=1';
                        }
                        if (vm.exportar.tags) {
                            enviar += '&tags=1';
                        }
                        if (vm.exportar.email_adicionais != '') {
                            enviar += '&emails=' + vm.exportar.email_adicionais;
                        }
                        // PERMISSOES
                        var rolIdDecry = API.funilRC.decrypt($window.localStorage.getItem('isModPessoas'));
                        var rolStorageObj = JSON.parse(rolIdDecry);
                        var rolID = rolStorageObj.visualiza;
                        var daUserStorage = API.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
                        var daUser = JSON.parse(daUserStorage);
                        var gruId = daUser[0].fk_gru_id;
                        var userId = daUser[0].user_id;
                        enviar += '&rolid=' + rolID + '&userID=' + userId + '&gruID=' + gruId;
                        // PERMISSOES

                        $('.box_geral_migrando_dados_PE').hide();
                        $window.location = API.url + 'pessoas/service.php?t=exportar_CSV&saasid=' + vm.saasid + '&qual=p' + enviar, '_blank';
                        $('#modalExportar').modal('hide');
                    }, 500);
                });
        };
        /*EXPORTAR CSV --- FIM*/








        /*CADASTRAR MAIS EMAILS --- INÍCIO*/
        vm.tabSelecionada = 0;
        vm.emails = {
            'tipo': '0',
            'email': ''
        };
        vm.limparEmails = function() {
            vm.emails = {
                'tipo': '0',
                'email': ''
            };
        };

        vm.buscaTiposEmail = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid
                };
                tiposEmailService.findAllCadastro(dados).then(function(response) {
                    if (response.data != 0) {
                        $timeout(function() {
                            vm.tiposEmailLista = response.data;
                        });
                    } else {
                        $timeout(function() {
                            vm.tiposEmailLista = '';
                        });
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        };
        vm.emailsAdd = [];
        vm.salvandoEmail = false;
        vm.error_email = false;
        vm.error_email_add = false;
        vm.error_tipo = false;

        vm.salvarEmails = function(tipo, email, id_email) {
            vm.salvandoEmail = true;
            $('.spinner_email').fadeIn();
            var id = tipo.emt_id;
            var nome = tipo.emt_nome;

            if (vm.IsEmail(email)) {
                vm.error_email = false;
            } else {
                vm.error_email = true;
                $('.email_add').focus();
                $('.spinner_email').hide();
                vm.salvandoEmail = false;
                return null;
            }
            var dados = {
                'id_cliente': vm.id_cliente,
                'id_tipo': id,
                'id_email': id_email,
                'email': email,
                'saasid': vm.saasid,
                'slug': $routeParams.slugCliente
            };
            if (vm.editandoCadastro) { // EDITANDO CLIENTE
                if (vm.editando) { // EDITANDO
                    PessoasService.validaExisteEmailEdit(dados).then(function(response) {
                        if (response.data != 0) { // existe cliente com o email
                            if (response.data[0].tipo == 'p') {
                                swal({
                                    title: 'E-mail já existente!',
                                    text: 'Já existe um cliente cadastrado com o email informado:<br><br><a href=\'#/pessoas/emailsAdd/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                        response.data[0].nome + '</a>',
                                    type: 'warning',
                                    showConfirmButton: true,
                                    html: true
                                }, function() {
                                    $timeout(function() {
                                        $('.email_add').focus();
                                        $('.spinner_email').hide();
                                        vm.salvandoEmail = false;
                                    });
                                });
                            } else if (response.data[0].tipo == 'e') {
                                swal({
                                    title: 'E-mail já existente!',
                                    text: 'Já existe uma empresa cadastrada com o email informado:<br><br><a href=\'#/empresas/emailsAdd/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                        response.data[0].nome + '</a>',
                                    type: 'warning',
                                    showConfirmButton: true,
                                    html: true
                                }, function() {
                                    $timeout(function() {
                                        $('.email_add').focus();
                                        $('.spinner_email').hide();
                                        vm.salvandoEmail = false;
                                    });
                                });
                            }
                            vm.salvando = false;
                        } else { // não existe cliente com o email
                            var existe = 0;
                            var existe2 = 0;
                            angular.forEach(vm.emailsAdd, function(value, key) {
                                if (value.tipo == nome && value.id_email != id_email) {
                                    existe = 1;
                                }
                                if (value.email == email && value.id_email != id_email) {
                                    existe2 = 1;
                                }
                            });
                            if (existe == 0 && existe2 == 0) {
                                vm.error_tipo = false;
                                PessoasService.editarEmailAdicionalCliente(dados).then(function(response) {
                                    $timeout(function() {
                                        $timeout(function() {
                                            $('.spinner_email').hide();
                                            $('.check_email').fadeIn();
                                            vm.buscarEmailsAdicionaisCliente(vm.id_cliente);
                                            vm.cancelarEdit();
                                            $timeout(function() {
                                                $('.check_email').hide();
                                                vm.salvandoEmail = false;
                                            }, 1000);
                                        }, 500);
                                    });
                                }, function(error) {
                                    $log.error(error);
                                });
                            } else {
                                $('.spinner_email').hide();
                                vm.salvandoEmail = false;
                                if (existe == 1) {
                                    vm.error_tipo = true;
                                    $timeout(function() {
                                        vm.error_tipo = false;
                                    }, 2000);
                                }
                                if (existe2 == 1) {
                                    vm.error_email_add = true;
                                    $timeout(function() {
                                        vm.error_email_add = false;
                                    }, 2000);
                                }
                            }
                        }
                    }, function(error) {
                        $log.error(error);
                    });
                } else { // SALVANDO
                    PessoasService.validaExisteEmail(dados).then(function(response) {
                        if (response.data != 0) { // existe cliente com o email
                            if (response.data[0].tipo == 'p') {
                                swal({
                                    title: 'E-mail já existente!',
                                    text: 'Já existe um cliente cadastrado com o email informado:<br><br><a href=\'#/pessoas/emailsAdd/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                        response.data[0].nome + '</a>',
                                    type: 'warning',
                                    showConfirmButton: true,
                                    html: true
                                }, function() {
                                    $timeout(function() {
                                        $('.email_add').focus();
                                        $('.spinner_email').hide();
                                        vm.salvandoEmail = false;
                                    });
                                });
                            } else if (response.data[0].tipo == 'e') {
                                swal({
                                    title: 'E-mail já existente!',
                                    text: 'Já existe uma empresa cadastrada com o email informado:<br><br><a href=\'#/empresas/emailsAdd/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                        response.data[0].nome + '</a>',
                                    type: 'warning',
                                    showConfirmButton: true,
                                    html: true
                                }, function() {
                                    $timeout(function() {
                                        $('.email_add').focus();
                                        $('.spinner_email').hide();
                                        vm.salvandoEmail = false;
                                    });
                                });
                            }
                            vm.salvando = false;
                        } else { // não existe cliente com o email


                            var existe = 0;
                            var existe2 = 0;
                            angular.forEach(vm.emailsAdd, function(value, key) {
                                if (value.tipo == nome) {
                                    existe = 1;
                                }
                                if (value.email == email) {
                                    existe2 = 1;
                                }
                            });
                            if (existe == 0 && existe2 == 0) {
                                vm.error_tipo = false;
                                PessoasService.cadastrarEmailAdicionalCliente(dados).then(function(response) {
                                    $timeout(function() {
                                        $('.spinner_email').hide();
                                        $('.check_email').fadeIn();
                                        vm.buscarEmailsAdicionaisCliente(vm.id_cliente);
                                        vm.cancelarEdit();
                                        $timeout(function() {
                                            $('.check_email').hide();
                                            vm.salvandoEmail = false;
                                        }, 1000);
                                    }, 500);
                                }, function(error) {
                                    $log.error(error);
                                });
                            } else {
                                $('.spinner_email').hide();
                                vm.salvandoEmail = false;
                                if (existe == 1) {
                                    vm.error_tipo = true;
                                    $timeout(function() {
                                        vm.error_tipo = false;
                                    }, 2000);
                                }
                                if (existe2 == 1) {
                                    vm.error_email_add = true;
                                    $timeout(function() {
                                        vm.error_email_add = false;
                                    }, 2000);
                                }
                            }
                        }
                    }, function(error) {
                        $log.error(error);
                    });
                }
            } else { // CADASTRANDO CLIENTE
                if (vm.editando) { // EDITANDO
                    PessoasService.validaExisteEmailEdit(dados).then(function(response) {
                        if (response.data != 0) { // existe cliente com o email
                            if (response.data[0].tipo == 'p') {
                                swal({
                                    title: 'E-mail já existente!',
                                    text: 'Já existe um cliente cadastrado com o email informado:<br><br><a href=\'#/pessoas/emailsAdd/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                        response.data[0].nome + '</a>',
                                    type: 'warning',
                                    showConfirmButton: true,
                                    html: true
                                }, function() {
                                    $timeout(function() {
                                        $('.email_add').focus();
                                    });
                                });
                            } else if (response.data[0].tipo == 'e') {
                                swal({
                                    title: 'E-mail já existente!',
                                    text: 'Já existe uma empresa cadastrada com o email informado:<br><br><a href=\'#/empresas/emailsAdd/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                        response.data[0].nome + '</a>',
                                    type: 'warning',
                                    showConfirmButton: true,
                                    html: true
                                }, function() {
                                    $timeout(function() {
                                        $('.email_add').focus();
                                    });
                                });
                            }
                            vm.salvando = false;
                        } else { // não existe cliente com o email
                            var existe = 0;
                            var existe2 = 0;
                            angular.forEach(vm.emailsAdd, function(value, key) {
                                if (value.tipo == nome && key != vm.indexEmail) {
                                    existe = 1;
                                }
                                if (value.email == email && key != vm.indexEmail) {
                                    existe2 = 1;
                                }
                            });
                            if (existe == 0 && existe2 == 0) {
                                vm.error_tipo = false;
                                vm.emailsAdd[vm.indexEmail] = {
                                    'id_tipo': id,
                                    'tipo': nome,
                                    'email': email
                                };
                                vm.limparEmails();
                            } else {
                                if (existe == 1) {
                                    vm.error_tipo = true;
                                    $timeout(function() {
                                        vm.error_tipo = false;
                                    }, 2000);
                                }
                                if (existe2 == 1) {
                                    vm.error_email_add = true;
                                    $timeout(function() {
                                        vm.error_email_add = false;
                                    }, 2000);
                                }
                            }
                        }
                    }, function(error) {
                        $log.error(error);
                    });
                } else { // SALVANDO
                    PessoasService.validaExisteEmail(dados).then(function(response) {
                        if (response.data != 0) { // existe cliente com o email
                            if (response.data[0].tipo == 'p') {
                                swal({
                                    title: 'E-mail já existente!',
                                    text: 'Já existe um cliente cadastrado com o email informado:<br><br><a href=\'#/pessoas/emailsAdd/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                        response.data[0].nome + '</a>',
                                    type: 'warning',
                                    showConfirmButton: true,
                                    html: true
                                }, function() {
                                    $timeout(function() {
                                        $('.email_add').focus();
                                    });
                                });
                            } else if (response.data[0].tipo == 'e') {
                                swal({
                                    title: 'E-mail já existente!',
                                    text: 'Já existe uma empresa cadastrada com o email informado:<br><br><a href=\'#/empresas/emailsAdd/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                        response.data[0].nome + '</a>',
                                    type: 'warning',
                                    showConfirmButton: true,
                                    html: true
                                }, function() {
                                    $timeout(function() {
                                        $('.email_add').focus();
                                    });
                                });
                            }
                            vm.salvando = false;
                        } else { // não existe cliente com o email
                            var existe = 0;
                            var existe2 = 0;
                            angular.forEach(vm.emailsAdd, function(value, key) {
                                if (value.tipo == nome) {
                                    existe = 1;
                                }
                                if (value.email == email) {
                                    existe2 = 1;
                                }
                            });
                            if (existe == 0 && existe2 == 0) {
                                vm.error_tipo = false;
                                vm.emailsAdd.push({
                                    'id_tipo': id,
                                    'tipo': nome,
                                    'email': email
                                });
                                vm.limparEmails();
                            } else {
                                if (existe == 1) {
                                    vm.error_tipo = true;
                                    $timeout(function() {
                                        vm.error_tipo = false;
                                    }, 2000);
                                }
                                if (existe2 == 1) {
                                    vm.error_email_add = true;
                                    $timeout(function() {
                                        vm.error_email_add = false;
                                    }, 2000);
                                }
                            }
                        }
                    }, function(error) {
                        $log.error(error);
                    });
                }
            }
        };

        vm.removerEmail = function(index) {
            if (vm.editandoCadastro) { // EDITANDO CLIENTE
                swal({
                        title: 'Deseja remover este e-mail?',
                        text: 'Esta ação é irreversível',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                        closeOnConfirm: false
                    },
                    function() {
                        $window.onkeydown = null;
                        $window.onfocus = null;
                        var dados = {
                            'id_cliente': vm.id_cliente,
                            'id_tipo': vm.emailsAdd[index].id_tipo,
                            'email': vm.emailsAdd[index].email,
                            'saasid': vm.saasid
                        };
                        PessoasService.removerEmailAdicionalCliente(dados).then(function(response) {
                            swal({
                                title: 'Removido com sucesso!',
                                type: 'success',
                                text: 'E-mail removido com sucesso!',
                                showConfirmButton: false,
                                timer: 1000
                            });
                            $timeout(function() {
                                vm.buscarEmailsAdicionaisCliente(vm.id_cliente);
                                vm.limparEmails();
                            });
                        }, function(error) {
                            $log.error(error);
                        });
                    });
            } else { // CADASTRANDO CLIENTE
                vm.emailsAdd.splice(index, 1);
            }
        };

        vm.indexEmail = '';
        vm.editando = false;
        vm.editarEmail = function(index, id_tipo, email, id_email) {
            vm.editando = true;
            angular.forEach(vm.tiposEmailLista, function(value, key) {
                if (value.emt_id == id_tipo) {
                    vm.emails.tipo = value;
                }
            });
            vm.emails.email = email;
            vm.emails.id_email = id_email;
            vm.indexEmail = index;
        };

        vm.cancelarEdit = function() {
            vm.limparEmails();
            vm.editando = false;
            vm.indexEmail = '';
        };

        vm.acessarTabEmails = function() {
            vm.tabSelecionada = 1;
        };

        vm.editarEmailPrincipal = function() {
            vm.tabSelecionada = 0;
            $timeout(function() {
                $('#email').focus();
            }, 500);
        };


        vm.growableOptions3 = {
            displayText: 'Tipo',
            emptyListText: 'Nenhum tipo cadastrado',
            onAdd: function(texto) {
                var deferred = $q.defer();
                // LIMITAÇÃO DE LEADS --- INÍCIO
                vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
                vm.items.then(function(items) {
                    vm.saasid = items;
                    vm.leads = verificaLeads(vm.saasid); // carregar saas id
                    var dados = {
                        'nome': texto,
                        'saasid': vm.saasid
                    };
                    $('.check_cadastro').hide(); // remove o check
                    $('.spinner_cadastro').toggleClass('hide'); // aparecer loading
                    tiposEmailService.cadastrarTipoEmailRapido(dados).then(function(response) {
                        vm.buscaTiposEmail();
                        $('.spinner_cadastro').toggleClass('hide'); // remover loading
                        $('.check_cadastro').fadeIn('slow'); // mostra check sucesso
                        $('.limpar').val(''); // limpar input após cadastro
                        $timeout(function() {
                            $('.check_cadastro').fadeOut('slow'); // após 2 segundos remove o check
                        }, 2000);
                        var tipo = {
                            'emt_id': response.data[0].emt_id,
                            'emt_nome': response.data[0].emt_nome
                        };
                        deferred.resolve(tipo);
                    }, function(error) {
                        $log.error(error);
                    });
                });
                return deferred.promise;
            }
        };

        if ($location.url().match(/emailsAdd/)) {
            vm.editarCadastroCliente(0);
            vm.tabSelecionada = 1;
        }

        /*CADASTRAR MAIS EMAILS --- FIM*/

        vm.mobile = false;
        $(function() {
            if (screen.width < 767) {
                vm.mobile = true;
            } else {
                vm.mobile = false;
            }
        });

        /*FILTRO URL --- INICIO*/

        vm.searchUrl = function(qual, value) {
            $location.search(qual, value);
        }

        var init = 0;
        vm.filtrarCidade = function(qual) {
            /*if (!qual) {
                qual = null;
                delete vm.cidade_filtro;
            }
            var search = $location.search();
            if (search.cidade && init == 0) { // EXECUTAR SOMENTE AO CARREGAR A PAGINA
                vm.cidade_filtro = search.cidade;
                qual = search.cidade;
            }
            init = 1;
            vm.searchUrl('cidade', qual);
            */
            if (!qual.length) {
                qual = null;
            }

            vm.searchUrl('cidade', qual);
            vm.buscaClientes();
        }
        //vm.filtrarCidade();

        var init2 = 0;
        vm.filtrarEstado = function(qual) {

            if (!qual.length) {
                qual = null;
                vm.addTooltipCidade();
            } else {
                $('.box_campo_cidade_filtro > select').tooltip('destroy');
            }

            vm.searchUrl('estado', qual);
            vm.searchUrl('cidade', null);
            vm.cidade_filtro = '';
            vm.buscaCidades(qual);
            vm.buscaClientes();
        }
        //vm.filtrarEstado();

        /*var init3 = 0;
        vm.categ_filtro = '';
        vm.categoria_filtro = [];*/
        vm.filtrarCategoria = function(qual) {
            /*var search = $location.search();
            if (id_categ) {
                var arr = $filter('filter')(vm.categorias, { 'categ_id': id_categ });
                var qual = arr[0].categ_id;
                vm.categoria_filtro.categ_id = arr[0].categ_id;
                vm.categoria_filtro.categ_nome = arr[0].categ_nome;
            } else {
                var qual = null;
                vm.categoria_filtro = [];
            }
      
            if (search.categoria && init3 == 0) { // EXECUTAR SOMENTE AO CARREGAR A PAGINA
                vm.categ_filtro = search.categoria;
                qual = search.categoria;
                var arr = $filter('filter')(vm.categorias, { 'categ_id': search.categoria });
                vm.categoria_filtro.categ_id = arr[0].categ_id;
                vm.categoria_filtro.categ_nome = arr[0].categ_nome;
            }
            init3 = 1;
            vm.searchUrl('categoria', qual);*/

            if (!qual.length) {
                qual = null;
            }

            vm.searchUrl('categoria', qual);
            vm.buscaClientes();
        }

        /*var init4 = 0;
        vm.car_filtro = '';
        vm.cargo_filtro = [];*/
        vm.filtrarCargo = function(qual) {
            /*var search = $location.search();
            if (id_cargo) {
                var arr = $filter('filter')(vm.cargosRepeat, { 'car_id': id_cargo });
                var qual = arr[0].car_id;
                vm.cargo_filtro.car_id = arr[0].car_id;
                vm.cargo_filtro.car_nome = arr[0].car_nome;
            } else {
                var qual = null;
                vm.cargo_filtro = [];
            }
      
            if (search.cargo && init4 == 0) { // EXECUTAR SOMENTE AO CARREGAR A PAGINA
                vm.car_filtro = search.cargo;
                qual = search.cargo;
                var arr = $filter('filter')(vm.cargosRepeat, { 'car_id': search.cargo });
                vm.cargo_filtro.car_id = arr[0].car_id;
                vm.cargo_filtro.car_nome = arr[0].car_nome;
            }
            init4 = 1;
            vm.searchUrl('cargo', qual);*/

            if (!qual.length) {
                qual = null;
            }

            vm.searchUrl('cargo', qual);
            vm.buscaClientes();
        }

        var init5 = 0;
        vm.filtrarResponsavel = function(qual) {
            /*if (!qual) {
                qual = null;
            }
            var search = $location.search();
            if (search.responsavel && init5 == 0) { // EXECUTAR SOMENTE AO CARREGAR A PAGINA
                vm.resp_filtro = search.responsavel;
                qual = search.responsavel;
            }
            init5 = 1;
            vm.searchUrl('responsavel', qual);*/

            if (!qual.length) {
                qual = null;
            }

            vm.searchUrl('responsavel', qual);
            vm.buscaClientes();
        }
        //vm.filtrarResponsavel();

        /*FILTRO ORIGEM CONTATO --- INICIO*/
        vm.buscaOrigensContato = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'user_hash': Cookies.get('crm_funil_hash')
                };
                origemContatoService.findAll(dados).then(function(response) {
                    if (response.data != 0) {
                        $timeout(function() {
                            vm.origemContatoLista = response.data;
                            //vm.filtrarOrigemContato();
                        });
                    } else {
                        $timeout(function() {
                            vm.origemContatoLista = '';
                            //vm.filtrarOrigemContato();
                        });
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        };

        /*var init7 = 0;
        vm.orc_filtro = '';
        vm.origem_filtro = [];*/
        vm.filtrarOrigemContato = function(qual) {
            /*var search = $location.search();
            if (id_origem) {
                var arr = $filter('filter')(vm.origemContatoLista, { 'orc_id': id_origem });
                var qual = arr[0].orc_id;
                vm.origem_filtro.orc_id = arr[0].orc_id;
                vm.origem_filtro.orc_nome = arr[0].orc_nome;
            } else {
                var qual = null;
                vm.origem_filtro = [];
            }
      
            if (search.origem && init7 == 0) { // EXECUTAR SOMENTE AO CARREGAR A PAGINA
                vm.orc_filtro = search.origem;
                qual = search.origem;
                var arr = $filter('filter')(vm.origemContatoLista, { 'orc_id': search.origem });
                vm.origem_filtro.orc_id = arr[0].orc_id;
                vm.origem_filtro.orc_nome = arr[0].orc_nome;
            }
            init7 = 1;
            vm.searchUrl('origem', qual);*/

            if (!qual.length) {
                qual = null;
            }

            vm.searchUrl('origem', qual);
            vm.buscaClientes();
        }
        /*FILTRO ORIGEM CONTATO --- INICIO*/

        function verifSearch() {
            var search = $location.search();
            //if ($location.url().match('/pessoas')) {
            if ($location.url() == '/pessoas') {
                if (!search.cidade && !search.estado && !search.categoria && !search.cargo && !search.responsavel && !search.origem && !search.ordenar && !search.busca) {
                    /*delete vm.cidade_filtro;
                    delete vm.estado_filtro;
                    vm.categoria_filtro = [];
                    vm.categ_filtro = '';
                    vm.cargo_filtro = [];
                    vm.car_filtro = '';
                    vm.orc_filtro = '';
                    vm.origem_filtro = [];
                    vm.resp_filtro = '';
                    vm.ordenacao = 'cad_rec';
                    vm.campoOrder = 'cli_nome';
                    vm.direcao = false;*/
                    vm.data_filtro = {};
                    vm.ordenacao = 'cad_rec';
                    vm.cidade_filtro = '';
                    vm.estado_filtro = '';
                    vm.categ_filtro = '';
                    vm.car_filtro = '';
                    vm.resp_filtro = '';
                    vm.orc_filtro = '';
                    vm.busca = '';
                    vm.cidades = '';
                    vm.addTooltipCidade();
                    vm.buscaClientes();
                }
            }
        }
        $scope.$on("$locationChangeSuccess", verifSearch);

        /*FILTRO URL --- FIM*/

        vm.showLogEmail = (data) => {
            $mdBottomSheet.show({
                templateUrl: 'pessoas/component/infoEmailRecebido.template.html',
                controller: 'ControllerInfoEmailRecebido',
                controllerAs: '$ctrl',
                resolve: {
                    dataParams: () => {
                        return data
                    }
                }
            }).then(function(clickedItem) {
                //$scope.alert = clickedItem['name'] + ' clicked!';
            }).catch(function(error) {
                // User clicked outside or hit escape
            });
        };



        /*
         * INICIO FILTRO DATA CRIAÇÃO
         */

        vm.setOpenCalendarFilter = function(s, e) {
            var start = s ? moment(s) : moment();
            var end = e ? moment(e) : moment();


            function cb(start, end) {
                /*console.log("start");
                console.log(start);
                console.log("end");
                console.log(end);*/

                var txtMostrar = "";
                
                //$('#openCalendarFilter').val('');

                if (angular.equals(start, end) && !start._isValid || start._isValid == undefined) {
                    txtMostrar = "últimas pessoas cadastradas";
                    vm.zerouDataCriacao = true;
                } else if ((start.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) && (end.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD'))) {
                    txtMostrar = "pessoas cadastradas hoje";
                    vm.zerouDataCriacao = false;
                } else if ((start.format('YYYY-MM-DD') == moment().subtract(1, 'days').format('YYYY-MM-DD')) && (end.format('YYYY-MM-DD') == moment().subtract(1, 'days').format('YYYY-MM-DD'))) {
                    txtMostrar = "pessoas cadastradas ontem";
                    vm.zerouDataCriacao = false;
                } else {
                    txtMostrar = "pessoas cadastradas entre " + start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY')
                    vm.zerouDataCriacao = false;
                }

                if (vm.zerouDataCriacao) {
                    vm.searchUrl('data_criacao', null);
                } else {
                    vm.searchUrl('data_criacao', start.format('YYYY-MM-DD') + "_" + end.format('YYYY-MM-DD'));
                }

                $('#openCalendarFilter span').html(txtMostrar);
            }

            $('#openCalendarFilter').daterangepicker({
                'locale': {
                    'format': 'DD/MM/YYYY',
                    'cancelLabel': 'Limpar'
                },
                startDate: start,
                endDate: end,
                ranges: {
                    //'Recentes': ['', ''],
                    'Hoje': [moment(), moment()],
                    'Ontem': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Últ. 7 dias': [moment().subtract(6, 'days'), moment()],
                    'Últ. 30 dias': [moment().subtract(29, 'days'), moment()],
                    'Este mês': [moment().startOf('month'), moment().endOf('month')],
                    'Mês passado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);

            // recebeu via url
            if (s && e) {
                cb(start, end);
            }

        }


        //verifica se tem na url filtro
        var filterINurl = $location.search().data_criacao;
        if (filterINurl) {
            var splitData = filterINurl.split('_')
            vm.setOpenCalendarFilter(splitData[0], splitData[1]);
        } else {
            vm.setOpenCalendarFilter(null, null);
        }


        // cancela filtro
        $('#openCalendarFilter').on('cancel.daterangepicker', function(ev, picker) {
            $(this).val('');
            $('#openCalendarFilter span').html('últimas pessoas cadastradas');

            // seta como dafault 'recentes'
            $(this).data('daterangepicker').setStartDate(moment());
            $(this).data('daterangepicker').setEndDate(moment());

            // limpa a url e chama o search
            vm.searchUrl('data_criacao', null);
            vm.zerouDataCriacao = true;
            // $location.search('data_criacao', null)
            vm.buscaClientes();
        });


        // aplica filtro
        $('#openCalendarFilter').on('apply.daterangepicker', function(ev, picker) {
            /*console.log(picker.startDate.format('YYYY-MM-DD'));
            console.log(picker.endDate.format('YYYY-MM-DD'));*/
            if (picker.startDate.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD') && picker.endDate.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
                $('#openCalendarFilter span').html("pessoas cadastradas hoje");
                vm.searchUrl('data_criacao', picker.startDate.format('YYYY-MM-DD') + "_" + picker.endDate.format('YYYY-MM-DD'));
                vm.data_filtro.start = picker.startDate.format('YYYY-MM-DD');
                vm.data_filtro.end = picker.endDate.format('YYYY-MM-DD')
                vm.buscaClientes(true);
            } else {
                vm.buscaClientes();
            }
        });

        vm.formatWhats = function(fone){
            return fone.replace(/[\(\)\+\.\s-]+/g,'');
        }

        // ODONTO TOP --- INÍCIO

        vm.unidades_odontotop = [];
        vm.buscaUnidadesOdontoTop = function(name) {
            PessoasService.findUnidadesOdontoTop().then(function(response) {
                // console.log("buscaUnidadesOdontoTop");
                // console.log(response.data);
                vm.unidades_odontotop = response.data;
                if (name){
                    angular.forEach(vm.unidades_odontotop, function(value, key) {
                        if (value.id == vm.cliente.id_unidade_odontotop){
                            vm.cliente.name_unidade_odontotop = value.name;
                        }
                    });
                }
                $timeout(function() {
                    $("#spinner_unidades_odontotop").hide();
                });
            }, function(error) {
                $log.error(error);
            });
        };

        vm.list_status_odontotop = [];
        vm.buscaStatusAtdOdontoTop = function(name) {
            PessoasService.findStatusAtdOdontoTop().then(function(response) {
                // console.log("buscaStatusAtdOdontoTop");
                // console.log(response.data);
                vm.list_status_odontotop = response.data;
                if (name){
                    angular.forEach(vm.list_status_odontotop, function(value, key) {
                        if (value.id == vm.cliente.status_atd_odontotop){
                            vm.cliente.name_status_atd_odontotop = value.name;
                        }
                    });
                }
                $timeout(function() {
                    $("#spinner_status_odontotop").hide();
                });
            }, function(error) {
                $log.error(error);
            });
        };

        vm.initDateRange = function(edit) {
            $('#data_entrada').daterangepicker({
                singleDatePicker: true,
                showDropdowns: true,
                startDate: edit ? vm.cliente.data_entrada : moment().format('DD/MM/YYYY HH:mm'),
                maxDate: moment().format('DD/MM/YYYY 23:59'),
                locale: {
                    format: 'DD/MM/YYYY HH:mm'
                },
                timePicker: true,
                timePicker24Hour: true,
                timePickerIncrement: 1
            });
            // if (!vm.cliente.data_agendamento_odontotop){
            //     $('#data_agendamento_odontotop').val('');
            // }
            $('#data_agendamento_odontotop').daterangepicker({
                singleDatePicker: true,
                showDropdowns: true,
                autoUpdateInput: false,
                // startDate: edit && vm.cliente.data_agendamento_odontotop ? vm.cliente.data_agendamento_odontotop : moment().format('DD/MM/YYYY'),
                maxDate: moment().format('DD/MM/YYYY'),
                locale: {
                    format: 'DD/MM/YYYY',
                    cancelLabel: 'Limpar'
                },
            });
            $('#data_agendamento_odontotop').on('apply.daterangepicker', function (ev, picker) {
                $(this).val(picker.startDate.format('DD/MM/YYYY'));
                vm.cliente.data_agendamento_odontotop = picker.startDate.format('DD/MM/YYYY');
            });
            $('#data_agendamento_odontotop').on('cancel.daterangepicker', function(ev, picker) {
                $(this).val('');
                vm.cliente.data_agendamento_odontotop = '';
            });
        }

        // CARREGAR LIGAÇÕES
        // vm.carregarLigacoesOdontoTop = function() {
        //     // vm.atendimento = [];
        //     var dados = {
        //         'slug': $routeParams.slugCliente,
        //         'user_hash': Cookies.get('crm_funil_hash')
        //     }
        //     PessoasService.listarAtendimentos(dados).then(function(response) {
        //         console.log("carregarLigacoesOdontoTop");
        //         console.log(response.data);
        //         // return null;
        //         if (response.data != '') {
        //             vm.atendimentos = response.data;
        //             vm.carregarFechado();
        //         } else {
        //             vm.atendimentos = [];
        //             vm.atdPg = false;
        //         }
        //         if ($location.url().substring(0, 21) == '/pessoas/atendimento/') {
        //             vm.ativarTextarea(0);
        //         } else {
        //             vm.ativarTextarea(1);
        //         }

        //     }, function(error) {
        //         $log.error(error);
        //     });
        // };

        // ODONTO TOP --- FIM

    }
})();