(function() {
    'use strict';

    angular
        .module('app')
        .component('listaProdutosA9', {
            templateUrl: 'app/components/listaProdutosA9/listaProdutosA9.template.html',
            bindings: {
                produtosAdd: '=',
                valorProposta: '=',
                attDados: '&',
                contrato: '=',
                pagOnline: '='
            },
            controller: function($timeout, API, produtosService, negociosService, $window, loadSaas){

                const $ctrl = this;

                console.log('$ctrl.produtosAdd');
                console.log($ctrl.produtosAdd);
                console.log('$ctrl.valorProposta');
                console.log($ctrl.valorProposta);

                $ctrl.loading = true;
                $ctrl.url_img = API.url;

                $ctrl.seta_produtos = true;
                $ctrl.acc_produtos = function() {
                    $ctrl.seta_produtos = !$ctrl.seta_produtos;
                    $('.box_campos_produtos').fadeToggle();
                    $('.box_produtos_add').fadeToggle();
                };

                $ctrl.usuarios = [];
                $ctrl.buscaUsuarios = function(responsavel) {
                    console.log
                    $ctrl.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
                    $ctrl.items.then(function(items) { // ler array de retorno
                        $ctrl.saasid = items;
                        var dados = {
                            'saasid': $ctrl.saasid,
                            'user_hash': Cookies.get('crm_funil_hash'),
                            'acao': 'ALTERA',
                            'mod_id': 2,
                            'admins': true,
                        }
                        negociosService.findUsuarios(dados).then(function(response) {
                            $timeout(function() {
                                $ctrl.usuarios = [];
                                $ctrl.responsavel = {};
                                $ctrl.usuarios = response.data;
                                $timeout(function() {
                                    if (responsavel) {
                                        for (var x = 0; x < $ctrl.usuarios.length; x++) {
                                            if ($ctrl.usuarios[x].user_hash == responsavel) {
                                                $ctrl.responsavel = $ctrl.usuarios[x];
                                            }
                                        }
                                        // FILTRAR USUARIOS ATIVOS
                                        $ctrl.usuarios = $filter('filter')($ctrl.usuarios, {
                                            user_ativo: '1'
                                        });
                                    }
                                });
                            });
                        }, function(error) {
                            $log.error(error);
                        });
                    });
                };

                $ctrl.buscarProdutos = function() {
                    $ctrl.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
                    $ctrl.items.then(function(items) { // ler array de retorno
                        $ctrl.saasid = items;
                        var dados = {
                            'saasid': $ctrl.saasid
                        };
                        produtosService.findAll(dados).then(function(response) {
                            if (response.data != 0) {
                                console.log('buscarProdutos');
                                console.log(response.data);
                                $timeout(function() {
                                    $ctrl.produtosLista = response.data;
                                });
                            } else {
                                $timeout(function() {
                                    $ctrl.produtosLista = '';
                                });
                            }
                            //$ctrl.filtrar_produtos();
                        }, function(error) {
                            $log.error(error);
                        });
                    });
                };
                $ctrl.acc_produtos();

                $ctrl.produto = [];
                $ctrl.prod_qtd = 1;
                $ctrl.limparProduto = function() {
                    $ctrl.produtosAdd = [];
                };

                $ctrl.growableOptions4 = {
                    displayText: 'Produtos',
                    emptyListText: 'Nenhum produto encontrado',
                    emptySearchResultText: 'Nenhum produto encontrado',
                    // onAdd: function(texto) {
                    //     var deferred = $q.defer();
                    //     $ctrl.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
                    //     $ctrl.items.then(function(items) {
                    //         $ctrl.saasid = items;
                    //         $ctrl.leads = verificaLeads($ctrl.saasid); // carregar saas id
                    //         var dados = {
                    //             'nome': texto,
                    //             'saasid': $ctrl.saasid
                    //         };
                    //         $('.check_cadastro2').hide(); // remove o check
                    //         $('.spinner_cadastro2').toggleClass('hide'); // aparecer loading
                    //         produtosService.cadastrarProdutoRapido(dados).then(function(response) {
                    //             $ctrl.buscarProdutos();
                    //             $('.spinner_cadastro2').toggleClass('hide'); // remover loading
                    //             $('.check_cadastro2').fadeIn('slow'); // mostra check sucesso
                    //             $('.limpar').val(''); // limpar input após cadastro
                    //             $timeout(function() {
                    //                 $('.check_cadastro2').fadeOut('slow'); // após 2 segundos remove o check
                    //             }, 2000);
                    //             var produto = {
                    //                 'prod_id': response.data[0].prod_id,
                    //                 'prod_nome': response.data[0].prod_nome
                    //             };
                    //             deferred.resolve(produto);
                    //         }, function(error) {
                    //             $log.error(error);
                    //         });
                    //     });
                    //     return deferred.promise;
                    // }
                };

                $ctrl.error_produto = false;
                $ctrl.error_evento = false;
                $ctrl.adicionarProduto = function() {
                    console.log('$ctrl.produto');
                    console.log($ctrl.produto);
                    var dados = angular.copy($ctrl.produto);
                    dados.prod_qtd = $ctrl.prod_qtd;
                    dados.prod_qtd_original = $ctrl.prod_qtd;
                    dados.prod_valor = $ctrl.produto.price;
                    dados.prod_valor_total_original = $ctrl.produto.price * $ctrl.prod_qtd;
                    dados.prod_valor_total = $ctrl.produto.price * $ctrl.prod_qtd;
                    var existe = 0;
                    var evento = 0;
                    angular.forEach($ctrl.produtosAdd, function(value, key) {
                        if (value.prod_id == $ctrl.produto.prod_id) {
                            existe = 1;
                        }
                        if (value.eventos.id != $ctrl.produto.eventos.id) {
                            evento = 1;
                        }
                    });
                    console.log('evento');
                    console.log(evento);
                    if (existe == 1 || evento == 1) {
                        $timeout(function() {
                            existe == 1 ? $ctrl.error_produto = true : $ctrl.error_evento = true;
                            $timeout(function() {
                                $ctrl.error_produto = false;
                                $ctrl.error_evento = false;
                            }, 5000);
                        });
                    } else {
                        $ctrl.produtosAdd.push(dados);
                        $ctrl.atualizarValor();
                        $ctrl.produto = [];
                        $ctrl.prod_qtd = 1;
                        $ctrl.error_produto = false;
                        $ctrl.error_evento = false;
                    }
                };

                $ctrl.removerProdutos = function(index) {
                    swal({
                        title: 'Deseja realmente remover este produto?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                        closeOnConfirm: false
                    },
                    function() {
                        $window.onkeydown = null;
                        $window.onfocus = null;
                        swal.close();
                        $ctrl.produtosAdd.splice(index, 1);
                        $ctrl.atualizarValor();
                    })
                };

                $ctrl.editarProdutos = function(item, index) {
                    console.log(item)
                    $ctrl.dadosProduto = angular.copy(item);
                    $ctrl.dadosProduto.index = index;
                    if (!item.bonus) {
                        $ctrl.dadosProduto.bonus = ''
                    }
                    $ctrl.dadosProduto.prod_qtd_original = item.prod_qtd
                    if (item.prod_qtd_original && !item.bonus && (item.prod_qtd != item.prod_qtd_original)) {
                        $ctrl.dadosProduto.bonus = ''
                    }
                    $ctrl.dadosProduto.prod_valor_total = $ctrl.dadosProduto.prod_qtd * $ctrl.dadosProduto.prod_valor;
                    $ctrl.dadosProduto.prod_valor_total_original = $ctrl.dadosProduto.prod_qtd * $ctrl.dadosProduto.price;
                    $ctrl.descontosDisponiveis();

                    var modalEditarProdutos = $("#modalEditarProdutos");
                    modalEditarProdutos.modal({
                        show: true
                    }).on('shown.bs.modal', function(e) {
                        $('#qtdProduto').focus();
                        // $ctrl.aplicarPicker();
                    });
                    if(modalEditarProdutos.parent().get(0).tagName != 'BODY'){
                        $('.modal-backdrop').insertAfter(modalEditarProdutos);
                    }
                }

                $ctrl.descontosDisponiveis = function() {
                    var qtd = $ctrl.dadosProduto.prod_qtd;
                    angular.forEach($ctrl.dadosProduto.group_discounts, function(value) {
                        if (qtd >= value.min_quantity) {
                            value.disponivel = true
                        } else {
                            value.disponivel = false
                        }
                    })
                    angular.forEach($ctrl.dadosProduto.bonifications, function(value) {
                        if (qtd >= value.min_quantity) {
                            value.disponivel = true
                        } else {
                            value.disponivel = false
                        }
                    })
                    $ctrl.validarDescontos();
                }

                $ctrl.validarDescontos = function() {
                    $ctrl.dadosProduto.prod_valor = $ctrl.dadosProduto.price
                    let qtd = $ctrl.dadosProduto.prod_qtd;
                    let valor = $ctrl.dadosProduto.prod_valor;
                    let desconto = $ctrl.dadosProduto.percentage_discount * 100            
                    let valido = false
                    if (desconto) {
                        angular.forEach($ctrl.dadosProduto.group_discounts, function(value) {
                            if (value.disponivel) {
                                valido = false
                                if (desconto <= value.max_discount && qtd >= value.min_quantity) {
                                    valido = true
                                }
                            }
                        })
                        $ctrl.dadosProduto.desconto_invalido = !valido
                        if (valido || $ctrl.dadosProduto.responsavel_desconto) {
                            var novoValor = valor - ((desconto * valor) / 100)
                            $ctrl.dadosProduto.prod_valor = novoValor > 0 ? novoValor : 0
                            $ctrl.dadosProduto.prod_valor_total = novoValor > 0 ? ($ctrl.dadosProduto.prod_qtd - $ctrl.dadosProduto.bonus) * $ctrl.dadosProduto.prod_valor : 0;
                        } else {
                            $ctrl.dadosProduto.prod_valor = $ctrl.dadosProduto.price
                            $ctrl.dadosProduto.prod_valor_total = ($ctrl.dadosProduto.prod_qtd - $ctrl.dadosProduto.bonus) * $ctrl.dadosProduto.prod_valor;
                        }
                        if (valido) {
                            $ctrl.dadosProduto.responsavel_desconto = '';
                        }
                    } else {
                        $ctrl.dadosProduto.desconto_invalido = false;
                        $ctrl.dadosProduto.responsavel_desconto = '';
                        $ctrl.dadosProduto.prod_valor_total = ($ctrl.dadosProduto.prod_qtd - $ctrl.dadosProduto.bonus) * $ctrl.dadosProduto.prod_valor;
                    }
                    $ctrl.dadosProduto.prod_valor_total_original = ($ctrl.dadosProduto.prod_qtd - $ctrl.dadosProduto.bonus) * $ctrl.dadosProduto.price;
                }
                
                $ctrl.qtdInvalida = false;
                $ctrl.validaQuantidade = function(){
                    if ($ctrl.dadosProduto.prod_qtd > $ctrl.dadosProduto.quantity) {
                        $ctrl.qtdInvalida = true;
                        $ctrl.dadosProduto.prod_qtd = $ctrl.dadosProduto.quantity;
                        var self = this;
                        $timeout(function() {
                            self.qtdInvalida = false;
                        }, 2000);
                    }
                    $ctrl.dadosProduto.prod_qtd_original = $ctrl.dadosProduto.prod_qtd
                }
                
                $ctrl.bonusInvalido = false;
                $ctrl.validaBonus = function(){
                    let valido = false
                    let qtdValido = 0
                    $timeout(function() {
                        $('#qtdBonificar').focus();
                    }, 200);
                    var self = this;
                    if ($ctrl.dadosProduto.bonus && $ctrl.dadosProduto.bonificar) {
                        angular.forEach($ctrl.dadosProduto.bonifications, function(value) {
                            if (value.disponivel) {
                                valido = false
                                if ($ctrl.dadosProduto.bonus <= value.max_bonus_quantity) {
                                    valido = true
                                } else {
                                    qtdValido = value.max_bonus_quantity
                                }
                            }
                        })
                        if (valido) {
                            $ctrl.dadosProduto.prod_qtd = $ctrl.dadosProduto.prod_qtd_original
                            $ctrl.dadosProduto.prod_qtd = $ctrl.dadosProduto.prod_qtd + $ctrl.dadosProduto.bonus
                            $ctrl.bonusInvalido = false
                            $ctrl.validarDescontos()
                        } else {
                            $ctrl.bonusInvalido = true
                            $ctrl.dadosProduto.bonus = qtdValido
                            $timeout(function() {
                                self.bonusInvalido = false;
                            }, 2000);
                        }
                    } else {
                        $ctrl.dadosProduto.prod_qtd = $ctrl.dadosProduto.prod_qtd_original
                        $ctrl.bonusInvalido = false
                        $ctrl.dadosProduto.bonus = ''
                        $ctrl.validarDescontos()
                    }
                }

                $ctrl.salvarEdicaoProduto = function() {
                    $ctrl.produtosAdd[$ctrl.dadosProduto.index] = $ctrl.dadosProduto;
                    $ctrl.dadosProduto = '';
                    $('#modalEditarProdutos').modal('hide');
                    $ctrl.atualizarValor();
                }
                
                // INICIO QUANTIDADE
                $ctrl.initQuantidadeProd = function(i) {
                    var idSelectorSpan = String('#SpanEditQtdProd_' + i);
                    var idSelectorInput = String('#InputEditQtdProd_' + i);

                    setTimeout(function() {
                        angular.element(idSelectorSpan).show()
                        angular.element(idSelectorInput).hide()
                    }, 10);
                    return true;

                }

                $ctrl.editarQuantidadeProd = function(i, produto) {
                    var idSelectorSpan = String('#SpanEditQtdProd_' + i);
                    var idSelectorInput = String('#InputEditQtdProd_' + i);


                    setTimeout(function() {
                        angular.element(idSelectorSpan).hide()
                        angular.element(idSelectorInput).show()
                        angular.element(idSelectorInput).focus()

                        // console.log(idSelectorSpan, idSelectorInput);
                        // console.log(i, produto);
                    }, 10);
                }

                $ctrl.editarValorProd = function(i, qual) {
                    var idSelectorSpan = String('#SpanEditValorProd_' + i);
                    var idSelectorInput = String('#InputEditValorProd_' + i);


                    setTimeout(function() {
                        if (qual) {
                            angular.element(idSelectorSpan).hide()
                            angular.element(idSelectorInput).show()
                            angular.element(idSelectorInput).focus()
                        } else {
                            angular.element(idSelectorSpan).show()
                            angular.element(idSelectorInput).hide()
                        }
                    }, 10);
                }

                $ctrl.blurQuantidadeProd = function(i) {
                    var idSelectorSpan = String('#SpanEditQtdProd_' + i);
                    var idSelectorInput = String('#InputEditQtdProd_' + i);

                    setTimeout(function() {
                        angular.element(idSelectorSpan).show()
                        angular.element(idSelectorInput).hide()
                    }, 10);

                }

                $ctrl.updateQuantidadeProd = function(i, qtd) {
                    $ctrl.produtosAdd[i].prod_qtd = qtd;
                }

                // FINAL QUANTIDADE

                $ctrl.valorPadraoAlterado = false;
                $ctrl.alterarValorPadrao = function() {
                    if ($ctrl.negocios.valor && $ctrl.negocios.valor != 0) {
                        $ctrl.valorPadraoAlterado = true;
                        var quant = $ctrl.produtosAdd.length;
                        angular.forEach($ctrl.produtosAdd, function(value, key) {
                            $timeout(function() {
                                if ($ctrl.valorPadraoAlterado) {
                                    if (quant == 1) {
                                        $ctrl.produtosAdd[key].prod_valor = $ctrl.negocios.valor;
                                    } else {
                                        $ctrl.produtosAdd[key].prod_valor = parseFloat(parseFloat($ctrl.negocios.valor) / quant);
                                    }
                                }
                            });
                        });
                    } else {
                        $ctrl.valorPadraoAlterado = false;
                    }
                };

                $ctrl.atualizarValor = function() {
                    $timeout(function() {
                        var valor = 0;
                        angular.forEach($ctrl.produtosAdd, function(value, key) {
                            valor = parseFloat(parseFloat(valor) + parseFloat(value.prod_valor_total)); // SOMAR VALORES
                        });
                        $ctrl.valorProposta = parseFloat(valor);
                        console.log('$ctrl.valorProposta');
                        console.log($ctrl.valorProposta);
                        $ctrl.attDados()
                    });
                };

                $ctrl.checkUtilizarValores = false;
                $ctrl.utilizarValores = false;
                $('.campo_valor').hide();
                $ctrl.utilizarValoresProdutos = function() {
                    if ($ctrl.checkUtilizarValores) { // MARCADO
                        $timeout(function() {
                            $ctrl.utilizarValores = true;
                            $ctrl.desabilitarValor = true;
                            $timeout(function() {
                                $('.txt_valor').hide();
                                $('.campo_valor').fadeIn();
                                if ($ctrl.negocios.valor && $ctrl.negocios.valor != 0) {
                                    var quant = $ctrl.produtosAdd.length;
                                    angular.forEach($ctrl.produtosAdd, function(value, key) {
                                        $timeout(function() {
                                            if ($ctrl.valorPadraoAlterado) {
                                                if (quant == 0) {
                                                    $ctrl.produtosAdd[key].prod_valor = $ctrl.negocios.valor;
                                                } else {
                                                    $ctrl.produtosAdd[key].prod_valor = parseFloat(parseFloat($ctrl.negocios.valor) / quant);
                                                }
                                            }
                                        });
                                    });
                                }
                            }, 100);
                        });
                    } else { // NÃO MARCADO
                        $timeout(function() {
                            $ctrl.utilizarValores = false;
                            $ctrl.desabilitarValor = false;
                            $('.campo_valor').hide();
                            $('.txt_valor').fadeIn();
                        });
                    }
                };

                $ctrl.desabilitarValor = false;
                $ctrl.tooltipster = function() {
                    if ($ctrl.mobile) {
                        var acao = 'click';
                        var posicao = 'top';
                    } else {
                        var acao = 'hover';
                        var posicao = 'right';
                    }
                    $('.icon_ajuda_prod').tooltipster({
                        interactive: true,
                        trigger: acao,
                        maxWidth: 300,
                        position: posicao,
                    });
                };

            }
        })
}());
