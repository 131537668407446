/*
 * @Author: Glauber Funez
 * @Date:   2018-03-19 09:25:41
 * @Last Modified by:   Glauber Funez
 * @Last Modified time: 2018-08-21 09:39:53
 */

(function() {
    'use strict';

    angular
        .module('relatorios')
        .controller('relatoriosController', relatoriosController);


    relatoriosController.$inject = ['$routeParams', 'mensagem', '$location', 'relatoriosService', 'categoriasService', '$http', 'API', '$filter', '$timeout', 'loadSaas', 'validarSaas', 'PessoasService', 'produtosService', 'FactoryRoles', 'compromissosService', 'usuariosService', 'origemContatoService', 'ligacaoService', 'metasService', 'turmasVCService'];

    function relatoriosController($routeParams, mensagem, $location, relatoriosService, categoriasService, $http, API, $filter, $timeout, loadSaas, validarSaas, PessoasService, produtosService, FactoryRoles, compromissosService, usuariosService, origemContatoService, ligacaoService, metasService, turmasVCService) {

        var Base64 = {
            _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+=',
            encode: function(e) {
                var t = '';
                var n, r, i, s, o, u, a;
                var f = 0;
                e = Base64._utf8_encode(e);
                while (f < e.length) {
                    n = e.charCodeAt(f++);
                    r = e.charCodeAt(f++);
                    i = e.charCodeAt(f++);
                    s = n >> 2;
                    o = (n & 3) << 4 | r >> 4;
                    u = (r & 15) << 2 | i >> 6;
                    a = i & 63;
                    if (isNaN(r)) { u = a = 64; } else if (isNaN(i)) { a = 64; }
                    t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a);
                }
                return t;
            },
            decode: function(e) {
                var t = '';
                var n, r, i;
                var s, o, u, a;
                var f = 0;
                e = e.replace(/[^A-Za-z0-9+/=]/g, '');
                while (f < e.length) {
                    s = this._keyStr.indexOf(e.charAt(f++));
                    o = this._keyStr.indexOf(e.charAt(f++));
                    u = this._keyStr.indexOf(e.charAt(f++));
                    a = this._keyStr.indexOf(e.charAt(f++));
                    n = s << 2 | o >> 4;
                    r = (o & 15) << 4 | u >> 2;
                    i = (u & 3) << 6 | a;
                    t = t + String.fromCharCode(n);
                    if (u != 64) { t = t + String.fromCharCode(r); }
                    if (a != 64) { t = t + String.fromCharCode(i); }
                }
                t = Base64._utf8_decode(t);
                return t;
            },
            _utf8_encode: function(e) {
                e = e.replace(/rn/g, 'n');
                var t = '';
                for (var n = 0; n < e.length; n++) {
                    var r = e.charCodeAt(n);
                    if (r < 128) { t += String.fromCharCode(r); } else if (r > 127 && r < 2048) {
                        t += String.fromCharCode(r >> 6 | 192);
                        t += String.fromCharCode(r & 63 | 128);
                    } else {
                        t += String.fromCharCode(r >> 12 | 224);
                        t += String.fromCharCode(r >> 6 & 63 | 128);
                        t += String.fromCharCode(r & 63 | 128);
                    }
                }
                return t;
            },
            _utf8_decode: function(e) {
                var t = '';
                var n = 0;
                var c1 = 0;
                var c2 = 0;
                var r = c1 = c2 = 0;
                while (n < e.length) {
                    r = e.charCodeAt(n);
                    if (r < 128) {
                        t += String.fromCharCode(r);
                        n++;
                    } else if (r > 191 && r < 224) {
                        c2 = e.charCodeAt(n + 1);
                        t += String.fromCharCode((r & 31) << 6 | c2 & 63);
                        n += 2;
                    } else {
                        c2 = e.charCodeAt(n + 1);
                        c3 = e.charCodeAt(n + 2);
                        t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63);
                        n += 3;
                    }
                }
                return t;
            }
        };

        var vm = this;

        vm.titulo = 'Relatórios';

        vm.url = API.url;

        vm.periodo = '';
        vm.periodo_inicio = '';
        vm.periodo_fim = '';
        vm.tipo = 'todos';
        vm.qualificacao = 'todos';
        vm.negSelect = 'todos';

        vm.aplicarScroll = function() {
            $('.checkBoxContainer').niceScroll({ horizrailenabled: false });
        };

        vm.situacoes = [
            { name: 'Criadas', selecionado: true },
            { name: 'Ganhas', selecionado: true },
            { name: 'Perdidas', selecionado: true },
            { name: 'Abertas', selecionadoA: false },
            { name: 'Exibir Descontos', selecionadoA: false },
        ];

        vm.situacoesAtv = [
            { name: 'Abertos', selecionado: true },
            { name: 'Atrasados', selecionado: true },
            { name: 'Finalizados', selecionado: true },
            { name: 'Atendimentos', selecionado: true }
        ];

        // var arrSituacoes = $routeParams.situacoes.split('-');
        vm.desabilitar = true;
        vm.selecionarTodas = true;

        // ROLES --- INICIO
        vm.verificarVisualiza = function(mod) {
                if (mod == 'isModPessoas' && !FactoryRoles.checkRolesVisuRel('isModPessoas')) {
                    vm.tipo = '';
                }
                if (mod == 'isModCompromissos' && !FactoryRoles.checkRolesVisuRel('isModCompromissos')) {
                    vm.qualificacao = '';
                }
                if (mod == 'isModNegociacao' && !FactoryRoles.checkRolesVisuRel('isModNegociacao')) {
                    vm.qualificacao = '';
                }
                if (!FactoryRoles.checkRolesVisuRel('isModCompromissos') && FactoryRoles.checkRolesVisuRel('isModNegociacao')) {
                    vm.qualificacao = 'negocios';
                } else if (FactoryRoles.checkRolesVisuRel('isModCompromissos') && !FactoryRoles.checkRolesVisuRel('isModNegociacao')) {
                    vm.qualificacao = 'compromissos';
                }
                return FactoryRoles.checkRolesVisuRel(mod);
            }
            // ROLES --- FIM

        vm.mudar = function(qual, item) {
            if (qual) {
                vm.desabilitar = true;
                vm.situacoesSelecionadas = function() {
                    return 'Criadas-Perdidas-Ganhas-';
                };
                angular.forEach(vm.situacoes, function(item) {
                    item.selecionado = true;
                });
            } else {
                vm.situacoesSelecionadas = function() {
                    return vm.situacoes.filter(function(item) {
                        return item.selecionado;
                    }).map(function(item) {
                        return item.name;
                    }).join('-');
                };
                angular.forEach(vm.situacoes, function(item) {
                    item.selecionado = false;
                });
                vm.desabilitar = false;
            }
        };

        vm.mudarAtv = function(qual, item) {
            if (qual) {
                vm.situacoesSelecionadasAtv = function() {
                    return 'Abertos-Atrasados-Finalizados-';
                };
                angular.forEach(vm.situacoesAtv, function(item) {
                    item.selecionado = true;
                });
            } else {
                vm.situacoesSelecionadasAtv = function() {
                    return vm.situacoesAtv.filter(function(item) {
                        return item.selecionado;
                    }).map(function(item) {
                        return item.name;
                    }).join('-');
                };
                angular.forEach(vm.situacoesAtv, function(item) {
                    item.selecionado = false;
                });
            }
        };

        vm.situacoesSelecionadas = function() {
            return vm.situacoes.filter(function(item) {
                return item.selecionado;
            }).map(function(item) {
                return item.name;
            }).join('-');
        };

        vm.situacoesSelecionadasA = function() {
            return vm.situacoes.filter(function(item) {
                return item.selecionadoA;
                item.selecionadoA;
            }).map(function(item) {
                return item.name;
            }).join('-');
        };

        vm.situacoesSelecionadasAtv = function() {
            return vm.situacoesAtv.filter(function(item) {
                return item.selecionado;
            }).map(function(item) {
                return item.name;
            }).join('-');
        };

        // angular.forEach(arrSituacoes, function(value) {
        //     if (value.match(/Abertas/)) {
        //         vm.abertas = value;
        //     } else if (value == "Criadas") {
        //         vm.criadas = value;
        //     } else if (value == "Perdidas") {
        //         vm.perdidas = value;
        //     } else if (value == "Ganhas") {
        //         vm.ganhas = value;
        //     } else if (value == "Ganhas, Perdidas, Criadas") {
        //         vm.todas = "Ganhas, Perdidas, Criadas";

        //     }
        // });

        vm.tooltip = function() {
            $(document).ready(function() {
                $('[data-toggle="popover"]').popover();
                $('[data-toggle="tooltip"]').tooltip();
            });
        };

        vm.adm = false;
        vm.verificarAdm = function() {
            categoriasService.verificarAdm(Cookies.get('crm_funil_hash')).then(function(response) {
                if (response.data == 'Administrador') {
                    vm.adm = true;
                } else {
                    vm.adm = false;
                }
            }, function(error) {
                console.log('Erro: ', error);
            });
        };
        vm.verificarAdm();

        vm.cards = true;

        if ($routeParams.qual_filtro) { // MOSTRAR FILTROS
            vm.relatorio = $routeParams.qual_filtro;
            vm.titulo = 'Filtros';
            vm.cards = false;
            if ($routeParams.qual_filtro == 'qualificacao_leads') {
                vm.tituloFiltro = 'Qualificação de leads';
                vm.descricaoFiltro = 'Direcione esforços e conduza planos de ações para sua equipe, com a <span class="italico">Qualificação de Leads</span> saiba quem possui negociações e/ou compromissos. Assim é possível analisar quais leads estão sendo nutridos e quais precisam de maiores "cuidados".';
                $timeout(function() {
                    $('.descricao_filtro').html(vm.descricaoFiltro);
                });
            } else if ($routeParams.qual_filtro == 'negociacoes_clientes') {
                vm.tituloFiltro = 'Negociações de clientes';
                vm.descricaoFiltro = 'O <span class="italico">Acompanhamento de Negociações</span> permite que sejam identificadas as negociações criadas, ganhas e perdidas em um período especifico.';
                $timeout(function() {
                    $('.descricao_filtro').html(vm.descricaoFiltro);
                });
            } else if ($routeParams.qual_filtro == 'leaderboard') {
                vm.tituloFiltro = 'Leaderboard';
                vm.descricaoFiltro = 'Acompanhe a evolução da sua equipe de vendas com o relatório do <span class="italico">Leaderboard</span>.';
                $timeout(function() {
                    $('.descricao_filtro').html(vm.descricaoFiltro);
                });
            } else if ($routeParams.qual_filtro == 'pesquisaAvancada') {
                if ($routeParams.qual_tipo) {
                    $timeout(function() {
                        if ($routeParams.qual_tipo == 'e') {
                            if (FactoryRoles.checkRolesVisuRel('isModPessoas')) {
                                vm.tipo = 'empresas';
                                vm.selecionarTipo(vm.tipo);
                            }
                        } else if ($routeParams.qual_tipo == 'p') {
                            if (FactoryRoles.checkRolesVisuRel('isModPessoas')) {
                                vm.tipo = 'pessoas';
                                vm.selecionarTipo(vm.tipo);
                            }
                        }
                    }, 500);
                }
                vm.tituloFiltro = 'Pesquisa avançada';
                vm.descricaoFiltro = 'Encontre pessoas e empresas cruzando informações de tags; categorias; responsável, dentre outras informações cadastrais.';
                $timeout(function() {
                    $('.descricao_filtro').html(vm.descricaoFiltro);
                });
            } else if ($routeParams.qual_filtro == 'produtos') {
                vm.tituloFiltro = 'Produtos';
                vm.descricaoFiltro = 'Tenha informações detalhadas dos produtos que estão em negociações, saiba quais são os mais negociados e os que geram maior receita.';
                $timeout(function() {
                    $('.descricao_filtro').html(vm.descricaoFiltro);
                });
            } else if ($routeParams.qual_filtro == 'produtos-new') {
                vm.tituloFiltro = 'Produtos CMS';
                vm.descricaoFiltro = 'Tenha informações detalhadas dos produtos que estão em negociações, saiba quais são os mais negociados e os que geram maior receita.';
                $timeout(function() {
                    $('.descricao_filtro').html(vm.descricaoFiltro);
                });
            } else if ($routeParams.qual_filtro == 'atividades') {
                vm.tituloFiltro = 'Atividades do usuário';
                vm.descricaoFiltro = 'Acompanhe todas as atividades concluídas, abertas e, inclusive, atrasadas. Não deixe nada para trás!';
                $timeout(function() {
                    $('.descricao_filtro').html(vm.descricaoFiltro);
                });
            } else if ($routeParams.qual_filtro == 'origemContato') {
                vm.tituloFiltro = 'Origem dos Contatos';
                vm.descricaoFiltro = 'Filtre os contatos por sua origem, para saber quais são os meios mais utilizados para nutrir o CRM com leads.';
                $timeout(function() {
                    $('.descricao_filtro').html(vm.descricaoFiltro);
                });
            } else if ($routeParams.qual_filtro == 'extratoChamadas') {
                vm.tituloFiltro = 'Extrato de chamadas';
                vm.descricaoFiltro = 'Verifique o histórico, gravações e o valor investido nas ligações efetuadas por cada usuário.';
                $timeout(function() {
                    $('.descricao_filtro').html(vm.descricaoFiltro);
                });
            } else if ($routeParams.qual_filtro == 'metas') {
                vm.tituloFiltro = 'Metas';
                vm.descricaoFiltro = 'Analise o desempenho dos usuários de acordo com as metas estipuladas.';
                $timeout(function() {
                    $('.descricao_filtro').html(vm.descricaoFiltro);
                });
            } else if ($routeParams.qual_filtro == 'motivosPerdas') {
                vm.tituloFiltro = 'Motivos de Perdas';
                vm.descricaoFiltro = 'Verifique quais são os principais motivos de perdas das negociações e encontre os maiores gargalos de seu processo comercial.';
                $timeout(function() {
                    $('.descricao_filtro').html(vm.descricaoFiltro);
                });
            } else if ($routeParams.qual_filtro == 'odontotop') {
                vm.tituloFiltro = 'Odonto Top';
                vm.descricaoFiltro = 'Clientes por unidade, status ou data de entrada.';
                $timeout(function() {
                    $('.descricao_filtro').html(vm.descricaoFiltro);
                });
            }
        } else { // MOSTRAR CARDSS
            vm.cards = true;
        }

        vm.rangePeriodo = function() {
            function cb(start, end) {
                if (start._isValid) { // datas validas
                    vm.periodo_inicio = start.format('DD_MM_YYYY');
                    vm.periodo_fim = end.format('DD_MM_YYYY');;
                } else {

                }
            }
            $('.campo_periodo').daterangepicker({
                //autoUpdateInput: false,
                'opens': 'center',
                'locale': {
                    'format': 'DD/MM/YYYY',
                    'cancelLabel': 'Limpar'
                },
                ranges: {
                    'Hoje': [moment(), moment()],
                    'Ontem': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Últ. 7 dias': [moment().subtract(6, 'days'), moment()],
                    'Últ. 30 dias': [moment().subtract(29, 'days'), moment()],
                    'Este mês': [moment().startOf('month'), moment().endOf('month')],
                    'Mês passado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            });
            //}, cb);
            $('.campo_periodo').on('apply.daterangepicker', function(ev, picker) {
                $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
                vm.periodo_inicio = picker.startDate.format('DD_MM_YYYY');
                vm.periodo_fim = picker.endDate.format('DD_MM_YYYY');
            });

            $('.campo_periodo').on('cancel.daterangepicker', function(ev, picker) {
                $(this).val('');
                vm.periodo = '';
                vm.periodo_inicio = '';
                vm.periodo_fim = '';
            });
        };

        vm.url_img = API.url;

        vm.selectUsuarios = [];
        vm.selectedUsuarios = [];
        vm.buscaUsuarios = function(mod) {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid
                };
                relatoriosService.findUsuariosRelatorios(dados).then(function(response) {
                    var array = [];

                    var local = window.localStorage.getItem('5c479de2');
                    var valLocal = JSON.parse(API.funilRC.decrypt(local));
                    var localNeg = window.localStorage.getItem(mod);
                    var valLocalNeg = JSON.parse(API.funilRC.decrypt(localNeg));

                    vm.roleVisualiza = parseInt(valLocalNeg.visualiza);

                    /* GESTOR --- INICIO*/
                    if (vm.roleVisualiza == 1) {
                        var gestores = $filter('filter')(response.data.usuarios, { fk_perfil_id: '1' });
                        if (gestores != '') {
                            array.push({ name: "Gestor", msGroup: true });
                            angular.forEach(gestores, function(value, key) {
                                if (value.user_avatar == null) {
                                    var icon =
                                        '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                                        '<div class="cell table">' +
                                        '<div class="row">' +
                                        '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                                        '</span>' +
                                        '</div>' +
                                        '</div>' +
                                        '</div>';
                                } else {
                                    var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                                }
                                if (value.user_ativo == 0) {
                                    var name = value.user_nome + ' (Inativo)';
                                } else {
                                    name = value.user_nome;
                                }
                                if (value.user_hash == Cookies.get('crm_funil_hash')) {
                                    var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                                }
                                if (vm.relatorio == 'atividades') {
                                    var ticked = false;
                                } else {
                                    var ticked = true;
                                }
                                array.push({ 'icon': icon, 'name': name, 'ticked': ticked, 'slug': value.user_slug });
                            });
                            array.push({ msGroup: false });
                        }
                    }
                    /* GESTOR --- FIM*/

                    vm.montarSelectUsuarios(array, response.data.usuarios, response.data.grupos, mod);
                    /*angular.forEach(response.data, function(value, key) {
                        if (value.user_avatar == null) {
                            var icon =
                                '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                                '<div class="cell table">' +
                                '<div class="row">' +
                                '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                                '</span>' +
                                '</div>' +
                                '</div>' +
                                '</div>';
                        } else {
                            var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                        }
                        if (value.user_ativo == 0) {
                            var name = value.user_nome + ' (Inativo)';
                        } else {
                            name = value.user_nome;
                        }
                        vm.selectUsuarios.push({ 'icon': icon, 'name': name, 'ticked': true, 'slug': value.user_slug });
                    });

                    vm.quantUsuarios = vm.selectUsuarios.length;
                    vm.selecionadosInicio = [];
                    angular.forEach(vm.selectUsuarios, function(value, key) {
                        vm.selecionadosInicio.push(value.slug);
                        vm.usuarioSelecionado = vm.selecionadosInicio.join();
                    });
                    vm.TodosNenhum(vm.usuarioSelecionado, 'usuarios');
                    vm.saasidSelecionado = vm.saasid;*/
                }, function(error) {
                    console.error(error);
                });
            });
        };

        vm.montarSelectUsuarios = function(array, usuarios, grupos, mod) {
            var local = window.localStorage.getItem('5c479de2');
            var valLocal = JSON.parse(API.funilRC.decrypt(local));
            var grupo_id = valLocal[0].fk_gru_id;
            var localNeg = window.localStorage.getItem(mod);
            var valLocalNeg = JSON.parse(API.funilRC.decrypt(localNeg));

            vm.roleVisualiza = parseInt(valLocalNeg.visualiza);
            if (vm.roleVisualiza != 4) {
                /* GRUPOS --- INICIO*/
                if (vm.roleVisualiza == 2 || vm.roleVisualiza == 1) {
                    if (grupos != '') {
                        angular.forEach(grupos, function(value, key) {
                            if (vm.roleVisualiza == 2) {
                                if (grupo_id == value.gru_id) {
                                    array.push({ name: value.gru_nome, msGroup: true });
                                    if (value.usuarios.length != '') {
                                        angular.forEach(value.usuarios, function(value, key) {
                                            if (value.user_avatar == null) {
                                                var icon =
                                                    '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                                                    '<div class="cell table">' +
                                                    '<div class="row">' +
                                                    '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                                                    '</span>' +
                                                    '</div>' +
                                                    '</div>' +
                                                    '</div>';
                                            } else {
                                                var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                                            }
                                            if (value.user_ativo == 0) {
                                                var name = value.user_nome + ' (Inativo)';
                                            } else {
                                                name = value.user_nome;
                                            }
                                            if (value.user_hash == Cookies.get('crm_funil_hash')) {
                                                var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                                            }
                                            if (vm.relatorio == 'atividades') {
                                                var ticked = false;
                                            } else {
                                                var ticked = true;
                                            }
                                            array.push({ 'icon': icon, 'name': name, 'ticked': ticked, 'slug': value.user_slug });
                                        });
                                    } else {
                                        array.push({ 'icon': '', 'name': 'Nenhum usuário', 'ticked': false, 'slug': '', 'disabled': true });
                                    }
                                    array.push({ msGroup: false });
                                }
                            } else {
                                array.push({ name: value.gru_nome, msGroup: true });
                                if (value.usuarios.length != '') {
                                    angular.forEach(value.usuarios, function(value, key) {
                                        if (value.user_avatar == null) {
                                            var icon =
                                                '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                                                '<div class="cell table">' +
                                                '<div class="row">' +
                                                '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                                                '</span>' +
                                                '</div>' +
                                                '</div>' +
                                                '</div>';
                                        } else {
                                            var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                                        }
                                        if (value.user_ativo == 0) {
                                            var name = value.user_nome + ' (Inativo)';
                                        } else {
                                            name = value.user_nome;
                                        }
                                        if (value.user_hash == Cookies.get('crm_funil_hash')) {
                                            var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                                        }
                                        if (vm.relatorio == 'atividades') {
                                            var ticked = false;
                                        } else {
                                            var ticked = true;
                                        }
                                        array.push({ 'icon': icon, 'name': name, 'ticked': ticked, 'slug': value.user_slug });
                                    });
                                } else {
                                    array.push({ 'icon': '', 'name': 'Nenhum usuário', 'ticked': false, 'slug': '', 'disabled': true });
                                }
                                array.push({ msGroup: false });
                            }

                        });
                    }
                }
                /* GRUPOS --- FIM*/

                if (vm.roleVisualiza == 1) {
                    var representantes = $filter('filter')(usuarios, { fk_perfil_id: '5' });
                    if (representantes != '') {
                        array.push({ name: 'Representante', msGroup: true });
                        angular.forEach(representantes, function(value, key) {
                            if (value.user_avatar == null) {
                                var icon =
                                    '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                                    '<div class="cell table">' +
                                    '<div class="row">' +
                                    '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                                    '</span>' +
                                    '</div>' +
                                    '</div>' +
                                    '</div>';
                            } else {
                                var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                            }
                            if (value.user_ativo == 0) {
                                var name = value.user_nome + ' (Inativo)';
                            } else {
                                name = value.user_nome;
                            }
                            if (value.user_hash == Cookies.get('crm_funil_hash')) {
                                var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                            }
                            if (vm.relatorio == 'atividades') {
                                var ticked = false;
                            } else {
                                var ticked = true;
                            }
                            array.push({ 'icon': icon, 'name': name, 'ticked': ticked, 'slug': value.user_slug });
                        });
                        array.push({ msGroup: false });

                    }
                    var gerentesGerais = $filter('filter')(usuarios, { fk_perfil_id: '3' });
                    if (gerentesGerais != '') {
                        array.push({ name: 'Gerente Geral', msGroup: true });
                        angular.forEach(gerentesGerais, function(value, key) {
                            if (value.user_avatar == null) {
                                var icon =
                                    '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                                    '<div class="cell table">' +
                                    '<div class="row">' +
                                    '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                                    '</span>' +
                                    '</div>' +
                                    '</div>' +
                                    '</div>';
                            } else {
                                var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                            }
                            if (value.user_ativo == 0) {
                                var name = value.user_nome + ' (Inativo)';
                            } else {
                                name = value.user_nome;
                            }
                            if (value.user_hash == Cookies.get('crm_funil_hash')) {
                                var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                            }
                            if (vm.relatorio == 'atividades') {
                                var ticked = false;
                            } else {
                                var ticked = true;
                            }
                            array.push({ 'icon': icon, 'name': name, 'ticked': ticked, 'slug': value.user_slug });
                        });
                        array.push({ msGroup: false });
                    }
                }

                if (vm.roleVisualiza == 3) {
                    angular.forEach(usuarios, function(value, key) {
                        if (value.user_hash == Cookies.get('crm_funil_hash')) {
                            if (value.user_avatar == null) {
                                var icon =
                                    '<div class="pull-left profile" style="height:20px;margin-top:0px;margin-left:0px;padding: 0 !important;">' +
                                    '<div class="cell table">' +
                                    '<div class="row">' +
                                    '<span ng-if="!vm.responsavel[0].icon" class="circle_profile fundo_profile cell text-center img_background_user" style="text-transform:uppercase;width:20px;height:20px;box-shadow:0px 0px 5px #5c7182 ;font-size:11px;">' + value.sigla +
                                    '</span>' +
                                    '</div>' +
                                    '</div>' +
                                    '</div>';
                            } else {
                                var icon = '<img class=\'user_avatar\' src=\'' + API.url + '/usuarios/avatar/' + value.user_avatar + '?date=' + vm.rand + '\'/>';
                            }
                            if (value.user_ativo == 0) {
                                var name = value.user_nome + ' (Inativo)';
                            } else {
                                name = value.user_nome;
                            }
                            if (value.user_hash == Cookies.get('crm_funil_hash')) {
                                var name = "<strong style='font-weight: 600;'>" + value.user_nome + "</strong>";
                            }
                            if (vm.relatorio == 'atividades') {
                                var ticked = false;
                            } else {
                                var ticked = true;
                            }
                            array.push({ 'icon': icon, 'name': name, 'ticked': ticked, 'slug': value.user_slug });
                        }
                    });
                }

                vm.selectUsuarios = array;

                if (vm.relatorio == 'pesquisaAvancada') {
                    if (vm.roleVisualiza == 1) {
                        vm.selectUsuarios.push({ 'icon': '<i class=\'fa fa-times\'></i>', 'name': 'Nenhum responsável', 'ticked': true, 'slug': 'nenhum' });
                    }
                }

                vm.somente_users = $filter('filter')(vm.selectUsuarios, { slug: '' });
                //return null;
                vm.saasidSelecionado = vm.saasid;
                vm.quantUsuarios = vm.somente_users.length;
                vm.selecionadosInicio = [];
                angular.forEach(vm.somente_users, function(value, key) {
                    vm.selecionadosInicio.push(value.slug);
                    if (vm.relatorio != 'atividades') {
                        vm.usuarioSelecionado = vm.selecionadosInicio.join();
                    }
                });
                vm.TodosNenhum(vm.usuarioSelecionado, 'usuarios');

                if (vm.roleVisualiza == 4) {
                    if (vm.relatorio == 'pesquisaAvancada') {
                        vm.usuarioSelecionado == 'não selecionado';
                    }
                }
            }
        }

        //TRADUÇÃO DO INPUT SELECT DE USUÁRIOS
        vm.ptbr = {
            selectAll: 'Todos',
            selectNone: 'Limpar',
            search: 'Pesquisar...',
            nothingSelected: 'Selecionar usuários'
        };
        vm.ptbrC = {
            selectAll: 'Todos',
            selectNone: 'Limpar',
            search: 'Pesquisar...',
            nothingSelected: 'Selecionar cargos'
        };
        vm.ptbrCid = {
            selectAll: 'Todos',
            selectNone: 'Limpar',
            search: 'Pesquisar...',
            nothingSelected: 'Selecionar cidades'
        };
        vm.ptbrE = {
            selectAll: 'Todos',
            selectNone: 'Limpar',
            search: 'Pesquisar...',
            nothingSelected: 'Selecionar estados'
        };
        vm.ptbrPA = {
            selectAll: 'Todos',
            selectNone: 'Limpar',
            search: 'Pesquisar...',
            nothingSelected: 'Selecionar país'
        };
        vm.ptbrT = {
            selectAll: 'Todos',
            selectNone: 'Limpar',
            search: 'Pesquisar...',
            nothingSelected: 'Selecionar tags'
        };
        vm.ptbrAtiv = {
            selectAll: 'Todos',
            selectNone: 'Limpar',
            search: 'Pesquisar...',
            nothingSelected: 'Selecionar usuário'
        };
        vm.ptbrAtivComp = {
            selectAll: 'Todos',
            selectNone: 'Limpar',
            search: 'Pesquisar...',
            nothingSelected: 'Selecionar tipo de compromisso'
        };
        vm.ptbrOrigens = {
            selectAll: 'Todos',
            selectNone: 'Limpar',
            search: 'Pesquisar...',
            nothingSelected: 'Selecionar origem'
        };

        vm.fSelectAll = function(qual) {
            if (qual == 'usuarios') {
                vm.novoTodos = [];
                angular.forEach(vm.somente_users, function(value, key) {
                    vm.novoTodos.push(value.slug);
                    vm.usuarioSelecionado = vm.novoTodos.join();
                });
                vm.TodosNenhum(vm.usuarioSelecionado, 'usuarios');
            } else if (qual == 'cargos') {
                vm.novoTodosC = [];
                angular.forEach(vm.cargosRepeat, function(value, key) {
                    vm.novoTodosC.push(value.id);
                    vm.cargoSelecionado = vm.novoTodosC.join();
                });
                vm.TodosNenhum(vm.cargoSelecionado, 'cargos');
            } else if (qual == 'cidades') {
                vm.novoTodosCid = [];
                angular.forEach(vm.cidadesRepeat, function(value, key) {
                    vm.novoTodosCid.push(value.name);
                    vm.cidadeSelecionado = vm.novoTodosCid.join();
                });
                vm.TodosNenhum(vm.cidadeSelecionado, 'cidades');
            } else if (qual == 'estados') {
                vm.novoTodosE = [];
                angular.forEach(vm.estadosRepeat, function(value, key) {
                    vm.novoTodosE.push(value.name);
                    vm.estadoSelecionado = vm.novoTodosE.join();
                });
                vm.TodosNenhum(vm.estadoSelecionado, 'estados');
            } else if (qual == 'pais') {
                // glauber
                vm.novoTodosPA = [];
                angular.forEach(vm.paisRepeat, function(value, key) {
                    vm.novoTodosPA.push(value.name);
                    vm.paisSelecionado = vm.novoTodosPA.join();
                });
                vm.TodosNenhum(vm.paisSelecionado, 'pais');
            } else if (qual == 'tags') {
                vm.novoTodosT = [];
                angular.forEach(vm.tagsRepeat, function(value, key) {
                    vm.novoTodosT.push(value.id);
                    vm.tagSelecionado = vm.novoTodosT.join();
                });
                vm.TodosNenhum(vm.tagSelecionado, 'tags');
            } else if (qual == 'produtos') {
                vm.novoTodosProd = [];
                angular.forEach(vm.produtosRepeat, function(value, key) {
                    vm.novoTodosProd.push(value.slug);
                    vm.produtoSelecionado = vm.novoTodosProd.join();
                });
                vm.todosNenhumProd(vm.produtoSelecionado, 'produtos');
            } else if (qual == 'tipoComp') {
                vm.novoTodosTipoComp = [];
                angular.forEach(vm.tiposCompromissoRepeat, function(value, key) {
                    vm.novoTodosTipoComp.push(value.slug);
                    vm.tipoCompSelecionado = vm.novoTodosTipoComp.join();
                });
                vm.todosNenhumTipoComp(vm.tipoCompSelecionado, 'tipoComp');
            } else if (qual == 'origem') {
                vm.novoOrigem = [];
                angular.forEach(vm.origensRepeat, function(value, key) {
                    vm.novoOrigem.push(value.slug);
                    vm.origemSelecionado = vm.novoOrigem.join();
                });
                vm.todosNenhumOrigem(vm.origemSelecionado, 'origem');
            }
        };

        vm.selectedCargos = [];
        vm.fClick = function(data, qual) {
            if (qual == 'usuarios') {
                vm.usuarioSelecionado = [];
                vm.novo = [];
                angular.forEach(vm.selectedUsuarios, function(value, key) {
                    vm.novo.push(value.slug);
                    vm.usuarioSelecionado = vm.novo.join();
                });
                vm.TodosNenhum(vm.usuarioSelecionado, 'usuarios');
            } else if (qual == 'cargos') {
                vm.cargoSelecionado = [];
                vm.novoC = [];
                angular.forEach(vm.selectedCargos, function(value, key) {
                    vm.novoC.push(value.id);
                    vm.cargoSelecionado = vm.novoC.join();
                });
                vm.TodosNenhum(vm.cargoSelecionado, 'cargos');
            } else if (qual == 'cidades') {
                vm.cidadeSelecionado = [];
                vm.novoCid = [];
                angular.forEach(vm.selectedCidades, function(value, key) {
                    vm.novoCid.push(value.name);
                    vm.cidadeSelecionado = vm.novoCid.join();
                });
                vm.TodosNenhum(vm.cidadeSelecionado, 'cidades');
            } else if (qual == 'estados') {
                vm.estadoSelecionado = [];
                vm.novoE = [];
                angular.forEach(vm.selectedEstados, function(value, key) {
                    vm.novoE.push(value.name);
                    vm.estadoSelecionado = vm.novoE.join();
                });
                vm.TodosNenhum(vm.estadoSelecionado, 'estados');
            } else if (qual == 'pais') {
                vm.paisSelecionado = [];
                vm.novoPA = [];
                angular.forEach(vm.paisSelect, function(value, key) {
                    vm.novoPA.push(value.name);
                    vm.paisSelecionado = vm.novoPA.join();
                });
                vm.TodosNenhum(vm.paisSelecionado, 'pais');
            } else if (qual == 'tags') {
                vm.tagSelecionado = [];
                vm.novoT = [];
                angular.forEach(vm.selectedTags, function(value, key) {
                    vm.novoT.push(value.id);
                    vm.tagSelecionado = vm.novoT.join();
                });
                vm.TodosNenhum(vm.tagSelecionado, 'tags');
            } else if (qual == 'produtos') {
                vm.produtoSelecionado = [];
                vm.novoTodosProd = [];
                angular.forEach(vm.selectedProdutos, function(value, key) {
                    vm.novoTodosProd.push(value.slug);
                    vm.produtoSelecionado = vm.novoTodosProd.join();
                });
                vm.todosNenhumProd(vm.produtoSelecionado, 'produtos');
            } else if (qual == 'tipoComp') {
                vm.tipocompSelecionado = [];
                vm.novoTipoComp = [];
                angular.forEach(vm.selectedTipoComp, function(value, key) {
                    vm.novoTipoComp.push(value.id);
                    vm.tipocompSelecionado = vm.novoTipoComp.join();
                });
                vm.todosNenhumTipoComp(vm.tipocompSelecionado, 'tipoComp');
            } else if (qual == 'origem') {
                vm.origemSelecionado = [];
                vm.novoOrigem = [];
                angular.forEach(vm.selectedOrigem, function(value, key) {
                    vm.novoOrigem.push(value.id);
                    vm.origemSelecionado = vm.novoOrigem.join();
                });
                vm.todosNenhumOrigem(vm.origemSelecionado, 'origem');
            }
        };

        vm.fSelectNone = function(qual) {
            if (qual == 'usuarios') {
                vm.novoTodos = [];
                angular.forEach(vm.selectUsuarios, function(value, key) {
                    vm.novoTodos.splice(key);
                    vm.usuarioSelecionado = vm.novoTodos.join();
                });
                vm.TodosNenhum(vm.usuarioSelecionado, 'usuarios');
            } else if (qual == 'cargos') {
                vm.novoTodosC = [];
                angular.forEach(vm.cargosRepeat, function(value, key) {
                    vm.novoTodosC.splice(key);
                    vm.cargoSelecionado = vm.novoTodosC.join();
                });
                vm.TodosNenhum(vm.cargoSelecionado, 'cargos');
            } else if (qual == 'cidades') {
                vm.novoTodosCid = [];
                angular.forEach(vm.cidadesRepeat, function(value, key) {
                    vm.novoTodosCid.splice(key);
                    vm.cidadeSelecionado = vm.novoTodosCid.join();
                });
                vm.TodosNenhum(vm.cidadeSelecionado, 'cidades');
            } else if (qual == 'estados') {
                vm.novoTodosE = [];
                angular.forEach(vm.estadosRepeat, function(value, key) {
                    vm.novoTodosE.splice(key);
                    vm.estadoSelecionado = vm.novoTodosE.join();
                });
                vm.TodosNenhum(vm.estadoSelecionado, 'estados');
            } else if (qual == 'pais') {
                vm.novoTodosPA = [];
                angular.forEach(vm.paisRepeat, function(value, key) {
                    vm.novoTodosPA.splice(key);
                    vm.paisSelecionado = vm.novoTodosPA.join();
                });
                vm.TodosNenhum(vm.paisSelecionado, 'pais');
            } else if (qual == 'tags') {
                vm.novoTodosT = [];
                angular.forEach(vm.tagsRepeat, function(value, key) {
                    vm.novoTodosT.splice(key);
                    vm.tagSelecionado = vm.novoTodosT.join();
                });
                vm.TodosNenhum(vm.tagSelecionado, 'tags');
            } else if (qual == 'produtos') {
                vm.novoTodosProd = [];
                angular.forEach(vm.produtosRepeat, function(value, key) {
                    vm.novoTodosProd.splice(key);
                    vm.produtoSelecionado = vm.novoTodosProd.join();
                });
                vm.todosNenhumProd(vm.produtoSelecionado, 'produtos');
            } else if (qual == 'tipoComp') {
                vm.novoTodosTipoComp = [];
                angular.forEach(vm.produtosRepeat, function(value, key) {
                    vm.novoTodosTipoComp.splice(key);
                    vm.tipocompSelecionado = vm.novoTodosTipoComp.join();
                });
                vm.todosNenhumTipoComp(vm.tipocompSelecionado, 'tipoComp');
            } else if (qual == 'origem') {
                vm.novoTodosOrigem = [];
                angular.forEach(vm.origensRepeat, function(value, key) {
                    vm.novoTodosOrigem.splice(key);
                    vm.origemSelecionado = vm.novoTodosOrigem.join();
                });
                vm.todosNenhumOrigem(vm.origemSelecionado, 'origem');
            }
        };

        vm.TodosNenhum = function(array, qual) {
            if (array) {
                if (array != '') {
                    var array = array.split(',');
                } else {
                    var array = '';
                }
            }
            if (qual == 'usuarios') {
                if (vm.relatorio == 'pesquisaAvancada' || vm.relatorio == 'extratoChamadas') {
                    if (array.length == vm.quantUsuarios) {
                        vm.usuarioSelecionado = 'todos';
                    } else if (array.length == 0) {
                        vm.usuarioSelecionado = 'não selecionado';
                    } else if (array == 'nenhum') {
                        vm.usuarioSelecionado = 'nenhum';
                    }
                }
            } else if (qual == 'cargos') {
                if (array.length == vm.quantCargos) {
                    vm.cargoSelecionado = 'todos';
                } else if (array.length == 0) {
                    vm.cargoSelecionado = 'não selecionado';
                } else if (array == 'nenhum') {
                    vm.cargoSelecionado = 'nenhum';
                }
            } else if (qual == 'cidades') {
                if (array.length == vm.quantCidades) {
                    vm.cidadeSelecionado = 'todos';
                } else if (array.length == 0) {
                    vm.cidadeSelecionado = 'não selecionado';
                } else if (array == 'Nenhuma cidade') {
                    vm.cidadeSelecionado = 'nenhum';
                }
            } else if (qual == 'estados') {
                if (array.length == vm.quantEstados) {
                    vm.estadoSelecionado = 'todos';
                } else if (array.length == 0) {
                    vm.estadoSelecionado = 'não selecionado';
                } else if (array == 'Nenhum estado') {
                    vm.estadoSelecionado = 'nenhum';
                }
            } else if (qual == 'pais') {
                // glauber
                if (array.length == vm.quantPais) {
                    vm.paisSelecionado = 'todos';
                } else if (array.length == 0) {
                    vm.paisSelecionado = 'não selecionado';
                } else if (array == 'Nenhum pais') {
                    vm.paisSelecionado = 'nenhum';
                }
            } else if (qual == 'tags') {
                if (array.length == vm.quantTags) {
                    vm.tagSelecionado = 'todos';
                } else if (array.length == 0) {
                    vm.tagSelecionado = 'não selecionado';
                } else if (array == 'nenhum') {
                    vm.tagSelecionado = 'nenhum';
                }
            } else if (qual == 'tipo') {
                if (vm.tipoSelecionado == 'empresas') {
                    angular.forEach(vm.cargosRepeat, function(value, key) {
                        vm.cargosRepeat[key].ticked = false;
                    });
                    vm.fSelectNone('cargos');
                    vm.desabilitarCargo = true;
                } else {
                    if (vm.cargosRepeat != '') {
                        vm.desabilitarCargo = false;
                    }
                }
            }

            if (vm.negSelSelecionado == undefined || vm.negSelSelecionado == null) {
                vm.negSelSelecionado = 'todos';
            }

            function b64EncodeUnicode(str) {
                return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
                    function toSolidBytes(match, p1) {
                        return String.fromCharCode('0x' + p1);
                    }));
            }


            function b64DecodeUnicode(str) {
                return decodeURIComponent(atob(str).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
            }


            vm.urlEnviar = vm.saasidSelecionado + '/' + vm.tipoSelecionado + '/' + vm.usuarioSelecionado + '/' + vm.cargoSelecionado + '/' + vm.categoriaSelecionado + '/' + vm.cidadeSelecionado + '/' + vm.estadoSelecionado + '/' + vm.tagSelecionado + '/' + vm.paisSelecionado + '/' + vm.negSelSelecionado;

            vm.urlEnviar = b64EncodeUnicode(vm.urlEnviar);
        };

        vm.todosNenhumProd = function(array, qual) {
            if (array) {
                if (array != '') {
                    var array = array.split(',');
                } else {
                    var array = '';
                }
            }
            if (array.length == vm.quantProdutos) {
                vm.produtoSelecionado = 'todos';
            }

            vm.urlEnviarProd = vm.saasidSelecionado + '/' + vm.produtoSelecionado;
            //vm.urlEnviarProd = Base64.encode(vm.urlEnviarProd);
        };

        vm.todosNenhumTipoComp = function(array, qual) {
            if (array) {
                if (array != '') {
                    var array = array.split(',');
                } else {
                    var array = '';
                }
            }
            if (array.length == vm.quantTipoComp) {
                vm.tipocompSelecionado = 'todos';
            } else if (array.length == '') {
                vm.tipocompSelecionado = 'nenhum';
            }
            vm.tipocompSelecionado = Base64.encode(vm.tipocompSelecionado);
        };

        vm.todosNenhumOrigem = function(array, qual) {
            if (array) {
                if (array != '') {
                    var array = array.split(',');
                } else {
                    var array = '';
                }
                if (array.length == vm.quantOrigem) {
                    vm.origemSelecionado = 'todos';
                } else if (array.length == '') {
                    vm.origemSelecionado = 'nenhum';
                }
                vm.origemSelecionado = Base64.encode(vm.origemSelecionado);
            }
        };

        /*PESQUISA AVANÇADA --- INÍCIO*/
        vm.listarCargos = function() {
            vm.cargosRepeat = [];
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) {
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid
                };
                PessoasService.listarCargos(dados).then(function(response) {
                    if (FactoryRoles.checkRolesVisuFUNIL('isModConfig')) {
                        if (response.data != '0') {
                            angular.forEach(response.data, function(value, key) {
                                vm.cargosRepeat.push({ 'name': value.car_nome, 'ticked': false, 'id': value.car_id });
                            });
                            vm.cargosRepeat.push({ 'icon': '<i class=\'fa fa-times\'></i>', 'name': 'Nenhum cargo', 'ticked': false, 'id': 'nenhum' });
                        } else {
                            vm.cargosRepeat = [];
                        }
                        vm.quantCargos = vm.cargosRepeat.length;
                        vm.selecionadosInicioC = [];
                        vm.cargoSelecionado = '';
                        /*angular.forEach(vm.cargosRepeat, function(value, key) {
                            vm.selecionadosInicioC.push(value.id);
                            vm.cargoSelecionado = vm.selecionadosInicioC.join();
                        })*/
                        vm.TodosNenhum(vm.cargoSelecionado, 'cargos');
                    } else {
                        vm.cargosRepeat = [];
                        vm.quantCargos = vm.cargosRepeat.length;
                        vm.selecionadosInicioC = [];
                        vm.cargoSelecionado = '';
                        vm.TodosNenhum('nenhum', 'cargos');
                        vm.desabilitarCargo = true;
                    }
                }, function(error) {
                    console.error(error);
                });
            });
        };
        vm.listarCargos();


        vm.buscaCategorias = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                if (FactoryRoles.checkRolesVisuFUNIL('isModConfig')) {
                    PessoasService.findCategorias(vm.saasid).then(function(response) {
                        vm.categorias = response.data;
                    }, function(error) {
                        console.error(error);
                    });
                    vm.selecionarCateg('todos');
                } else {
                    vm.selecionarCateg('nenhum');
                    vm.desabilitarCat = true;
                }
            });
        };
        vm.buscaCategorias();

        vm.selecionarCateg = function(dados) {
            /*if (dados == 'todos') {
                vm.categoria = "todos";
            } else {
                vm.categoria = dados;
            }*/
            //vm.categoriaSelecionado = btoa(vm.categoria);
            vm.categoria = dados;
            vm.categoriaSelecionado = dados;
            vm.TodosNenhum();
        };

        vm.selecionarNeg = function(dados) {
            /*if (dados == 'todos') {
                vm.categoria = "todos";
            } else {
                vm.categoria = dados;
            }*/
            //vm.categoriaSelecionado = btoa(vm.categoria);
            vm.negSel = dados;
            vm.negSelSelecionado = dados;
            vm.TodosNenhum();
        };

        vm.desabilitarCargo = false;
        vm.selecionarTipo = function(dados) {
            vm.tipoSelecionado = dados;
            vm.TodosNenhum(null, 'tipo');
        };
        vm.selecionarTipo('todos');

        vm.buscaCidades = function() {
            vm.cidadesRepeat = [];
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                PessoasService.findCidadesTodos(vm.saasid).then(function(response) {
                    if (response.data != '0') {
                        angular.forEach(response.data, function(value, key) {
                            vm.cidadesRepeat.push({ 'name': value.endcli_cidade, 'ticked': true, 'id': '' });
                        });
                        vm.cidadesRepeat.push({ 'icon': '<i class=\'fa fa-times\'></i>', 'name': 'Nenhuma cidade', 'ticked': true, 'id': 'nenhum' });
                    } else {
                        vm.cidadesRepeat = [];
                    }
                    vm.quantCidades = vm.cidadesRepeat.length;
                    vm.selecionadosInicioCid = [];
                    angular.forEach(vm.cidadesRepeat, function(value, key) {
                        vm.selecionadosInicioCid.push(value.id);
                        vm.cidadeSelecionado = vm.selecionadosInicioCid.join();
                    });
                    vm.TodosNenhum(vm.cidadeSelecionado, 'cidades');
                }, function(error) {
                    console.error(error);
                });
            });
        };
        vm.buscaCidades();

        vm.buscaEstados = function() {
            vm.estadosRepeat = [];
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                PessoasService.findEstadosTodos(vm.saasid).then(function(response) {
                    if (response.data != '0') {
                        angular.forEach(response.data, function(value, key) {
                            vm.estadosRepeat.push({ 'name': value.endcli_estado, 'ticked': true, 'id': '' });
                        });
                        vm.estadosRepeat.push({ 'icon': '<i class=\'fa fa-times\'></i>', 'name': 'Nenhum estado', 'ticked': true, 'id': '' });
                    } else {
                        vm.estadosRepeat = [];
                    }
                    vm.quantEstados = vm.estadosRepeat.length;
                    vm.selecionadosInicioE = [];
                    angular.forEach(vm.estadosRepeat, function(value, key) {
                        vm.selecionadosInicioE.push(value.id);
                        vm.estadoSelecionado = vm.selecionadosInicioE.join();
                    });
                    vm.TodosNenhum(vm.estadoSelecionado, 'estados');
                }, function(error) {
                    console.error(error);
                });
            });
        };
        vm.buscaEstados();


        vm.buscaPais = function() {
            vm.paisRepeat = [];
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                PessoasService.findPaisTodos(vm.saasid).then(function(response) {
                    if (response.data != '0') {
                        angular.forEach(response.data, function(value, key) {
                            vm.paisRepeat.push({ 'name': value.endcli_pais, 'ticked': true, 'id': '' });
                        });
                        vm.paisRepeat.push({ 'icon': '<i class=\'fa fa-times\'></i>', 'name': 'Nenhum pais', 'ticked': true, 'id': '' });
                    } else {
                        vm.paisRepeat = [];
                    }
                    vm.quantPais = vm.paisRepeat.length;
                    vm.selecionadosInicioPais = [];
                    angular.forEach(vm.paisRepeat, function(value, key) {
                        vm.selecionadosInicioPais.push(value.id);
                        vm.paisSelecionado = vm.selecionadosInicioPais.join();
                    });
                    vm.TodosNenhum(vm.paisSelecionado, 'pais');
                }, function(error) {
                    console.error(error);
                });
            });
        };
        vm.buscaPais();

        vm.buscaTags = function() {
            vm.tagsRepeat = [];
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                PessoasService.findTagsRel(vm.saasid).then(function(response) {
                    if (FactoryRoles.checkRolesVisuFUNIL('isModConfig')) {
                        if (response.data != '0') {
                            angular.forEach(response.data, function(value, key) {
                                vm.tagsRepeat.push({ 'name': value.tag_nome, 'ticked': true, 'id': value.tag_id });
                            });
                            vm.tagsRepeat.push({ 'icon': '<i class=\'fa fa-times\'></i>', 'name': 'Nenhuma tag', 'ticked': true, 'id': 'nenhum' });
                        } else {
                            vm.tagsRepeat = [];
                        }
                        vm.quantTags = vm.tagsRepeat.length;
                        vm.selecionadosInicioT = [];
                        angular.forEach(vm.tagsRepeat, function(value, key) {
                            vm.selecionadosInicioT.push(value.id);
                            vm.tagSelecionado = vm.selecionadosInicioT.join();
                        });
                        vm.TodosNenhum(vm.tagSelecionado, 'tags');
                    } else {
                        vm.tagsRepeat = [];
                        vm.quantTags = vm.tagsRepeat.length;
                        vm.selecionadosInicioT = [];
                        angular.forEach(vm.tagsRepeat, function(value, key) {
                            vm.selecionadosInicioT.push(value.id);
                            vm.tagSelecionado = vm.selecionadosInicioT.join();
                        });
                        vm.TodosNenhum('nenhum', 'tags');
                        vm.desabilitarTag = true;
                    }
                }, function(error) {
                    console.error(error);
                });
            });
        };
        vm.buscaTags();

        /* CODIFICAR E DECODIFICAR STRING --- INÍCIO*/
        // Define the string
        /*        var string = 'Hello World!';

                // Encode the String
                var encodedString = btoa(string);
                console.log(encodedString); // Outputs: "SGVsbG8gV29ybGQh"

                // Decode the String
                var decodedString = atob(encodedString);
                console.log(decodedString); // Outputs: "Hello World!"*/
        /* CODIFICAR E DECODIFICAR STRING --- FIM*/

        // Create Base64 Object

        // Define the string
        /*var string = 'Hello World!';

        // Encode the String
        var encodedString = Base64.encode(string);
        console.log(encodedString); // Outputs: "SGVsbG8gV29ybGQh"

        // Decode the String
        var decodedString = Base64.decode(encodedString);
        console.log(decodedString); // Outputs: "Hello World!"*/

        /*PESQUISA AVANÇADA --- FIM*/



        /*PRODUTOS --- INÍCIO*/


        vm.buscaProdutos = function() {
            // aquiiii
            // console.log($routeParams.qual_filtro == 'produtos-new')
            console.log('$routeParams.qual_filtro')
            console.log($routeParams.qual_filtro)
            vm.produtosRepeat = [];
            // var filtro = $routeParams.qual_filtro == 'produtos-new' ? 'new' : '';  
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid
                };
                if ($routeParams.qual_filtro == 'produtos') dados.old = true;
                produtosService.findAll(dados).then(function(response) {
                    if (response.data != '0') {
                        angular.forEach(response.data, function(value, key) {
                            vm.produtosRepeat.push({ 'name': value.prod_nome, 'ticked': true, 'id': value.prod_id, 'slug': value.prod_slug });
                        });
                    } else {
                        vm.produtosRepeat = [];
                    }
                    vm.quantProdutos = vm.produtosRepeat.length;
                    vm.selecionadosInicioProd = [];
                    angular.forEach(vm.produtosRepeat, function(value, key) {
                        vm.selecionadosInicioProd.push(value.slug);
                        vm.produtoSelecionado = vm.selecionadosInicioProd.join();
                    });
                    vm.todosNenhumProd(vm.produtoSelecionado, 'produtos');
                }, function(error) {
                    console.error(error);
                });
            });
        };
        vm.buscaProdutos();


        vm.ptbrProd = {
            selectAll: 'Todos',
            selectNone: 'Limpar',
            search: 'Pesquisar...',
            nothingSelected: 'Selecionar Produtos'
        };

        vm.selectedProdutos = [];

        vm.situacoesProd = [
            { name: 'Ganhas', selecionado: true },
            { name: 'Perdidas', selecionado: true },
            { name: 'Abertas', selecionado: true },
        ];

        vm.desabilitarProd = true;
        vm.selecionarTodasProd = true;

        vm.mudarProd = function(qual, item) {
            if (qual) {
                vm.desabilitarProd = true;
                vm.situacoesSelecionadasProd = function() {
                    return 'Ganhas-Perdidas-Abertas';
                };
                //vm.situacoesSelecionadasProd = 'Ganhas-Perdidas-Abertas';
                angular.forEach(vm.situacoesProd, function(item) {
                    item.selecionado = true;
                });
            } else {
                vm.situacoesSelecionadasProd = function() {
                    return vm.situacoesProd.filter(function(item) {
                        return item.selecionado;
                    }).map(function(item) {
                        return item.name;
                    }).join('-');
                };
                angular.forEach(vm.situacoesProd, function(item) {
                    item.selecionado = false;
                });
                vm.desabilitarProd = false;
            }
        };

        vm.situacoesSelecionadasProd = function() {
            return vm.situacoesProd.filter(function(item) {
                return item.selecionado;
            }).map(function(item) {
                return item.name;
            }).join('-');
        };

        /*PRODUTOS --- FIM*/


        // COMBOBOX VISUALIZACAO --- INICIO
        vm.visualizando = 'todos';

        vm.buscaGrupos = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                };
                usuariosService.findGrupo(dados)
                    .then(function(response) {
                        $timeout(function() {
                            if (response.data != 0) {
                                vm.grupolista = response.data;
                                if (vm.usuarioLogado.fk_perfil_id != 1 && vm.usuarioLogado.fk_perfil_id != 3 && vm.roleVisualiza != 1) {
                                    vm.grupolista = $filter('filter')(vm.grupolista, { gru_id: vm.usuarioLogado.fk_gru_id });
                                }
                            } else {
                                vm.grupolista = '';
                            };
                            $timeout(function() {
                                //$('.select_visu').selectpicker();
                                $('.select_visu').selectpicker('refresh');
                                $timeout(function() {
                                    vm.mostrarCombo = true;
                                });
                            }, 100);
                        });
                    }, function(error) {
                        console.error(error);
                    });
            });
        };

        vm.buscaRepresentantes = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                };
                usuariosService.findRepresentantes(dados)
                    .then(function(response) {
                        if (response.data != 0) {
                            vm.representantelista = response.data;
                        } else {
                            vm.representantelista = '';
                        };
                        $timeout(function() {
                            $('.select_visu').selectpicker();
                            $('.select_visu').selectpicker('refresh');
                            $timeout(function() {
                                vm.mostrarCombo = true;
                            });
                        }, 200);
                    }, function(error) {
                        console.error(error);
                    });
            });
        };

        vm.buscarUsuarioLogado = function(mod) {
            var dados = {
                'hash': Cookies.get('crm_funil_hash')
            }
            compromissosService.buscarUsuarioEspecifico(dados).then(function(response) {
                vm.usuarioLogado = response.data[0];
                vm.verifDadosUser(mod);
            }, function(error) {
                console.error(error);
            });
        }

        vm.mostrarCombo = false;
        vm.mostrarVisuGeral = true;
        vm.mostrarUsuario = true;
        vm.naoMostrar = false;
        vm.visuGeral = [];
        vm.verifDadosUser = function(mod) {
            var local = window.localStorage.getItem('5c479de2');
            var valLocal = JSON.parse(API.funilRC.decrypt(local));
            var localNeg = window.localStorage.getItem(mod);
            var valLocalNeg = JSON.parse(API.funilRC.decrypt(localNeg));

            if (valLocalNeg.visualiza) {
                vm.roleVisualiza = parseInt(valLocalNeg.visualiza);
                switch (parseInt(valLocalNeg.visualiza)) {
                    case 4:
                        $timeout(function() {
                            vm.mostrarCombo = true;
                            vm.mostrarUsuario = false;
                            vm.carregando = false;
                            vm.naoMostrar = true;
                            vm.visualizando = '';
                            vm.mostrarVisuGeral = false;
                        });
                        break;
                    case 3:
                        $timeout(function() {
                            vm.mostrarCombo = true;
                            vm.mostrarVisuGeral = false;
                            vm.mostrarUsuario = true;
                            vm.visualizando = "user_" + vm.usuarioLogado.user_id;
                            $timeout(function() {
                                vm.enviarVisualizando = Base64.encode(vm.visualizando);
                            });
                            $timeout(function() {
                                $('.select_visu').selectpicker();
                                $('.select_visu').selectpicker('refresh');
                            });
                        });
                        break;
                    case 2:
                        $timeout(function() {
                            vm.buscaGrupos();
                            vm.mostrarVisuGeral = false;
                            if (vm.usuarioLogado.fk_perfil_id == 4 || vm.usuarioLogado.fk_perfil_id == 5) {
                                vm.mostrarVisuGeral = false;
                                vm.mostrarUsuario = true;
                            }
                            vm.visualizando = "grupo_" + vm.usuarioLogado.fk_gru_id;
                            $timeout(function() {
                                vm.enviarVisualizando = Base64.encode(vm.visualizando);
                            });
                            $timeout(function() {
                                //$('.select_visu').selectpicker();
                                //$('.select_visu').selectpicker('refresh');
                            });
                        });
                        break;
                    default:
                        $timeout(function() {
                            vm.buscaGrupos();
                            vm.buscaRepresentantes();
                            vm.visualizando = "todos";
                            vm.mostrarVisuGeral = true;
                            vm.visuGeral.push({ 'nome': 'Visualização Geral' });
                            vm.mostrarUsuario = true;
                            $timeout(function() {
                                vm.enviarVisualizando = Base64.encode(vm.visualizando);
                            });
                            $timeout(function() {
                                $('.select_visu').selectpicker();
                                $('.select_visu').selectpicker('refresh');
                            });
                        });
                }
            }
        }

        vm.mudarVisualizacao = function(visu) {
            $timeout(function() {
                vm.enviarVisualizando = Base64.encode(vm.visualizando);
            });
        }

        vm.carregando = true;

        // COMBOBOX VISUALIZACAO --- FIM


        /*TIPOS DE COMPROMISSO --- INÍCIO*/
        vm.buscarTiposCompromisso = function(slug_comp) {
                vm.tiposCompromissoRepeat = [];
                compromissosService.buscarTiposCompromisso().then(function(response) {
                    if (response.data != '0') {
                        angular.forEach(response.data, function(value, key) {
                            var icon = '<img src=\'' + API.url + '/app/template/img/icones/' + value.cot_imagem_nome + '\'/>';
                            vm.tiposCompromissoRepeat.push({ 'icon': icon, 'name': value.cot_nome, 'ticked': true, 'id': value.cot_id });
                        });
                        vm.quantTipoComp = vm.tiposCompromissoRepeat.length;
                        vm.selecionadosInicioTipoComp = [];
                        angular.forEach(vm.tiposCompromissoRepeat, function(value, key) {
                            vm.selecionadosInicioTipoComp.push(value.id);
                            vm.tipocompSelecionado = vm.selecionadosInicioTipoComp.join();
                        });
                        vm.todosNenhumTipoComp(vm.tipocompSelecionado, 'tipoComp');
                    } else {
                        vm.tiposCompromissoRepeat = [];
                    }
                }, function(error) {
                    $log.error(error);
                });
            }
            /*TIPOS DE COMPROMISSO --- FIM*/

        /*ORIGEM --- INÍCIO*/
        vm.buscarOrigens = function(slug_comp) {
            vm.origensRepeat = [];
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'user_hash': Cookies.get('crm_funil_hash')
                };
                origemContatoService.findAll(dados).then(function(response) {
                    if (response.data != '0') {
                        if (vm.relatorio == 'produtos' || vm.relatorio == 'produtos-new' || vm.relatorio == 'odontotop') {
                            vm.origens = response.data;
                            vm.selecionarOrigem('todos');
                        }
                        angular.forEach(response.data, function(value, key) {
                            vm.origensRepeat.push({ 'name': value.orc_nome, 'ticked': true, 'id': value.orc_id });
                        });
                        //vm.origensRepeat.push({ 'icon': '<i class=\'fa fa-times\'></i>', 'name': 'Nenhuma origem', 'ticked': true, 'id': 'nenhum' });
                        vm.quantOrigem = vm.origensRepeat.length;
                        vm.selecionadosInicioOrigem = [];
                        angular.forEach(vm.origensRepeat, function(value, key) {
                            vm.selecionadosInicioOrigem.push(value.id);
                            vm.origemSelecionado = vm.selecionadosInicioOrigem.join();
                        });
                        vm.todosNenhumOrigem(vm.origemSelecionado, 'origem');
                    } else {
                        vm.origensRepeat = [];
                        if (vm.relatorio == 'produtos' || vm.relatorio == 'produtos-new' || vm.relatorio == 'odontotop') {
                            vm.origens = [];
                            vm.selecionarOrigem('nenhum');
                        }
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        }

        vm.selecionarOrigem = function(dados) {
            vm.origemContato = dados;
            vm.origemSelecionada = dados;
            //vm.TodosNenhum();
        };
        /*ORIGEM --- FIM*/

        // TOTAL VOICE --- INICIO
        vm.buscarIntegracaoTotalVoice = function() {
            vm.carregandoTotalVoice = true;
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid
                };
                ligacaoService.buscarIntegracaoTotalVoice(dados).then(function(response) {
                    /*console.log("buscarIntegracaoTotalVoice");
                    console.log(response.data);*/
                    if (response.data != '') { // EXISTE INTEGRAÇÃO
                        $timeout(function() {
                            vm.totalVoice = true;
                        });
                    } else { // NÃO EXISTE INTEGRAÇÃO
                        $timeout(function() {
                            vm.totalVoice = false;
                        });
                    }
                    $timeout(function() {
                        vm.carregandoTotalVoice = false;
                    });
                }, function(error) {
                    console.error(error);
                });
            });
        };
        vm.buscarIntegracaoTotalVoice();

        /*METAS --- INÍCIO*/
        vm.selecionarTipoMeta = function(meta) {
            if (meta == 'N' || meta == 'AO'){
                $timeout(function() {
                    $('.campo_periodo').val('');
                    vm.periodo = '';
                    vm.periodo_inicio = '';
                    vm.periodo_fim = '';
                });
                // vm.buscarMesesMetaNeg();
                $('.from').datepicker({
                    autoclose: true,
                    minViewMode: 1,
                    format: 'mm/yyyy'
                }).on('changeDate', function(selected){
                    vm.mesMetaSelecionado = vm.mes_meta.replace("/", "-");
                });
            } else if (meta == 'L'){
                vm.mesMetaSelecionado = '';
                $timeout(function() {
                    $('.campo_periodo').val('');
                    vm.periodo = '';
                    vm.periodo_inicio = '';
                    vm.periodo_fim = '';
                });
            }
        }

        vm.buscarMesesMetaNeg = function(){
            vm.carregandoMesesMeta = true;
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                  'saasid': vm.saasid
                };
                metasService.buscarMesesMetaNeg(dados).then(function(response) {
                  console.log("buscarMesesMetaNeg");
                  console.log(response.data);
                  //return null;
                  if (response.data != ''){
                      $timeout(function() {
                          vm.mesesMetaNeg = response.data;
                      });
                  } else {
                      $timeout(function() {
                          vm.mesesMetaNeg = '';
                      });
                  }
                  $timeout(function() {
                      vm.carregandoMesesMeta = false;
                  });
                }, function(error) {
                    console.error(error);
                });
            });
        };

        vm.selecionarMesMeta = function(mes){
            vm.mesMetaSelecionado = mes.replace("/", "-");
        }
        /*METAS --- FIM*/

        // ODONTO TOP --- INÍCIO

        vm.filtro_data_odonto_top = 'entrada';
        vm.buscaUnidadesOdontoTop = function(name) {
            vm.unidades_odontotop = [];
            vm.unidade_odontotop = 'todas';
            PessoasService.findUnidadesOdontoTop().then(function(response) {
                // console.log("buscaUnidadesOdontoTop");
                // console.log(response.data);
                vm.unidades_odontotop = response.data;
                $timeout(function() {
                    $("#spinner_unidades_odontotop").hide();
                });
            }, function(error) {
                $log.error(error);
            });
        };

        vm.list_status_odontotop = [];
        vm.status_atd_odontotop = 'todos';
        vm.buscaStatusAtdOdontoTop = function(name) {
            PessoasService.findStatusAtdOdontoTop().then(function(response) {
                // console.log("buscaStatusAtdOdontoTop");
                // console.log(response.data);
                vm.list_status_odontotop = response.data;
                $timeout(function() {
                    $("#spinner_status_odontotop").hide();
                });
            }, function(error) {
                $log.error(error);
            });
        };

        // ODONTO TOP --- FIM

        // TURMAS VENDAS COMPLEXAS - A9 --- INÍCIO
        vm.turma_vc = 'todas';
        vm.buscaTurmasVC = function () {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function (items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                };
                turmasVCService.findAll(dados).then(function (response) {
                    if (response.data != 0) {
                        $timeout(function () {
                            vm.turmasVCRepeat = response.data;
                        });
                    } else {
                        $timeout(function () {
                            vm.turmasVCRepeat = '';
                        });
                    }
                }, function (error) {
                    $log.error(error);
                });
            });
        };
        // TURMAS VENDAS COMPLEXAS - A9 --- FIM

    }



})();