/*
* @Author: Glauber Funez
* @Date:   2018-03-19 09:40:13
* @Last Modified by:   Glauber Funez
* @Last Modified time: 2018-08-21 09:39:53
*/

(function() {
  'use strict';

  angular
    .module('relatorios')
    .controller('gerarRelProdutosNewController', gerarRelProdutosNewController)


  gerarRelProdutosNewController.$inject = ['$routeParams', 'mensagem', '$location', 'relatoriosService', '$http', 'API', '$filter', '$timeout', 'loadSaas', 'validarSaas', 'produtosService', 'compromissosService', 'FactoryRoles', 'usuariosService', 'origemContatoService', '$window'];

  function gerarRelProdutosNewController($routeParams, mensagem, $location, relatoriosService, $http, API, $filter, $timeout, loadSaas, validarSaas, produtosService, compromissosService, FactoryRoles, usuariosService, origemContatoService, $window) {
        var Base64 = {
          _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+=',
          encode: function(e) {
            var t = '';
            var n, r, i, s, o, u, a;
            var f = 0;
            e = Base64._utf8_encode(e);
            while (f < e.length) {
              n = e.charCodeAt(f++);
              r = e.charCodeAt(f++);
              i = e.charCodeAt(f++);
              s = n >> 2;
              o = (n & 3) << 4 | r >> 4;
              u = (r & 15) << 2 | i >> 6;
              a = i & 63;
              if (isNaN(r)) { u = a = 64; } else if (isNaN(i)) { a = 64; }
              t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a);
            }
            return t;
          },
          decode: function(e) {
            var t = '';
            var n, r, i;
            var s, o, u, a;
            var f = 0;
            e = e.replace(/[^A-Za-z0-9+/=]/g, '');
            while (f < e.length) {
              s = this._keyStr.indexOf(e.charAt(f++));
              o = this._keyStr.indexOf(e.charAt(f++));
              u = this._keyStr.indexOf(e.charAt(f++));
              a = this._keyStr.indexOf(e.charAt(f++));
              n = s << 2 | o >> 4;
              r = (o & 15) << 4 | u >> 2;
              i = (u & 3) << 6 | a;
              t = t + String.fromCharCode(n);
              if (u != 64) { t = t + String.fromCharCode(r); }
              if (a != 64) { t = t + String.fromCharCode(i); }
            }
            t = Base64._utf8_decode(t);
            return t;
          },
          _utf8_encode: function(e) {
            e = e.replace(/rn/g, 'n');
            var t = '';
            for (var n = 0; n < e.length; n++) {
              var r = e.charCodeAt(n);
              if (r < 128) { t += String.fromCharCode(r); } else if (r > 127 && r < 2048) {
                t += String.fromCharCode(r >> 6 | 192);
                t += String.fromCharCode(r & 63 | 128);
              } else {
                t += String.fromCharCode(r >> 12 | 224);
                t += String.fromCharCode(r >> 6 & 63 | 128);
                t += String.fromCharCode(r & 63 | 128);
              }
            }
            return t;
          },
          _utf8_decode: function(e) {
            var t = '';
            var n = 0;
            var c1 = 0;
            var c2 = 0;
            var r = c1 = c2 = 0;
            while (n < e.length) {
              r = e.charCodeAt(n);
              if (r < 128) {
                t += String.fromCharCode(r);
                n++;
              } else if (r > 191 && r < 224) {
                c2 = e.charCodeAt(n + 1);
                t += String.fromCharCode((r & 31) << 6 | c2 & 63);
                n += 2;
              } else {
                c2 = e.charCodeAt(n + 1);
                c3 = e.charCodeAt(n + 2);
                t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63);
                n += 3;
              }
            }
            return t;
          }
        };
        var vm = this;

        vm.url = API.url;

        vm.aplicarScroll = function() {
          $('.checkBoxContainer').niceScroll({ horizrailenabled: false });
        };

        vm.tituloPrincipal = 'Relatório de produtos CMS';
        vm.subtitulo = 'Relatório de produtos CMS';
        vm.carregando = true;

        vm.filtros = {
          'saasid': '',
          'produtos': [],
          'situacoes': [],
          'inicio': '',
          'fim': ''
        };

        vm.carregarDados = function() {
          vm.filtros.saasid = vm.saasid;
          var dados = {
            'filtros': vm.filtros
          };
          dados.qual_visu = $routeParams.visualizar;

          dados.origens = $routeParams.origens;
          vm.origemSelecionada = $routeParams.origens;

          if (FactoryRoles.checkRolesVisuFUNIL('isModNegociacao')) {
            relatoriosService.carregarDadosProdutosNew(dados).then(function(response) {
              // aquiiiii
              // console.log("carregarDadosProdutos");
              // console.log(response.data);
              if (response.data != '' && response.data != 0) {
                vm.listagemProdutos = response.data.dados;

                vm.quantAbertas = (response.data.totais.quantAbertas);
                vm.vlAbertas = (response.data.totais.vlAbertas);
                vm.quantPerdidas = (response.data.totais.quantPerdidas);
                vm.vlPerdidas = (response.data.totais.vlPerdidas);
                vm.quantGanhas = (response.data.totais.quantGanhas);
                vm.vlGanhas = (response.data.totais.vlGanhas);

              } else {
                vm.listagemProdutos = '';
              }
              vm.buscarDadosProdutos();
            }, function(error) {
              console.error(error);
            });
          } else {
            vm.carregando = false;
            vm.naoMostrar = true;
          }
        };

        vm.buscaProdutos = function() {
          vm.produtosRepeat = [];
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) { // ler array de retorno
            vm.saasid = items;
            var dados = {
              'saasid': vm.saasid
            };
            produtosService.findAll(dados).then(function(response) {
              if (response.data != '0') {
                angular.forEach(response.data, function(value, key) {
                  vm.produtosRepeat.push({ 'name': value.prod_nome, 'ticked': true, 'id': value.prod_id, 'slug': value.prod_slug });
                  if (vm.arrayProdutos) {
                    angular.forEach(vm.arrayProdutos, function(value2, key2) {
                      if (value2 == value.prod_slug) {
                        vm.produtosExibir.push(value);
                      }
                    });
                  }
                });
                vm.carregarDados();
              } else {
                vm.produtosRepeat = [];
              }
              vm.quantProdutos = vm.produtosRepeat.length;
              vm.selecionadosInicioProd = [];
              angular.forEach(vm.produtosRepeat, function(value, key) {
                vm.selecionadosInicioProd.push(value.slug);
                vm.produtoSelecionado = vm.selecionadosInicioProd.join();
              });
              vm.todosNenhumProd(vm.produtoSelecionado, 'produtos');

            }, function(error) {
              console.error(error);
            });
          });
        };
        vm.produtosExibir = [];

        vm.buscaOrigens = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'user_hash': Cookies.get('crm_funil_hash')
                };
                origemContatoService.findAll(dados).then(function(response) {
                    vm.origens = response.data;
                    vm.selecionarOrigem('todos');
                }, function(error) {
                    console.error(error);
                });
            });
        };
        vm.buscaOrigens();

        vm.selecionarOrigem = function(dados) {
            vm.origemContato = dados;
            vm.origemSelecionada = dados;
            //vm.TodosNenhum();
        };

        vm.mostrarFiltroVisualizar = function(visu) {
          var qual_visu = Base64.decode(visu);
          if (!qual_visu.match(/todos/)) {
            if (qual_visu.match(/user/)) {
              var start = qual_visu.indexOf("_") + 1;
              var end = qual_visu.length;
              var user = qual_visu.substr(start, end);
              var dados = {
                'user_id': user
              }
              usuariosService.buscaUsuarioID(dados).then(function(response) {
                vm.filtroVisualizar = "Usuário - " + response.data[0].user_nome;
              }, function(error) {
                console.log('Erro: ', error);
              });
            } else {
              var start = qual_visu.indexOf("_") + 1;
              var end = qual_visu.length;
              var grupo = qual_visu.substr(start, end);
              var dados = {
                'gru_id': grupo
              }
              usuariosService.buscaGrupoID(dados).then(function(response) {
                vm.filtroVisualizar = "Grupo - " + response.data[0].gru_nome;
              }, function(error) {
                console.log('Erro: ', error);
              });
            }
          } else {
            vm.filtroVisualizar = 'Todos';
          }
        }

        if ($routeParams) {
          if ($routeParams.produtos) {
            var produtos = $routeParams.produtos;
            if (produtos != 'todos') {
              vm.filtros.produtos = produtos.split(',');
              vm.arrayProdutos = produtos.split(',');
            } else {
              vm.produtosExibir = 'todos';
              vm.filtros.produtos = 'todos';
            }
            vm.buscaProdutos();
          }
          if ($routeParams.situacoes) {
            var situacoes = $routeParams.situacoes;
            if (situacoes != 'todos') {
              vm.filtros.situacoes = situacoes.split('-');
            } else {
              vm.filtros.situacoes = 'todos';
            }

            angular.forEach(vm.filtros.situacoes, function(value) {
              if (value.match(/Abertas/)) {
                vm.abertasExibir = value;
              } else if (value == 'Perdidas') {
                vm.perdidasExibir = value;
              } else if (value == 'Ganhas') {
                vm.ganhasExibir = value;
              } else if (value == 'Ganhas, Perdidas, Abertas') {
                vm.todasExibir = 'Ganhas, Perdidas, Abertas';
              }
            });
          }
          if ($routeParams.inicio) { // PERIODO SELECIONADO
            vm.filtros.inicio = $routeParams.inicio;
            vm.filtros.fim = $routeParams.fim;
            vm.inicioExibir = vm.filtros.inicio.replace('_', '/').replace('_', '/');
            vm.fimExibir = vm.filtros.fim.replace('_', '/').replace('_', '/');
          }
          vm.mostrarFiltroVisualizar($routeParams.visualizar);

          //vm.buscarTotaisCards();
        }

        vm.ptbrProd = {
          selectAll: 'Todos',
          selectNone: 'Limpar',
          search: 'Pesquisar...',
          nothingSelected: 'Selecionar Produtos'
        };

        vm.selectedProdutos = [];

        vm.situacoesProd = [
          { name: 'Ganhas', selecionado: true },
          { name: 'Perdidas', selecionado: true },
          { name: 'Abertas', selecionado: true },
        ];

        vm.desabilitarProd = true;
        vm.selecionarTodasProd = true;
        vm.periodo_inicio = '';

        vm.mudarProd = function(qual, item) {
          if (qual) {
            vm.desabilitarProd = true;
            vm.situacoesSelecionadasProd = function() {
              return 'Ganhas-Perdidas-Abertas';
            };
            //vm.situacoesSelecionadasProd = 'Ganhas-Perdidas-Abertas';
            angular.forEach(vm.situacoesProd, function(item) {
              item.selecionado = true;
            });
          } else {
            vm.situacoesSelecionadasProd = function() {
              return vm.situacoesProd.filter(function(item) {
                return item.selecionado;
              }).map(function(item) {
                return item.name;
              }).join('-');
            };
            angular.forEach(vm.situacoesProd, function(item) {
              item.selecionado = false;
            });
            vm.desabilitarProd = false;
          }
        };

        vm.situacoesSelecionadasProd = function() {
          return vm.situacoesProd.filter(function(item) {
            return item.selecionado;
          }).map(function(item) {
            return item.name;
          }).join('-');
        };


        vm.acabouNeg = function() {
          $timeout(function() {
            $('[data-toggle="tooltip"]').tooltip();
            vm.carregando = false;
          });
        };

        vm.acc_produtos = function(prod_id) {
          $timeout(function() {
            $('.box_dados_neg_' + prod_id).slideToggle();
            $('.seta_acc_produto_' + prod_id).toggleClass('seta_ativa');
          });
        };

        vm.nomeColuna = 'quant';
        vm.nomeColuna2 = 'total_prod';
        vm.nomeColuna3 = 'prod_nome';
        vm.reverse = true;
        vm.trocarOrdem = function() {
          if (vm.toggle_produtos) {
            $('.box_toggle').toggleClass('toggle_ativo');
            $('.box_quant').toggleClass('toggle_ativo');
            vm.nomeColuna = 'total_prod';
            vm.nomeColuna2 = 'quant';
            vm.reverse = true;
          } else {
            $('.box_toggle').toggleClass('toggle_ativo');
            $('.box_quant').toggleClass('toggle_ativo');
            vm.nomeColuna = 'quant';
            vm.nomeColuna2 = 'total_prod';
            vm.reverse = true;
          }
        };

        vm.mudarToggle = function() {
          vm.toggle_produtos = !vm.toggle_produtos;
          vm.trocarOrdem();
        };

        vm.expandirAcc = function() {
          $('.box_filtrar_produtos').slideToggle();
        };

        vm.rangePeriodo = function() {
          function cb(start, end) {
            if (start._isValid) { // datas validas
              vm.periodo_inicio = start.format('DD_MM_YYYY');
              vm.periodo_fim = end.format('DD_MM_YYYY');;
            } else {

            }
          }
          $('.campo_periodo').daterangepicker({
            //autoUpdateInput: false,
            'opens': 'center',
            'locale': {
              'format': 'DD/MM/YYYY',
              'cancelLabel': 'Limpar'
            },
            ranges: {
              'Hoje': [moment(), moment()],
              'Ontem': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Últ. 7 dias': [moment().subtract(6, 'days'), moment()],
              'Últ. 30 dias': [moment().subtract(29, 'days'), moment()],
              'Este mês': [moment().startOf('month'), moment().endOf('month')],
              'Mês passado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
          });
          //}, cb);
          $('.campo_periodo').on('apply.daterangepicker', function(ev, picker) {
            $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
            vm.periodo_inicio = picker.startDate.format('DD_MM_YYYY');
            vm.periodo_fim = picker.endDate.format('DD_MM_YYYY');
          });

          $('.campo_periodo').on('cancel.daterangepicker', function(ev, picker) {
            $(this).val('');
            vm.periodo = '';
            vm.periodo_inicio = '';
            vm.periodo_fim = '';
          });
        };


        vm.fSelectAll = function(qual) {
          if (qual == 'produtos') {
            vm.novoTodosProd = [];
            angular.forEach(vm.produtosRepeat, function(value, key) {
              vm.novoTodosProd.push(value.slug);
              vm.produtoSelecionado = vm.novoTodosProd.join();
            });
            vm.todosNenhumProd(vm.produtoSelecionado, 'produtos');
          }
        };

        vm.selectedCargos = [];
        vm.fClick = function(data, qual) {
          if (qual == 'produtos') {
            vm.produtoSelecionado = [];
            vm.novoTodosProd = [];
            angular.forEach(vm.selectedProdutos, function(value, key) {
              vm.novoTodosProd.push(value.slug);
              vm.produtoSelecionado = vm.novoTodosProd.join();
            });
            vm.todosNenhumProd(vm.produtoSelecionado, 'produtos');
          }
        };

        vm.fSelectNone = function(qual) {
          if (qual == 'produtos') {
            vm.novoTodosProd = [];
            angular.forEach(vm.produtosRepeat, function(value, key) {
              vm.novoTodosProd.splice(key);
              vm.produtoSelecionado = vm.novoTodosProd.join();
            });
            vm.todosNenhumProd(vm.produtoSelecionado, 'produtos');
          }
        };

        vm.todosNenhumProd = function(array, qual) {
          if (array) {
            if (array != '') {
              var array = array.split(',');
            } else {
              var array = '';
            }
          }
          if (array.length == vm.quantProdutos) {
            vm.produtoSelecionado = 'todos';
          }

          vm.urlEnviarProd = vm.saasidSelecionado + '/' + vm.produtoSelecionado;
          //vm.urlEnviarProd = Base64.encode(vm.urlEnviarProd);
        };

        function number_format(numero, decimal, decimal_separador, milhar_separador) {
          numero = (numero + '').replace(/[^0-9+\-Ee.]/g, '');
          var n = !isFinite(+numero) ? 0 : +numero,
            prec = !isFinite(+decimal) ? 0 : Math.abs(decimal),
            sep = (typeof milhar_separador === 'undefined') ? '.' : milhar_separador,
            dec = (typeof decimal_separador === 'undefined') ? ',' : decimal_separador,
            s = '',
            toFixedFix = function(n, prec) {
              var k = Math.pow(10, prec);
              return '' + Math.round(n * k) / k;
            };
          // Fix para IE: parseFloat(0.55).toFixed(0) = 0;
          s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
          if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
          }
          if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
          }

          return s.join(dec);
        }

        vm.buscarDadosProdutos = function() {
          var randomColorFactor = function() {
            return Math.round(Math.random() * 255);
          };
          var randomColor = function(opacity) {
            return 'rgba(' + randomColorFactor() + ',' + randomColorFactor() + ',' + randomColorFactor() + ',' + (opacity || '.3') + ')';
          };
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) { // ler array de retorno
            vm.saasid = items;
            var dados = {
              'saasid': vm.saasid,
              'filtros': vm.filtros
            };
            dados.qual_visu = $routeParams.visualizar;

            dados.origens = $routeParams.origens;
            vm.origemSelecionada = $routeParams.origens;

            relatoriosService.carregarDadosProdutosGraficosNew(dados).then(function(response) {
              if (response.data.quant != '') {
                vm.listaProdutosQtd = response.data.quant;
              } else {
                vm.listaProdutosQtd = '';
                vm.loadGraficoProdutosQtd1 = false;
              }
              if (response.data.quant != '') {
                vm.listaProdutosVl = response.data.valores;
              } else {
                vm.listaProdutosVl = '';
                vm.loadGraficoProdutosVl1 = false;
              }
              var dados = {
                'labelsQtd': [],
                'labelsVl': [],
                'coresQtd': [],
                'coresVl': [],
                'dados_qtd': [],
                'dados_vl': []
              };
              var a = 0;
              angular.forEach(response.data.quant, function(value, key) {
                dados.labelsQtd.push(value.nome);
                dados.coresQtd.push(value.cor);
                dados.dados_qtd.push(value.quant);
                a++;
              });
              var a = 0;
              angular.forEach(response.data.valores, function(value, key) {
                dados.labelsVl.push(value.nome);
                dados.coresVl.push(value.cor);
                dados.dados_vl.push(value.total);
                a++;
              });
              $timeout(function() {
                vm.graficod3pie(dados);
              }, 10);
            }, function(error) {
              console.error(error);
            });
          });
        };

        vm.graficod3pie = function(dados) {
          var preencher1 = [];
          var preencher2 = [];
          angular.forEach(dados.labelsQtd, function(value, key) {
            preencher1.push({ 'label': value, 'value': 0, 'color': '' });
          });
          angular.forEach(dados.dados_qtd, function(value, key) {
            preencher1[key].value = parseFloat(value);
          });
          angular.forEach(dados.coresQtd, function(value, key) {
            preencher1[key].color = value;
          });
          angular.forEach(dados.labelsVl, function(value, key) {
            preencher2.push({ 'label': value, 'value': 0 });
          });
          angular.forEach(dados.dados_vl, function(value, key) {
            preencher2[key].value = parseFloat(value);
          });
          angular.forEach(dados.coresVl, function(value, key) {
            preencher2[key].color = value;
          });

          var myLabelFormatter = function(context) {
            var label = context.label + ': ' + number_format(context.value);

            return label;
          };


          $timeout(function() {

            if (preencher1.length) {
              var pie = new d3pie('canvas_grafico_produtos_qtd', {
                'header': {
                  'title': {
                    'text': 'Quantidade',
                    'fontSize': 14,
                    'font': 'open sans'
                  },
                  'subtitle': {
                    'text': 'Quantidade de produtos relacionados em negociações.',
                    'color': '#999999',
                    'fontSize': 10,
                    'font': 'open sans'
                  },
                  'titleSubtitlePadding': 10
                },
                'size': {
                  'canvasWidth': 540,
                  'canvasHeight': 360,
                  'pieInnerRadius': '55%',
                  'pieOuterRadius': '70%'
                },
                'data': {
                  'sortOrder': 'value-desc',
                  'content': preencher1
                },
                'labels': {
                  //"formatter": myLabelFormatter,
                  'outer': {
                    'format': 'label',
                    'pieDistance': 20
                  },
                  'inner': {
                    'format': 'none'
                  },
                  'mainLabel': {
                    'fontSize': 11
                  },
                  'value': {
                    'color': '#999999',
                    'fontSize': 11
                  },
                  'lines': {
                    'enabled': true,
                    'color': '#777777'
                  },
                  'truncation': {
                    'enabled': true,
                    'truncateLength': 23
                  }
                },
                'effects': {
                  'pullOutSegmentOnClick': {
                    'effect': 'linear',
                    'speed': 400,
                    'size': 8
                  },
                  'highlightSegmentOnMouseover': false,
                  //"highlightLuminosity": -0.56
                },
                'misc': {
                  'colors': {
                    /*"segments": [
                        "#2484c1", "#65a620"
                    ],
                    "segmentStroke": "#000000"*/
                  }
                }
              });
            }

            var myLabelFormatter = function(context) {
              var label = context.label + ': ' + 'R$ ' + Number(context.value).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');

              // if it's a single bird seen, add an exclamation mark to the outer label
              if (context.section === 'outer') {
                if (context.value === 1) {
                  label = label + '!';
                }
              }
              return label;
            };

            if (preencher2.length) {
              var pie2 = new d3pie('canvas_grafico_produtos_vl', {
                'header': {
                  'title': {
                    'text': 'Valores',
                    'fontSize': 14,
                    'font': 'open sans'
                  },
                  'subtitle': {
                    'text': 'Valor total dos produtos relacionados em negociações.',
                    'color': '#999999',
                    'fontSize': 10,
                    'font': 'open sans'
                  },
                  'titleSubtitlePadding': 10
                },
                'size': {
                  'canvasWidth': 540,
                  'canvasHeight': 360,
                  'pieInnerRadius': '55%',
                  'pieOuterRadius': '70%'
                },
                'data': {
                  'sortOrder': 'value-desc',
                  'content': preencher2
                },
                'labels': {
                  //"formatter": myLabelFormatter,
                  'outer': {
                    'format': 'label',
                    'pieDistance': 20
                  },
                  'inner': {
                    'format': 'none'
                  },
                  'mainLabel': {
                    'fontSize': 11
                  },
                  'value': {
                    'color': '#999999',
                    'fontSize': 11
                  },
                  'lines': {
                    'enabled': true,
                    'color': '#777777'
                  },
                  'truncation': {
                    'enabled': true,
                    'truncateLength': 23
                  }
                },
                'effects': {
                  'pullOutSegmentOnClick': {
                    'effect': 'linear',
                    'speed': 400,
                    'size': 8
                  },
                  'highlightSegmentOnMouseover': false,
                  //"highlightLuminosity": -0.56
                },
                'misc': {
                  'colors': {
                    /*"segmentStroke": "#000000"*/
                  }
                }
              });
            }

          }, 100);

        };

        vm.imprimir = function() {
          window.print();
        };

        vm.imprimirGrafico = true;

        vm.toggleImprmirGrafico = function() {
          vm.imprimirGrafico = !vm.imprimirGrafico;
        };

        vm.url = API.url;

        // COMBOBOX VISUALIZACAO --- INICIO
        vm.visualizando = 'todos';

        vm.grupos = [
          { 'gru_id': 1, 'gru_nome': 'Grupo A' },
          { 'gru_id': 2, 'gru_nome': 'Grupo B' },
          { 'gru_id': 3, 'gru_nome': 'Grupo C' },
        ];

        vm.buscaGrupos = function() {
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) { // ler array de retorno
            vm.saasid = items;
            var dados = {
              'saasid': vm.saasid,
            };
            usuariosService.findGrupo(dados)
              .then(function(response) {
                if (response.data != 0) {
                  vm.grupolista = response.data;
                  if (vm.usuarioLogado.fk_perfil_id != 1 && vm.usuarioLogado.fk_perfil_id != 3 && vm.roleVisualiza != 1) {
                    vm.grupolista = $filter('filter')(vm.grupolista, { gru_id: vm.usuarioLogado.fk_gru_id });
                  }
                  $timeout(function() {
                    $('.select_visu').selectpicker();
                    $timeout(function() {
                      vm.mostrarCombo = true;
                    });
                  }, 100);
                } else {
                  vm.grupolista = '';
                };
              }, function(error) {
                console.error(error);
              });
          });
        };

        vm.buscaRepresentantes = function() {
          vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
          vm.items.then(function(items) { // ler array de retorno
            vm.saasid = items;
            var dados = {
              'saasid': vm.saasid,
            };
            usuariosService.findRepresentantes(dados)
              .then(function(response) {
                if (response.data != 0) {
                  vm.representantelista = response.data;
                  $timeout(function() {
                    $('.select_visu').selectpicker();
                    $timeout(function() {
                      vm.mostrarCombo = true;
                    });
                  }, 100);
                } else {
                  vm.representantelista = '';
                };
              }, function(error) {
                console.error(error);
              });
          });
        };

        vm.buscarUsuarioLogado = function() {
          var dados = {
            'hash': Cookies.get('crm_funil_hash')
          }
          compromissosService.buscarUsuarioEspecifico(dados).then(function(response) {
            vm.usuarioLogado = response.data[0];
            vm.verifDadosUser();
          }, function(error) {
            console.error(error);
          });
        }
        vm.buscarUsuarioLogado();

        vm.mostrarCombo = false;
        vm.mostrarVisuGeral = true;
        vm.mostrarUsuario = true;
        vm.naoMostrar = false;
        vm.verifDadosUser = function() {
          var local = window.localStorage.getItem('5c479de2');
          var valLocal = JSON.parse(API.funilRC.decrypt(local));
          var localNeg = window.localStorage.getItem('isModNegociacao');
          var valLocalNeg = JSON.parse(API.funilRC.decrypt(localNeg));

          if (valLocalNeg.visualiza) {
            vm.roleVisualiza = parseInt(valLocalNeg.visualiza);
            switch (parseInt(valLocalNeg.visualiza)) {
              case 4:
                $timeout(function() {
                  vm.mostrarCombo = false;
                  vm.mostrarUsuario = false;
                  vm.carregando = false;
                  vm.naoMostrar = true;
                });
                break;
              case 3:
                $timeout(function() {
                  vm.mostrarCombo = false;
                  vm.mostrarVisuGeral = false;
                  vm.visualizando = "user_" + vm.usuarioLogado.user_id;
                  $timeout(function() {
                    vm.enviarVisualizando = Base64.encode(vm.visualizando);
                  });
                });
                break;
              case 2:
                $timeout(function() {
                  vm.buscaGrupos();
                  if (vm.usuarioLogado.fk_perfil_id == 4 || vm.usuarioLogado.fk_perfil_id == 5) {
                    vm.mostrarVisuGeral = false;
                    vm.mostrarUsuario = true;
                  }
                  vm.visualizando = "grupo_" + vm.usuarioLogado.fk_gru_id;
                  $timeout(function() {
                    vm.enviarVisualizando = Base64.encode(vm.visualizando);
                  });
                });
                break;
              default:
                $timeout(function() {
                  vm.buscaGrupos();
                  vm.buscaRepresentantes();
                  vm.visualizando = "todos";
                  vm.mostrarVisuGeral = true;
                  vm.mostrarUsuario = true;
                  $timeout(function() {
                    vm.enviarVisualizando = Base64.encode(vm.visualizando);
                  });
                });
            }
          }
        }

        vm.mudarVisualizacao = function(visu) {
          $timeout(function() {
            vm.enviarVisualizando = Base64.encode(vm.visualizando);
          });
        }

        vm.carregando = true;

        // COMBOBOX VISUALIZACAO --- FIM


        vm.exportarCSV = function() {
          // aquiiiii
          swal({
                title: 'Deseja realmente exportar os dados?',
                text: 'Esta ação pode demorar um pouco.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#5cb85c",
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                closeOnConfirm: false
              },
              function() {
                $window.onkeydown = null;
                $window.onfocus = null;
                swal.close();
                var enviar = '';
                var produtos = $routeParams.produtos;
                if (produtos != 'todos') {
                  enviar += '&produtos=' + produtos;
                } else {
                  enviar += '&produtos=todos';
                }
                var situacoes = $routeParams.situacoes;
                if (situacoes != 'todos') {
                  enviar += '&situacoes=' + situacoes;
                } else {
                  enviar += '&situacoes=todos';
                }
                if ($routeParams.inicio) { // PERIODO SELECIONADO
                  enviar += '&inicio=' + $routeParams.inicio;
                  enviar += '&fim=' + $routeParams.fim;
                }
                enviar += '&qual_visu=' + $routeParams.visualizar;
                enviar += '&origens=' + $routeParams.origens;
                $timeout(function() {
                  $window.location = API.url + 'relatorios/service.php?t=exportar_CSV&saasid=' + vm.saasid + '&qual=produtos_new' + enviar, '_blank';
                }, 500);
              });
        };

      }

})();
